const getTenantPrefix = () => {
  let tenantId = localStorage.getItem('tenantId');
  return `${tenantId ? tenantId : ''}`;
};

const getHubPath = () => {
  return `/api/${getTenantPrefix()}/dashboardHub`;
};

export { getHubPath, getTenantPrefix };
