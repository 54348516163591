import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Topbar } from '../../ui/topbar/Topbar';
import { Box, Card, Grid, Typography, Divider, Button, Container } from '@mui/material';
import MomentUtils from '@date-io/moment';
import { getParkingLogById } from 'utils/TerritoriesService';
import { initiatePayment, getPaymentStatus } from 'utils/KevinService';
import { postPayment } from 'utils/PaymentsService';
import { NotificationManager } from 'react-notifications';
import { PublicFooter } from 'components/public/PublicFooter';
import icon_parking from '../../../assets/icons/publicApp/icon_parking.svg';
import icon_car from '../../../assets/icons/publicApp/icon_car.svg';
import icon_eur from '../../../assets/icons/publicApp/icon_eur.svg';

const moment = new MomentUtils({ locale: 'en' })

class PublicPayment extends Component {
    constructor() {
        super();
        this.state = {
            vehicles: [],
            userId: null,
            lot: null,
            payment: null,
            mounted: true,
            parkingLog: null
        }
        this.timer = {}
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.setState({ mounted: false })
    }

    componentDidMount = async () => {
        const userId = this.props.currentUser.id;
        this.setState({ userId: userId })
        const { lot, vehicles, parkingLog } = this.props.location.state;
        this.setState({ lot, vehicles, parkingLog });
        if (parkingLog && lot) {
            getParkingLogById(parkingLog.parkingLogId, lot.id).then((response) => {
                if (this.state.mounted) {
                    if (response) {
                        this.setState({ payment: response })
                        this.startTimer()
                    } else {
                        this.props.history.push({
                            pathname: 'publicParking',
                            state: null
                        });
                    }
                }
            })
        } else {
            this.goBack();
        }
    }

    startTimer = () => {
        this.timer = setInterval(this.goBack, 60000);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    goPayment = async () => {

        const plateNum = this.state.vehicles && this.state.vehicles.length > 0 ? this.state.vehicles[0].plateNum : null;

        if (this.state.payment.amount > 0) {
            const redirect = `${window.location.origin}/paymentConfirm?id=${this.state.userId}`;
            // const webhook = `https://spacer.click/api/v1/Payments/confirmPayment`;

            initiatePayment(this.state.lot, this.state.payment, redirect).then((kevin) => {
                postPayment(this.state.lot, this.state.userId, this.state.payment, kevin.id, null, plateNum).then(() => {
                    window.location.href = kevin.confirmLink;
                    // this.props.history.push({
                    //     pathname: '/paymentConfirm'
                    // });
                }).catch((error) => {
                    console.log(error.message);
                })
            }).catch((error) => {
                postPayment(this.state.lot, this.state.userId, this.state.payment, null, error.message, plateNum).then(() => {
                    this.setState({ payment: null })
                    NotificationManager.error(error.message);
                }).catch((error) => {
                    console.log(error.message);
                })
            })
        } else {
            postPayment(this.state.lot, this.state.userId, this.state.payment, null, null, plateNum).then(() => {
                NotificationManager.success('Free Exit');
                this.goBack();
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    getDate = (date) => {
        return moment.date(date).format('YYYY-MM-DD').toString()
    }

    getTime = (date) => {
        return moment.date(date).format('HH:mm').toString()
    }

    getDiff = () => {
        if (this.state.payment) {
            const mins = moment.date(this.state.payment.exitDate).diff(moment.date(this.state.payment.entryDate), 'minutes');
            var d = mins / (60 * 24) | 0;
            var h = (mins - (d * 60 * 24)) / 60 | 0;
            var m = mins % 60 | 0;
            return (d > 0 ? d + 'd ' : '') + (h > 0 ? h + 'h ' : '') + m + 'min';
        } else {
            return '0:00'
        }
    }

    getVat = () => {
        if (this.state.payment && this.state.lot && this.state.payment.amount && this.state.lot.vat) {
            const result = (this.state.payment.amount * this.state.lot.vat) / (100 + this.state.lot.vat)
            return result.toFixed(2)
        } else {
            return 0.00
        }
    }

    getWithOutVat = () => {
        if (this.state.payment && this.state.lot && this.state.payment.amount && this.state.lot.vat) {
            const result = this.state.payment.amount - this.getVat()
            return result.toFixed(2)
        } else {
            return 0.00
        }
    }

    goPublic = (pathname) => {
        if (pathname !== '/publicPayment') {
            this.props.history.push({
                pathname: pathname,
            });
        }
    }

    render() {
        const { t } = this.props;
        const { vehicles, lot, payment } = this.state;
        return (
            <div className='public-form' style={{ backgroundColor: '#F5F6FA' }}>
                <Topbar
                    title={t('publicApp.E-Receipt')}
                    cbBack={this.goBack}
                    location={this.props.location}
                ></Topbar>

                <Box sx={{ top: 50, position: 'relative', overflow: 'auto', mb: 2 }} >

                    <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3 }}>
                        <Grid container >
                            <Grid item xs={2}>
                                <img src={icon_parking} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }}>
                                {lot && (<Typography variant="h3" gutterBottom sx={{ pl: 1 }}>
                                    {lot.name}
                                </Typography>)}

                            </Grid>
                        </Grid>
                    </Card>
                    <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3 }}>
                        <Grid container >
                            <Grid item xs={2} >
                                <img src={icon_car} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }}>
                                <Typography variant="h3" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                    {t('publicApp.Vehicle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        {vehicles.length > 0 && (
                            <Box style={{ textAlign: '-webkit-center' }}>
                                <Divider />
                                <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1', m: 1, width: 'min-content', minWidth: '200px' }}
                                >
                                    <Typography className='parking-plate-num' gutterBottom>
                                        {vehicles[0].plateNum}
                                    </Typography>
                                </Card>
                            </Box>)}

                    </Card>

                    <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3, mb: 1 }}>
                        <Grid container >
                            <Grid item xs={2}>
                                <img src={icon_eur} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }}>
                                <Typography variant="h3" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                    {t('publicApp.PaymentInformation')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" />
                        <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}
                                >
                                    <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1, fontWeight: 500 }}>
                                        {t('publicApp.Arrive')}
                                    </Typography>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1', mr: 1 }}>
                                        <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1, fontWeight: 500 }}>
                                            {payment ? this.getTime(payment.entryDate) : '00:00'}
                                        </Typography>
                                        <Typography variant="h4" gutterBottom sx={{ pl: 1, fontWeight: 500 }}>
                                            {payment ? this.getDate(payment.entryDate) : '--'}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>
                            <Grid item xs={6} >
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}
                                >
                                    <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1, fontWeight: 500 }}>
                                        {t('publicApp.Exit')}
                                    </Typography>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1', ml: 1 }}>
                                        <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1, fontWeight: 500 }}>
                                            {payment ? this.getTime(payment.exitDate) : '00:00'}
                                        </Typography>
                                        <Typography variant="h4" gutterBottom sx={{ pl: 1, fontWeight: 500 }}>
                                            {payment ? this.getDate(payment.exitDate) : '--'}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>

                        </Grid>
                        <Divider variant="middle" />
                        {lot && payment && (
                            <Grid container  >
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0 }}>
                                        <Typography variant="h3" gutterBottom sx={{ pl: 2, pt: 1, fontWeight: 500 }}>
                                            {t('publicApp.TotalTime')}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
                                        <Typography variant="h3" gutterBottom sx={{ pr: 2, pt: 1, fontWeight: 500 }}>
                                            {this.getDiff()}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0 }}>
                                        <Typography variant="h3" gutterBottom sx={{ pl: 2, pt: 1, fontWeight: 500 }}>
                                            {t('publicApp.Amount')}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
                                        <Typography variant="h3" gutterBottom sx={{ pr: 2, pt: 1, fontWeight: 500 }}>
                                            {this.getWithOutVat() + ' ' + lot?.currencyShort}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0 }}>
                                        <Typography variant="h3" gutterBottom sx={{ pl: 2, pt: 1, fontWeight: 500 }}>
                                            {t('publicApp.VAT') + ' ' + lot?.vat + '%'}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
                                        <Typography variant="h3" gutterBottom sx={{ pr: 2, pt: 1, fontWeight: 500 }}>
                                            {this.getVat() + ' ' + lot?.currencyShort}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                        <Divider variant="middle" sx={{ borderColor: '#455D80' }} />
                        {lot && payment && (
                            <Grid container>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0 }}>
                                        <Typography variant="h2" gutterBottom sx={{ pl: 2, pt: 1, color: '#455D80' }}>
                                            {t('publicApp.TotalAmount')}
                                        </Typography>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card sx={{ boxShadow: 0, textAlign: 'right' }}>
                                        <Typography variant="h2" gutterBottom sx={{ pr: 2, pt: 1, color: '#455D80' }}>
                                            {payment.amount + ' ' + lot?.currencyShort}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                    </Card>
                    {payment && payment.amount > 0 && (
                        <Box sx={{ mt: 3, ml: 2, mr: 2 }}>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                type="buttom"
                                onClick={() => this.goPayment()}
                                variant="contained"
                                color="secondary"
                                className='authButton'
                            >
                                {t('publicApp.Payment')}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Container sx={{ minHeight: '200px' }}
                >
                </Container>
                <PublicFooter path='payment' goPublic={this.goPublic} />

            </div>
        )
    }
}

export default withSnackbar(withTranslation()(PublicPayment));