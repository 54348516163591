import { axiosA } from './AuthService';

async function postData  (importData)  {

    try {

        const { data } = await axiosA.post('/Administration/Import', importData)
        return data
    } catch (e) {
        const  data = { value: e};
        return data;
        // throw new Error(e)
    }
}

const importLot = async (lot, id) => {
	const { data } = await axiosA.put(`/Administration/importLot/${id}`, lot)
	return data
}

const getData = async () => {
	const { data } = await axiosA.get('/Administration')
	return data
}

const deleteLot = async (id) =>{
    const { data } = await axiosA.get(`/Administration/deleteLot/${id}`)
	return data
}

const deleteSpace = async (id) =>{
    const { data } = await axiosA.get(`/Administration/deleteSpace/${id}`)
	return data
}

const postParkingSpace = async (space, territory) => {
	try{
		const body = {
			name: space.name,
			parkingLotId: space.parkingLotId,
			ownerId: space.ownerId,
			lotPlaceId: space.lotPlaceId
		}
		const {data} = await axiosA.post('/ParkingSpaces', body)
		return data
	}catch (e) {
		throw new Error(e)
	}
}

const putParkingSpace = async (space, territory) => {
	const body = {
		name: space.name,
		parkingLotId: space.parkingLotId,
		ownerId: space.ownerId,
		lotPlaceId: space.lotPlaceId
	}
	const {data} = await axiosA.put(`/ParkingSpaces/${space.id}`, body)
	return data
} 

export { postData, importLot, getData, deleteLot, deleteSpace,	postParkingSpace,
	putParkingSpace }