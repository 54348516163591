import React, { Component } from 'react';
import { Topbar } from '../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import './Administration.scss';
import { Grid, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Divider, ButtonBase } from '@mui/material';
import { getTerritories } from '../../utils/TerritoriesService';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../constants/Colors';

class Administration extends Component {
    constructor(){
        super()
        this.state = {
            territories: [],
         }
    }

    componentDidMount() {
        this.fetchTerritories();
    }

    fetchTerritories = async () => {
        try {
            const resp = await getTerritories();
             this.setState({ territories: resp });
        } catch (e) {
        }
    }

    fetchData = async() => {
        // try {
        //     const resp = await getData();
        // } catch (e) {
        // }
    }

    newClick = async() => {
        // const resp = await getData();
    }

    territoryClick = async(value) => {
        this.props.history.push({
            pathname: '/territory',
            state: { territory: value }
          })
    }

    import = (territory) => {

        this.props.history.push({
            pathname: '/import',
            state: { territory: territory, patchName: '/territory' }
        })
    }

    render() {
        const { t } = this.props;

        const handleToggle = (value) => () => {
            this.territoryClick(value);
        };
        const handleImport = (territory) => () => {
            this.import(territory);
        }

        return (
            <div>
                <Topbar title={t("administration.Territories")} />
                <div className="adminstrationContainer">
                    <List component="nav" >
                        {
                            this.state.territories.map((item) => {
                                return (
                                    <React.Fragment key={item.id}>

                                        <ListItem className="adminstrationContainerListItem">

                                            <Grid onClick={handleToggle(item)} container xs={12} sx={{ p:3, py: 1.5 , borderRadius: '10px', borderColor:'#3A4D8F', border: '1px solid'}}>

                                            <ListItemText primary=
                                                {<Typography variant ='h3'>{`${item.name} `}</Typography>}
                                            ></ListItemText>
                                            <ListItemSecondaryAction>
                                           {/* { item.isRemote? */}
                                                <IconButton edge="end" aria-label="comments" onClick={handleToggle(item)}>
                                                    <FontAwesomeIcon icon={faChevronRight} color={Colors.iconHead} />
                                                </IconButton>
                                                {/* :
                                                <IconButton onClick={handleImport(item)} edge="end" aria-label="Action" className="expansionIcon">
                                                <FontAwesomeIcon icon={faFileImport} color={Colors.iconHead} />
                                            </IconButton>
                            } */}
                                            </ListItemSecondaryAction>
                                            </Grid>

                                        </ListItem>

                                    </React.Fragment>
                                )
                            })
                        }
                    </List>
                </div>
            </div>
        )
    }

}
export default withTranslation()(Administration);