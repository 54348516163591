import React, { Component } from 'react';
import './BookedList.scss'
import MomentUtils from "@date-io/moment";

import { ListItem, Box, Grid, Typography, Button, IconButton } from '@mui/material';
import { getParkingSpace, getParkingLot, getParkingLotNames } from '../../../utils/ParkingService';
import ListExpanded from '../../ui/list/expanded/ListExpanded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

class BookedListItem extends Component {
  state = {

    parkingSpace: {

    },
    owner: {

    },
    parkingLotName: null,
    parkingLotNames: []

  }
  fetchInfo = async ({ parkingSpaceId, parkingLotId, name }, parkingSpaces, parkingLots) => {
    try {
     let parkingSpace;
      if (parkingSpaces && parkingSpaces.length > 0) {
        parkingSpace = parkingSpaces.find(x => x.id == parkingSpaceId);
        if (!parkingSpace) {
          parkingSpace = await getParkingSpace(parkingSpaceId);
        }
      } else {
        parkingSpace = await getParkingSpace(parkingSpaceId);
      }
      let parkingLotName;
      let parkingLotNames;
      if (parkingSpace && parkingSpace.bookingLevel === 2) {
        if (parkingLots && parkingLots.length > 0) {
          parkingLotNames = parkingLots.filter(x => x.territoryId == parkingLotId).map(x => x.name);
        } else {
          parkingLotNames = await getParkingLotNames(parkingLotId);
        }

        this.setState({ parkingLotNames: parkingLotNames });
      } else {
        if (parkingLots && parkingLots.length > 0) {
          parkingLotName = parkingLots.find(x => x.id == parkingLotId).name;
        } else {
          const parkingLot = await getParkingLot(parkingLotId);
          parkingLotName = parkingLot.name;
        }
      }
      const bookedUserName = name;
      const dates = await this.resolveDate();

      await this.setState({ parkingSpace, parkingLotName, bookedUserName, bookedSpace: { ...this.props.bookedSpace, ...dates } })
    } catch (e) {
      console.error(e)
    }
  }

  resolveDate = async () => {
    const currentLng = localStorage.getItem('currentLng');
    const moment =  new MomentUtils({locale: currentLng});
    const dates = {
      bookedFrom: await moment.date(this.props.bookedSpace.bookedFrom).utc(0).format('MMM DD'),
      bookedTo: await moment.date(this.props.bookedSpace.bookedTo).utc(0).format('MMM DD')
    }
    return dates
  }

  componentDidMount = () => {
    this.fetchInfo(this.props.bookedSpace, this.props.parkingSpaces, this.props.parkingLots)
  }
  render() {
    const { onBookSpace, spaceName } = this.props;
    const { bookedSpace, parkingSpace, parkingLotName, bookedUserName, parkingLotNames } = this.state;
    return (
      <React.Fragment>
        {bookedSpace &&
          <ListItem
            button={onBookSpace && true}
            onClick={() => onBookSpace && onBookSpace({ ...parkingSpace, ...bookedSpace })}
            className="BookedItemContainer"
          >
            <Grid container>
              <Grid item xs={12}  sx={{ background:'#3A4D8F', borderRadius: '10px', borderColor:'#3A4D8F', border: '1px solid'}}>
                <Grid container >
                  <Grid item xs={2} sx={{py: 1.5  }} >
                    <Grid container direction='column'  alignItems='center'>
                      <Grid item xs>
                        <Typography className='h4' color ="#ffffff">
                          {bookedSpace.bookedFrom}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className='h4' color ="#ffffff">
                          -
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className='h4' color ="#ffffff">
                          {bookedSpace.bookedTo}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs sx={{ background:'#ffffff' ,py: 1.5, px: 2 ,  borderRadius: '10px'}}>
                    <Grid xs container  >
                      <Grid item xs>
                          <Typography className='h2'>
                            {parkingSpace.name}
                          </Typography>

                          <Typography className='h2'>
                            {spaceName && ` (${spaceName.name}) `}
                          </Typography>

                        <div className='ExchangeSpaceSubtitle'>
                          {(parkingLotNames || parkingLotName) && <ListExpanded names={parkingLotNames} name={parkingLotName}></ListExpanded>}
                            </div>
                      </Grid>

                      <Grid item >
                        <Box>
                          {bookedUserName ? (
                            <div className="bookedUserName">{bookedUserName}</div>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </ListItem>
        }
      </React.Fragment>
    )
  }
}


export default BookedListItem;
