
// booking period aligiment kokiu laiku
// zinksnkiai kurie 
import MomentUtils from '@date-io/moment';
const moment = new MomentUtils();

const resolveTimeSpan = (timespan) => {
  let resolvedTimeSpan = {}
  let arrTimespan = timespan.split('.')
  const arrTime = arrTimespan.slice(-1)[0].split(':')
  if (arrTimespan.length === 2) {
    resolvedTimeSpan['days'] = parseInt(arrTimespan[0])
  }
  resolvedTimeSpan['hours'] = parseInt(arrTime[0])
  resolvedTimeSpan['minutes'] = parseInt(arrTime[1])
  resolvedTimeSpan['seconds'] = parseInt(arrTime[2])
  return resolvedTimeSpan;
}

const resolveMinMaxDates = (periods, datefrom, dateto, id) => {
  if (periods) {
    let periods_ = periods.filter(x => x.id !== id).map(item => { return { ...item, to: item.to.replace('T', ' ').replace('Z', ''), from: item.from.replace('T', ' ').replace('Z', '') } });
    periods = periods_;
  }

  let from = periods
    .sort((a, b) => (a.to < b.to ? 1 : -1))
    .find((x) => x.to < datefrom)?.to;
  let to = periods
    .sort((a, b) => (a.from > b.from ? 1 : -1))
    .find((x) => x.from > dateto)?.from;
  let minDate = moment.date(new Date()).startOf('day');//.utc(true);
  let maxDate = null;
  if (from) {
    let fromDate = moment
      .date(new Date(from))
      // .utc(true)
      .add(1, 'days');
    if (minDate < fromDate) {
      minDate = fromDate;
    }
  }
  if (to) {
    let toDate = moment
      .date(new Date(to))
      // .utc(true)
      .add(-1, 'days');
    maxDate = toDate.format('YYYY-MM-DDT23:59:59.999').toString();
  }
  minDate = minDate.format('YYYY-MM-DDT00:00:00.000').toString();
  return { minDate: minDate, maxDate: maxDate };
}

const resolveStartOfDay = (dateString) => {
  return dateString.substring(0, 10) + 'T00:00:00.000';
}

const resolveEndOfDay = (dateString) => {
  return dateString.substring(0, 10) + 'T23:59:59.999';
}

export { resolveTimeSpan, resolveMinMaxDates, resolveStartOfDay, resolveEndOfDay };


