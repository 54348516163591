import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './Territory.scss';
import {
    Typography,
    Accordion,
  AccordionDetails,
  AccordionSummary,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Button,
    Grid, Divider, Box
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../constants/Colors';
import { getLots, getUsers, getRemoteLots, getLocalLots } from '../../../utils/TerritoriesService';
import { importLot } from '../../../utils/AdministrationService';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

class Territory extends Component {
    constructor() {
        super()
        this.state = {
            territory: {
                id: '',
                name: '',
                isRemote: true
            },
            expanded: null,
            remoteLots: [],
            localLots: [],
            users: [],
            lots: [],
            mappedLots: [],
            open: false,
            prepareted: {}
        }
        this.setChange = this.setChange.bind(this);
    }

    componentDidMount = async () => {
        if (this.props.location.state) {
            const { territory, expanded } = this.props.location.state;
            if (territory) {
                await this.setState({
                    territory, expanded
                });
                if (territory.isRemote) {
                    this.fetchRemoteLots();
                }
                else {
                    this.fetchLocalLots();
                }
            }
        }
    }

    fetchLocalLots = async () => {
        if (this.state.territory.id) {
            console.log(this.state.territory.id);
            try {
                const resp = await getLocalLots(this.state.territory.id);
                this.setState({ remoteLots: resp });
                this.fetchLots();
            } catch (e) {
                console.error(e)
            }
        }
    }

    fetchRemoteLots = async () => {
        if (this.state.territory.id) {
            try {
                const resp = await getRemoteLots(this.state.territory.id);
                this.setState({ remoteLots: resp });
                this.fetchLots();
            } catch (e) {
                console.error(e)
            }
        }
    }

    fetchLots = async () => {
        if (this.state.territory.id) {
            try {
                const resp = await getLots(this.state.territory.id);
                this.setState({ lots: resp });
                this.mapLots();
            } catch (e) {
                console.error(e)
            }
        }
    }

    mapLots() {
        this.state.remoteLots.forEach(r => {
            let importedLot = this.state.lots.find(i => i.remoteLotId == r.id);
            r.imported = importedLot != null ? true : false;
            r.guid = importedLot != null ? importedLot.id : '';
            r.name = importedLot != null ? importedLot.name : '';
            this.state.mappedLots.push(r);
        })
        this.setState({expanded: 'remoteLots'});
    }

    fetchUsers = async () => {
        if (this.state.territory.id) {
            try {
                const resp = await getUsers(this.state.territory.id);
                this.setState({ users: resp });
            } catch (e) {
                console.error(e)
            }
        }
    }

    goBack = () => {
        this.props.history.push({ pathname: '/administration' })
    }

    setExpanded(newValue) {
        this.setState({ expanded: newValue })
    }

    newLot = async () => {
        this.props.history.push({
            pathname: '/lot/form',
            state: { territory: this.state.territory }
        })
    }

    newUser = async () => {
        this.props.history.push({
            pathname: '/territoryUser/form',
            state: { territory: this.state.territory }
        })
    }

    lotClick = async (value) => {
        this.props.history.push({
            pathname: '/lot',
            state: { lot: { id: value.guid, name: value.name }, territory: this.state.territory }
        })
    }

    userClick = async (value) => {
        this.props.history.push({
            pathname: '/territoryUser/form',
            state: { user: value, territory: this.state.territory }
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
    }

    setChange = (event) => {
        this.setState({ territory: { ...this.state.territory, [event.target.id]: event.target.value } });
    }

    territoryClick = async () => {
        this.props.history.push({
            pathname: '/territory/form',
            state: { territory: this.state.territory }
        })
    }

    importRemoteLot = async (lot) => {

        this.setState({ prepareted: lot });
        this.setState({ open: true });

    }

    confirmImport = async () => {
        const resp = await importLot(this.state.prepareted, this.state.territory.id);
        this.props.history.push({
            pathname: '/lot',
            state: { lot: { id: resp, name: this.state.prepareted.title }, territory: this.state.territory }
        })
        this.setState({ prepareted: {} });
    }

    import = (name) => {

        this.props.history.push({
            pathname: '/import',
            state: { territory: this.state.territory, patchName: '/territory', name: name }
        })
    }

    render() {

        const { t } = this.props;
        const { territory } = this.state;
        const title = this.state.territory.id != null ? this.state.territory.name : t("administration.NewTerritory")

        const handleChange = (panel) => (event, isExpanded) => {
            this.setExpanded(isExpanded ? panel : false);
        };

        const handleLot = (value) => () => {
            this.lotClick(value);
        };

        const handleUser = (value) => () => {
            this.userClick(value);
        }

        const handleImport = (value) => () => {
            this.import(value);
        }

        const handleImportLot = (lot) => () => {
            this.importRemoteLot(lot);
        }

        const handleClose = () => {
            this.setState({ prepareted: {} })
            this.setState({ open: false });
        };

        const handleYes = () => {
            this.confirmImport();
            this.setState({ open: false });
        };
        let changeTitle = t('exchanges.Change');
        return (
            <div>
                <Topbar title={t("administration.Territory")} cbBack={this.goBack} >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="SpaceBlock"
                    >
                    </Grid>
                </Topbar>
                <div className="territoryContainer">

                    <Grid  xs container direction='row' sx={{p: 2, py:1.5,  background:'#3A4D8F', border: '1px solid #3A4D8F'}} >
                        <Grid item  xs >
                    <Typography variant='h3' color='#ffffff'>
                        {this.state.territory.name}
                    </Typography>
                        </Grid>
                        <Grid item >
                            <Button  onClick={this.territoryClick}>
                                <Typography className="shareBtn-text">{changeTitle}</Typography>
                            </Button>

                            <Box>
                                <div className='pb-10'>
                                    <Button className="actionBtn" aria-label="Delete" onClick={this.territoryClick}>
                                        <Typography className="shareBtn-text">{changeTitle}</Typography>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>

                    <Accordion expanded={this.state.expanded === 'remoteLots'} onChange={handleChange('remoteLots')}>
                        <AccordionSummary className="summary" aria-controls="panel2bh-content" id="panel2bh-header">
                            <Typography variant ='h4' edge="start">{t("spaces.All")}</Typography>


                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="territoryLotsContainer">
                                <List component="nav" >
                                    {
                                        this.state.mappedLots.map((item) => {
                                            return (
                                                
                                                <React.Fragment key={item.id}>
                                                    <Divider />
                                                    <ListItem >
                                                        <ListItemText className="space-name" primary=
                                                            {<Typography variant = 'h4'>{`${item.name} `}</Typography>}
                                                            secondary={item.title}

                                                        ></ListItemText>
                                                        <ListItemSecondaryAction>
                                                            {item.imported ?
                                                                <IconButton edge="end" aria-label="comments" onClick={handleLot(item)}>
                                                                    <FontAwesomeIcon icon={faChevronRight} color={Colors.iconHead} />
                                                                </IconButton>
                                                                :
                                                                <IconButton edge="end" aria-label="comments" onClick={handleImportLot(item)}>
                                                                    <FontAwesomeIcon icon={faFileImport} color={Colors.iconHead} />
                                                                </IconButton>
                                                            }
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </React.Fragment>
                                                        
                                            )
                                        })
                                    }
                                </List>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                </div>
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t("administration.ImportLot")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.prepareted.title}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                        {t("general.Cancel")}
                        </Button>
                        <Button onClick={handleYes} color="primary">
                        {t("general.Confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withTranslation()(Territory);