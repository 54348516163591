import React, { Component } from 'react';
import './ExchangesBookingItem.scss'
import MomentUtils from "@date-io/moment";

import { ListItem, Box, Typography, Button, Grid, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getParkingSpace, getParkingLot, getParkingLotNames } from '../../../../utils/ParkingService';
import { getUser } from '../../../../utils/UsersService';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import ListExpanded from '../../../ui/list/expanded/ListExpanded';
import { faPlus, faUserLock } from '@fortawesome/free-solid-svg-icons';
import DeleteActionButton from "../../../uiactions/actionbuttons/DeleteActionButton";

class ExchangesBookingItem extends Component {
  state = {
    parkingSpace: {},
    owner: {},
    parkingLotName: null,
    parkingLotNames: []
  }

  fetchInfo = async ({ parkingSpaceId, parkingLotId, userId }, parkingSpaces, parkingLots) => {
    try {
      let parkingSpace;
      if (parkingSpaces && parkingSpaces.length > 0) {
        parkingSpace = parkingSpaces.find(x => x.id == parkingSpaceId);
        if (!parkingSpace) {
          parkingSpace = await getParkingSpace(parkingSpaceId);
        }
      } else {
        parkingSpace = await getParkingSpace(parkingSpaceId);
      }
      let parkingLotName;
      let parkingLotNames;
      if (parkingSpace && parkingSpace.bookingLevel === 2) {
        if (parkingLots && parkingLots.length > 0) {
          parkingLotNames = parkingLots.filter(x => x.territoryId == parkingLotId).map(x => x.name);
        } else {
          parkingLotNames = await getParkingLotNames(parkingLotId);
        }

        this.setState({ parkingLotNames: parkingLotNames });
      } else {
        if (parkingLots && parkingLots.length > 0) {
          parkingLotName = parkingLots.find(x => x.id == parkingLotId).name;
        } else {
          const parkingLot = await getParkingLot(parkingLotId);
          parkingLotName = parkingLot.name;
        }
      }
      const owner = await getUser(userId)
      const dates = await this.resolveDate();
      await this.setState({ parkingSpace, parkingLotName, owner, bookedSpace: { ...this.props.bookedSpace, ...dates } })
    } catch (e) {
      console.error(e)
    }
  }

  resolveDate = async () => {
    const currentLng = localStorage.getItem('currentLng');
    const moment =  new MomentUtils({locale: currentLng});
    const dates = {
      sharedFrom: await moment.date(this.props.bookedSpace.bookedFrom).utc(0).format('MMM DD'),
      sharedTo: await moment.date(this.props.bookedSpace.bookedTo).utc(0).format('MMM DD')
    }
    return dates
  }

  componentDidMount = () => {
    this.fetchInfo(this.props.bookedSpace, this.props.parkingSpaces, this.props.parkingLots)
  }

  render() {
    
    const { onDelete, onBookSpace, canEdit, changeTitle } = this.props;
    const { bookedSpace, parkingSpace, parkingLotName, parkingLotNames } = this.state;

    const renderEditButton = (
      onBookSpace,
      canEdit,
      changeTitle,
      bookedSpace
    ) => {
      if (!canEdit && new Date(bookedSpace.bookedTo) <= new Date()) {
        return null;
      }
      
      return onBookSpace && !canEdit ? (
        <Button
          disabled
          className="actionBtn-disabled"
          aria-label="Delete"
        >
          <Typography className="shareBtn-text">{changeTitle}</Typography>
        </Button>
       
      ) : (
        <Button
          className="actionBtn"
          aria-label="Delete"
          onClick={onBookSpace}
        >
          <Typography className="shareBtn-text">{changeTitle}</Typography>
        </Button>
      );
    };

    return (
      <React.Fragment>
        {bookedSpace &&
          <ListItem
            // button={onBookSpace && true}
            // onClick={() => onBookSpace && onBookSpace({...parkingSpace, ...bookedSpace})}
            className="ExchangeItemContainer"
          >
            <Grid container>
              <Grid item xs={12}  sx={{ background:'#3A4D8F', borderRadius: '10px', borderColor:'#3A4D8F', border: '1px solid'}}>
                <Grid container >
                  <Grid item xs={2} sx={{py: 1.5  }} >
                    <Grid container direction='column'  alignItems='center'>
                      <Grid item xs>
                        <Typography className='h4' color ="#ffffff">
                          {bookedSpace.sharedFrom}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className='h4' color ="#ffffff">
                          -
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className='h4' color ="#ffffff">
                          {bookedSpace.sharedTo}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs sx={{ background:'#ffffff' ,py: 1.5, px: 2 ,  borderRadius: '10px'}}>
                    <Grid xs container  >
                      <Grid item xs>
                          <Typography className='h2'>
                            {parkingSpace.name}
                          </Typography>
                        <div className='ExchangeSpaceSubtitle'>
                          {(parkingLotNames || parkingLotName) && <ListExpanded names={parkingLotNames} name={parkingLotName}></ListExpanded>}
                        </div>
                      </Grid>

                      <Grid item >
                        <Box>
                          <div className="pb-10">
                            {
                              renderEditButton(() => onBookSpace(bookedSpace, parkingSpace.name, parkingLotName), canEdit, changeTitle, bookedSpace)
                            }
                          </div>
                          <div>
                            {onDelete &&
                            <DeleteActionButton
                                openit = {false}
                                onConfirm ={() =>  onDelete(bookedSpace.id)}
                               // parkingLotNames = {parkingLotName}
                            />
                            // <Button className="actionBtn" aria-label="Delete" onClick={() =>
                            //   onDelete(bookedSpace.id)
                            //   // onDelete(bookedSpace, parkingSpace.name, parkingLotName)
                            // }>
                            //   <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                            // </Button>
                            }
                          </div>
                        </Box>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </ListItem>
        }
      </React.Fragment>
    )
  }
}

export default ExchangesBookingItem;
