import { getParkingPrice, currencyFormat, countWorkDays } from "../../../utils/PricingHelpers";
import { Grid, Typography } from "@mui/material";
import info from "../../../assets/icons/info.svg";
import React, { useEffect, useState } from 'react';
import MomentUtils from '@date-io/moment';
import i18next from 'i18next';

const moment = new MomentUtils({ locale: 'lt' });

const PriceInfoCard = (props) => {
    const { type, dateFrom, dateTo, parkingInfo, bookedSpace, price, freedays, inlcudeWeekendsInSharing } = props;
    const [dayPrice, setDayPrice] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const formatFrom = moment.date(dateFrom).format('YYYY-MM-DD');
    const formatTo = moment.date(dateTo).format('YYYY-MM-DD');
    let price_ = 0;

    useEffect(() => {
        fetchInfo();
    }, [price,
        dateFrom,
        dateTo
    ]);

    const fetchInfo = async () => {
        if (price === null) {
            if (parkingInfo && parkingInfo.parkingRateId && parkingInfo.parkingRateId != null) {
                const rateId = info.parkingRateId;
                const rate = await getParkingPrice(rateId);
                if (rate) {
                    price = rate;
                    const formatRate = await currencyFormat(price);
                    setDayPrice(formatRate);
                }
            } else {
                if (bookedSpace && bookedSpace.price != null) {
                    price_ = bookedSpace.price
                }
            }
        }
        else {
            price_ = (price > 0 || price === 0) ? price : 0;
        }

        const formatRate = await currencyFormat(price_);
        setDayPrice(formatRate);

        const days = await countWorkDays(dateFrom, dateTo, freedays, inlcudeWeekendsInSharing);
        let countdays = days;
        const total = price_ * (countdays);
        const formatPrice = await currencyFormat(total);
        setTotalPrice(formatPrice);
    }

    switch (type) {

        case 'booking': {
            return (
                <React.Fragment>
                    <Grid item xs={10}>
                        <Grid item alignItems="center" sx={{
                            background: '#ffffff',
                            borderRadius: '10px',
                            borderColor: '#E6E9EE',
                            border: '1px solid #E6E9EE'
                        }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                py={2}
                            >
                                <Grid container direction="column" justifyContent="center"
                                    alignItems="center">
                                    <img src={info} alt="background" />
                                    <Typography py={1} variant='h6' color='#27348A'>
                                        {i18next.t('PriceInfoCard.DailyPrice')} {`${dayPrice}`}
                                    </Typography>
                                    <Typography variant='h6' color='#27348A'>
                                        {i18next.t('PriceInfoCard.ForPeriod')}
                                    </Typography>
                                    <Typography py={1} variant='h6' color='#27348A'>
                                        {`${formatFrom} `} - {`${formatTo} `}
                                    </Typography>
                                    <Typography variant='h5' color='#27348A'>
                                        {i18next.t('PriceInfoCard.AmountToBePaid')}
                                    </Typography>
                                    <Typography variant='h5' color='#27348A'>
                                        {`${totalPrice}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
        case 'exchange': {
            return (
                <React.Fragment>
                    <Grid item xs={10}>
                        <Grid item alignItems="center" sx={{
                            background: '#ffffff',
                            borderRadius: '10px',
                            borderColor: '#E6E9EE',
                            border: '1px solid #E6E9EE'
                        }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                py={2}
                            >
                                <img src={info} alt="background" />
                                <Typography py={1} variant='h6' color='#27348A'>
                                    {i18next.t('PriceInfoCard.DailyPrice')} {`${dayPrice}`}
                                </Typography>
                                <Typography variant='h6' color='#27348A'>
                                    {i18next.t('PriceInfoCard.ForSharedPeriod')}
                                </Typography>
                                <Typography py={1} variant='h6' color='#27348A'>
                                    {`${formatFrom} `} - {`${formatTo} `}
                                </Typography>
                                <Typography variant='h5' color='#27348A'>
                                    {i18next.t('PriceInfoCard.WillBeRefunded')}
                                </Typography>
                                <Typography variant='h5' color='#27348A'>
                                    {`${totalPrice}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
        default:
            return null
    }
};
export default PriceInfoCard;

