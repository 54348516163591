import React, { Component } from 'react';
import './UserDetails.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  Dialog,
  List,
  Typography,
  Divider,
  Avatar
} from '@mui/material';
import VehiclesOwnerList from '../../vehicles/owner-list/VehiclesOwnerList';
import SpacesOwnerList from '../../spaces/owner-list/SpacesOwnerList';
import { getOwnwerVehicles, deleteVehicle } from '../../../utils/VehicleService';
import {
  getOwnerSpaces,
  setParkingOwner,
  getUserBookedSpaces,
  resolveSpaceLot,
  getParkingSpaces,
  getParkingLots,
  getSharedSpacesList,
  deleteShareSpace,
  deleteBookedSpace,
  getSharedSpaces,
  getSharedSpace,
  getPrimaryParkingLots,
  spacesData,
  getParkingSpace
} from '../../../utils/ParkingService';
import { allSharedSpacesRequests, deleteSharedSpaceRequest } from '../../../utils/SharedSpaceRequestService';
import { withSnackbar } from 'notistack';
import ExchangesBookingList from '../../exchanges/booking-list/ExchangesBookingList';
import { InputSelect } from 'components/ui/inputs/select/Select';
import { InputSearch } from 'components/ui/inputs/search/Search';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from 'constants/Api';
import i18next from 'i18next';
import SpacesOwnerListItem from 'components/spaces/owner-list/item/SpacesOwnerListItem';
import ExchangesList from 'components/exchanges/list/ExchangesList';
import InfiniteScroll from "react-infinite-scroll-component";
import { featureEnabled } from 'utils/FeatureTogglerService';
import { AUTO_MATCH, SHOW_CALENDAR, SHOW_IBAN, SHOW_PHONE } from 'constants/Features';
import SubscriptionList from '../../exchanges/subscription-list/SubscriptionList';
import { getSilencePeriods, deleteSilencePeriod } from 'utils/SilencePeriodsService';
import { getUser, getDateStatuses } from 'utils/UsersService';
import MonthInformation from '../../ui/calendar/calendar/MonthInformation';
import BookedList from 'components/exchanges/booked-list/BookedList';
import MomentUtils from '@date-io/moment';
import { getActiveInfo } from '../../../utils/UsersService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { HubConnectionBuilder } from '@microsoft/signalr'
import ConfirmDialog from '../../../components/ui/dialogs/ConfirmDialog'
import { getHubPath } from 'utils/ProxyPathService'
import ActiveInfo from '../activeInfo/ActiveInfo';

const searchAPIDebounced = AwesomeDebouncePromise(getParkingSpaces, Api.SEARCH_DEBOUNCE);
const moment = new MomentUtils({ locale: 'lt', });

class UserDetails extends Component {
  state = {
    query: '',
    selectedLot: i18next.t('spaces.All'),
    vehicles: [],
    parkingSpaces: [],
    openedSpaceDialog: false,
    user: {
      id: null,
      name: null,
      email: null,
      phone: null,
      iban: null,
      role: null,
      parkingLots: [],
      parkingSpaces: [],
      isEmailValidated: null,
      canBack: false,
      primaryParkingLots: []
    },
    spaces: [],
    autoMatchFeatureEnabled: false,
    show_calendarEnabled: false,
    show_ibanEnabled: false,
    show_phoneEnabled: false,
    dateStatuses: null,
    loadingDone: false,
    defaultDate: null,
    isCurrentUser: null,
    spaceNames: null,
    index: 0,
    periods: [],
    lastUpdateDt: new Date(),
    dialogConfirmed: false,
    activeInfo: null,
    inlcudeWeekendsInSharing: false
  };

  editUser = () => {
    const showIbanEnabled = this.state.show_ibanEnabled;
    const showPhoneEnabled = this.state.show_phoneEnabled;
    this.props.history.push({
      pathname: '/user/' + this.state.user.id + '/edit',
      state: {
        user: { ...this.state.user },
        showIban: showIbanEnabled,
        showPhone: showPhoneEnabled
      },
    });
  };

  settings = () => {
    this.props.history.push({
      pathname: '/settings',
      state: { user: { ...this.state.user }, autoMatch: this.state.autoMatchFeatureEnabled },
    });
  };
  activeHistory = () => {
    this.props.history.push({
      pathname: '/activehistory',
      state: { user: { ...this.state.user } }
    });
  };

  monthlyReports = () => {
    this.props.history.push({
      pathname: '/monthly_reports',
      state: { user: { ...this.state.user } },
      userId: this.state.user.id,
      hideTitle: true,
      isCurrentUser: this.state.isCurrentUser
    });
  };

  newVehicle = () => {
    this.props.history.push({
      pathname: '/vehicles/new',
      state: {
        ownerId: this.state.user.id,
      },
    });
  };

  exchangeSpace = async (space) => {
    const sharedDates = await this.fetchSharedSpacesDates(space.id);
    this.props.history.push({
      pathname: '/exchanges/new',
      state: {
        space: space,
        sharedDates,
        user: { ...this.state.user },
        spaceNames: this.state.spaceNames
      },
    });
  };

  deleteVehicle = async (id) => {
    const deleteVehicleFn = async (id) => {
      try {
        const { affectedIds } = await deleteVehicle(id);
        if (affectedIds && affectedIds.length) {
          this.setState({
            vehicles: [
              ...this.state.vehicles.filter((e) => !affectedIds.includes(e.id)),
            ],
          });
          this.props.enqueueSnackbar(
            this.props.t('notification.success.deleteVehicle'),
            {
              variant: 'success',
            }
          );
        }
        //   this.handleCloseConfirmDialog();
      } catch (e) {
        console.error(e);
      }
    };
    deleteVehicleFn(id);
    // this.setupConfirmDialog('confirm.Delete', () => deleteVehicleFn(id));
    // this.showConfirmDialog();
  };

  goToShares = () => {
    this.props.history.push({
      pathname: '/exchanges/',
    });
  };

  fetchSharedSpacesDates = async (id) => {
    try {
      const { items } = await getSharedSpaces(id, true);
      return items.map((e) => {
        return { from: e.sharedFrom, to: e.sharedTo };
      });
    } catch (e) {
      console.error(e);
    }
  };

  fetchSharedSpaces = async () => {
    let sharedSpacesAll = [];
    if (this.state.user.id) {
      const { items } = await getSharedSpacesList(
        this.state.user.id,
        'pending',
        0,
        this.props.settings.User.UiUserDetailsListCountLimit.value
      );
      sharedSpacesAll = items;
    }
    await this.setState({ sharedSpacesAll: sharedSpacesAll });
  };

  fetchSharedSpacesOccupied = async () => {
    let sharedSpacesOccupied = [];

    if (this.state.user.id) {
      const { items } = await getSharedSpacesList(
        this.state.user.id,
        'active',
        0,
        this.props.settings.User.UiUserDetailsListCountLimit.value
      );
      sharedSpacesOccupied = items;
    }

    await this.setState({ sharedSpacesOccupied: sharedSpacesOccupied });
  };

  fetchSharedSpacesHistory = async () => {
    let sharedSpacesOccupiedHistory = [];

    if (this.state.user.id) {
      const { items } = await getSharedSpacesList(
        this.state.user.id,
        'user-history',
        0,
        this.props.settings.User.UiUserDetailsListCountLimit.value
      );

      // Only booked
      // items.filter( sh => sh.bookings.length ).
      await items.forEach((sh) => {
        sharedSpacesOccupiedHistory.push(sh);
      });
    }

    await this.setState({ sharedSpacesOccupiedHistory });
  };

  fetchBookedSpaces = async () => {
    let bookedSpaces = [];

    if (this.state.user.id) {
      const { items, nextOffset } = await getUserBookedSpaces(
        this.state.user.id,
        'active',
        null,
        null,
        null,
        this.props.settings.User.UiUserDetailsListCountLimit.value
      );

      bookedSpaces = items || [];
    }

    await this.setState({ bookedSpaces });
  };

  fetchBookedSpacesHistory = async () => {
    let bookedSpacesHistory = [];

    if (this.state.user.id) {
      const { items, nextOffset } = await getUserBookedSpaces(
        this.state.user.id,
        'history',
        null,
        null,
        null,
        this.props.settings.User.UiUserDetailsListCountLimit.value
      );

      bookedSpacesHistory = items || [];
    }

    await this.setState({ bookedSpacesHistory });
  };

  fetchSharedSpaceRequests = async () => {
    let data = [];

    if (this.state.user.id) {
      const { items } = await allSharedSpacesRequests(
        this.state.user.id,
        new Date(),
        this.props.settings.User.UiUserDetailsListCountLimit.value
      );
      data = items;
    }
    this.setState({ sharedSpacesRequestsAll: data });
  };

  fetchSilencePeriods = async () => {
    let data = [];

    if (this.state.user.id) {
      try {
        const { items } = await getSilencePeriods(
          this.state.user.id,
          new Date(),
          this.props.settings.User.UiUserDetailsListCountLimit.value
        );
        data = items;
      } catch (error) {
        // TODO: call back home with errors
      }
    }
    this.setState({ silencePeriodsAll: data });
  };

  setLoadingDone = () => {
    // TODO: think of some sort of spinner, as the info required is massive and not very nicely loaded
    this.setState({
      ...this.state,
      loadingDone: true,
    });
  };

  fetchInfo = async () => {
    try {
      let user,
        canBack = null;
      const state = this.props.location.state;
      const autoMatch = await featureEnabled(AUTO_MATCH);
      const show_calendar = await featureEnabled(SHOW_CALENDAR);
      const show_iban = await featureEnabled(SHOW_IBAN);
      const show_phone = await featureEnabled(SHOW_PHONE);

      if (this.props.settings) {
        const inlcudeWeekendsInSharing = this.props.settings.Global.InlcudeWeekendsInSharing.value === 'true'
        this.setState({ inlcudeWeekendsInSharing: inlcudeWeekendsInSharing });
      }

      await this.setState({
        ...this.state,
        autoMatchFeatureEnabled: autoMatch,
        show_calendarEnabled: show_calendar,
        show_ibanEnabled: show_iban,
        show_phoneEnabled: show_phone,
        loadingDone: false,
      });
      if (state) {
        const renewUser = await getUser(state.user.id);
        user = renewUser || user;
        canBack = state.canBack || canBack;
      }
      if (!user) user = await getUser(this.props.match.params.id);

      if (user) {
        await this.fetchParkingLots();

        let vehicles = await getOwnwerVehicles(user.id);
        let spaces = await getOwnerSpaces(user.id, this.state.parkingLots);

        await getActiveInfo(user.id).then((activeInfo) => {
          this.setState({ activeInfo: activeInfo });
        })

        if (spaces && spaces.length > 1 && spaces[0].bookingLevel === 2) {
          const ids = spaces.map(x => { return x.id });
          const spaceDtos = await spacesData(ids);
          this.setState({ spaceNames: spaceDtos });
        }

        vehicles = vehicles || [];
        spaces = spaces || [];

        await this.setState({
          vehicles,
          spaces,
          user,
          canBack,
        });
        await this.fetchBookedSpaces();
        await this.fetchBookedSpacesHistory();
        await this.fetchSharedSpaces();
        await this.fetchSharedSpacesOccupied();
        await this.fetchSharedSpacesHistory();
        if (this.props.isAdmin && this.props.currentUser.id !== user.id) {
          await this.fetchDateStatuses(user.id);
        }
        if (this.props.currentUser.id === user.id) {
          this.setState({ isCurrentUser: true });
        } else {
          this.setState({ isCurrentUser: false });
        }

        // TODO: feature toggle
        if (this.state.autoMatchFeatureEnabled) {
          await this.fetchSharedSpaceRequests();
          await this.fetchSilencePeriods();
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.setLoadingDone();
  };

  fetchParkingLots = async () => {
    try {
      const resp = await getParkingLots();
      this.setState({
        parkingLots: resp.items
      });
      const resp_ = await getPrimaryParkingLots();
      this.setState({
        primaryParkingLots: [
          { id: '', name: this.props.t('spaces.All') },
          ...resp_.items,
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };



  fetchParkingSpaces = async (query, offset, loadingMore) => {
    try {
      let hasMore = false;
      const lotId =
        this.props.t('spaces.All') === this.state.selectedLot
          ? undefined
          : this.state.selectedLot.id;
      const resp = await searchAPIDebounced(lotId, query, offset);
      const items = await this.resolveSpaceLot(resp.items);
      if (resp.nextOffset) hasMore = true;
      if (!loadingMore) {
        this.setState((prevState) => ({
          parkingSpaces: [...items],
          nextOffset: resp.nextOffset,
          searching: true,
          count: resp.items,
          hasMore,
        }));
      } else {
        this.setState((prevState) => ({
          parkingSpaces: [...new Set([...prevState.parkingSpaces, ...items])],
          nextOffset: resp.nextOffset,
          searching: true,
          count: resp.items,
          hasMore,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };
  search = async (e) => {
    await this.setState({ query: e.target.value });
    await this.fetchParkingSpaces(this.state.query);
  };
  resolveSpaceLot = async (spaces) => {
    return await resolveSpaceLot(spaces, this.state.parkingLots);
  };

  setLot = (selectedLot) => {
    this.setState({ selectedLot, searching: false }, () =>
      this.fetchParkingSpaces(this.state.query, 0)
    );
  };

  fetchSpaceList = async () => { };

  newSpace = async () => {
    await this.fetchParkingSpaces();
    await this.setState({ newSpace: true, openedSpaceDialog: true });
    setTimeout(() => {
      this.setState({ dialogOpened: true });
    }, 100);
  };

  reassignSpace = async () => {
    let space = this.state.spaces[0];
    space.isOwned = true;
    this.props.history.push({
      pathname: '/spaces/reassign',
      state: { space, spaceNames: this.state.spaceNames }
    })
  };

  handleCloseSpaceDialog = async () => {
    await this.setState({ openedSpaceDialog: false });
    await this.fetchInfo();
  };

  handleCloseConfirmDialog = () => {
    this.setState({ openConfirmationDialog: false, dialogConfirmed: false });
  };

  showConfirmDialog = () => {
    this.setState({ openConfirmationDialog: true });
  };

  setupConfirmDialog = (confirmationText, onAfterConfirm) => {
    this.setState({ confirmationText: confirmationText });
    this.setState({ onAfterConfirm: onAfterConfirm });
    this.setState({
      onCancelAction: () => {
        this.handleCloseConfirmDialog()
      },
    });
  }

  deleteSpace = async (spaceId) => {
    const deleteSpace = async (spaceId) => {
      const { id, status, detail } = await setParkingOwner(spaceId);
      if (id) {
        // await this.setState({
        //   spaces: [...this.state.spaces.filter((e) => id !== e.id)],
        // });
        await this.fetchInfo();
        this.props.enqueueSnackbar(
          this.props.t('notification.success.deleteSpace'),
          {
            variant: 'success',
          }
        );
      } else if (status && status === 202) {
        this.props.enqueueSnackbar(
          this.props.t('notification.warning.' + detail),
          {
            variant: 'warning',
          }
        );
      }
    };
    deleteSpace(spaceId);

  };

  deleteSharedSpace = async (id) => {
    const { affectedIds } = await deleteShareSpace(id);
    if (affectedIds === undefined) return;

    if (affectedIds && affectedIds.length) {
      if (this.state.sharedSpacesAll) {
        let filtered = this.state.sharedSpacesAll.filter((bs) => bs.id !== affectedIds[0]);
        this.setState({ sharedSpacesAll: filtered || [] });
      }

      await this.fetchSharedSpaces();
      await this.fetchSharedSpacesOccupied();

      this.props.enqueueSnackbar(
        this.props.t('notification.success.DeleteSharedSpace'),
        {
          variant: 'success',
        }
      );
    }
  };

  deleteBookedSpace = async (id) => {
    const deleteBookedSpaceFn = async (id) => {
      this.setState({ dialogConfirmed: true });
      try {
        const { affectedIds, status, detail } = await deleteBookedSpace(id);
        if (affectedIds && affectedIds.length) {
          if (this.state.bookedSpaces) {
            let filtered = this.state.bookedSpaces.filter((bs) => bs.id !== affectedIds[0]);
            this.setState({ bookedSpaces: filtered || [] });
          }

          await this.fetchBookedSpaces();


          if (this.state.autoMatchFeatureEnabled) {
            // requires to update state of silenceperiods
            await this.fetchSilencePeriods();
          }

          this.props.enqueueSnackbar(
            this.props.t('notification.success.DeleteBookedSpaces'),
            {
              variant: 'success',
            }
          );
        } else if (status) {
          // this.handleCloseConfirmDialog();
          if (status === 203) {
            this.props.enqueueSnackbar(
              this.props.t('notification.error.' + detail),
              {
                variant: 'error',
              }
            );
          }
        }
      } catch (error) {
        // this.setState({ dialogConfirmed: false });
        // keep popup displayed
        console.error(error)
      }
    };
    deleteBookedSpaceFn(id);

  };

  bookSpace = async (bookedSpace, parkingSpaceName, parkingLotName) => {
    const bookings = await getUserBookedSpaces(
      null,
      null,
      null,
      bookedSpace.sharedSpaceId,
      true
    );
    const userBookings = await getUserBookedSpaces(
      bookedSpace.userId,
      null,
      null,
      null,
      true
    );
    const items = bookings.items.concat(userBookings.items);
    let space = await getSharedSpace(bookedSpace.sharedSpaceId);
    const parkingSpace = await getParkingSpace(space.parkingSpaceId);
    let from = null;
    let to = null;
    let sharedDates = null;
    if (items.length > 0) {
      sharedDates = items.filter((e) => e.id !== bookedSpace.id).map((e) => {
        return { from: e.bookedFrom, to: e.bookedTo };
      });
    }

    let minDate = moment.date(new Date()).utc(0).startOf('day');
    let maxDate = moment.date(new Date(space.sharedTo)).utc(0).endOf('day');
    if (from) {
      let fromDate = moment
        .date(new Date(from))
        .utc(0)
        .add(1, 'days')
        .startOf('day');
      if (minDate < fromDate) {
        minDate = fromDate;
      }
    } else {
      let fromDate = moment
        .date(new Date(space.sharedFrom))
        .utc(0)
        .startOf('day');
      if (minDate < fromDate) {
        minDate = fromDate;
      }
    }
    if (to) {
      maxDate = moment.date(new Date(to)).utc(0).add(-1, 'days').endOf('day');
    }

    minDate = minDate.format('YYYY-MM-DDT12:00:00.000').toString();
    maxDate = maxDate.format('YYYY-MM-DDT12:00:00.000').toString();

    this.props.history.push({
      pathname: '/bookings/edit',
      state: {
        space: {
          name: parkingSpaceName,
          parkingLotId: space.parkingLotId,
          parkingLotName: parkingLotName,
          parkingSpaceId: space.parkingSpaceId,
          sharedFrom: space.sharedFrom,
          sharedTo: space.sharedTo,
          ownerId: space.ownerId,
          id: space.id,
          bookingLevel: parkingSpace.bookingLevel
        },
        bookedSpace: bookedSpace,
        user: {
          name: '',
          id: bookedSpace.userId,
        },
        minDate_: minDate,
        maxDate_: maxDate,
        sharedDates: sharedDates,
      },
    });
  };

  sharedSpace = async (bookSpace, parkingSpaceName, parkingLotName) => {
    const sharedDates_ = await this.fetchSharedSpacesDates(
      bookSpace.parkingSpaceId
    );
    const parkingSpace = await getParkingSpace(bookSpace.parkingSpaceId);

    let from = sharedDates_
      .sort((a, b) => (a.to < b.to ? 1 : -1))
      .find((x) => x.to < bookSpace.sharedFrom)?.to;
    let to = sharedDates_
      .sort((a, b) => (a.from > b.from ? 1 : -1))
      .find((x) => x.from > bookSpace.sharedTo)?.from;
    let minDate = moment.date(new Date()).utc(0).startOf('day');
    let maxDate = null;
    if (from) {
      let fromDate = moment
        .date(new Date(from))
        .utc(true)
        // .add(1, 'days')
        .startOf('day');
      console.log(fromDate)
      if (minDate < fromDate) {
        minDate = fromDate;
      }
    }
    if (to) {
      let toDate = moment
        .date(new Date(to))
        .utc(true)
        .add(-1, 'days')
        .endOf('day');
      maxDate = toDate.toString();
    }
    minDate = minDate.toString();
    let sharedDates = [];
    let disabledForStart = [];
    let disabledForEnd = [];
    if (bookSpace.bookings.length > 0) {
      sharedDates = bookSpace.bookings.map((e) => {
        return { from: e.bookedFrom, to: e.bookedTo };
      });
      disabledForStart = bookSpace.bookings.map((e) => {
        return {
          from: moment
            .date(e.bookedFrom)
            .utc(true)
            .add(1, 'days')
            .format('YYYY-MM-DDT00:00:00.000')
            .toString(),
          to: e.bookedTo,
        };
      });
      disabledForEnd = bookSpace.bookings.map((e) => {
        return {
          from: e.bookedFrom,
          to: moment
            .date(e.bookedTo)
            .utc()
            .add(-1, 'days')
            .format('YYYY-MM-DDT23:59:59.999')
            .toString(),
        };
      });
    }
    this.props.history.push({
      pathname: '/exchanges/edit',
      state: {
        space: parkingSpace,
        minDate_: minDate,
        maxDate_: maxDate,
        from_: bookSpace.sharedFrom,
        to_: bookSpace.sharedTo,
        id: bookSpace.id,
        sharedDates: sharedDates,
        disabledForStart: disabledForStart,
        disabledForEnd: disabledForEnd,
      },
    });
  };

  deleteSharedSpaceRequestFn = async (id) => {
    const deleteSharedSpaceRequestFn = async (id) => {
      const { affectedIds } = await deleteSharedSpaceRequest(id);

      if (affectedIds && affectedIds.length) {
        await this.fetchSharedSpaceRequests();
        this.props.enqueueSnackbar(
          this.props.t('notification.success.DeleteSharedSpaceRequest'),
          {
            variant: 'success',
          }
        );


      }
    };
    deleteSharedSpaceRequestFn(id);
  };

  deleteSilencePeriodFn = async (id) => {

    try {
      const { affectedIds } = await deleteSilencePeriod(id);

      if (affectedIds && affectedIds.length) {
        await this.fetchSilencePeriods();
        this.props.enqueueSnackbar(
          this.props.t('notification.success.DeleteSilencePeriod'),
          {
            variant: 'success',
          }
        );
      }
      //  this.handleCloseConfirmDialog();
    } catch (error) {
      // not sure if console is good thing
      // id rather do some call home
    }
    //  this.setupConfirmDialog('confirm.Delete', () => deleteSilencePeriodFn(id));
    //  this.showConfirmDialog();
  };

  clickOnSpace = async (space) => {
    const assignSpace = async (space) => {
      try {
        const resp = await setParkingOwner(space, this.state.user.id);
        if (resp) {
          this.props.enqueueSnackbar(
            this.props.t('notification.success.AssignSpace'),
            {
              variant: 'success',
            }
          );
          this.handleCloseConfirmDialog();
          this.handleCloseSpaceDialog();
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (this.state.bookedSpaces.length > 0) {
      this.setupConfirmDialog('confirm.AssignSpace', () => assignSpace(space));
      this.showConfirmDialog();
    } else {
      assignSpace(space);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount = async () => {
    // TODO: move to signalr service
    const hubConnection = new HubConnectionBuilder()
      .withUrl(getHubPath()).withAutomaticReconnect()
      .build();

    this.setState({ hubConnection }, () => {
      this.state.hubConnection.start()
        .then(() => console.log('Connection started!'))
        .catch(err => console.log('Error while establishing connection :('));

      this.state.hubConnection.on("ReceiveMessage", data => {
        if (data === 'dashboard') {
          this.fetchBookedSpaces();
        }

        if (data === 'userdetails') {
          this.fetchSilencePeriods();
        }

        if (data === 'settings') {
          this.setState({ lastUpdateDt: new Date() })
        }
      });
    });

    await this.fetchInfo();
  };

  componentWillUnmount = async () => {
    if (this.state.hubConnection) {
      this.state.hubConnection.stop();
    }
  };

  fetchDateStatuses = async (userId) => {
    try {
      const resp = await getDateStatuses(userId);
      if (resp) {
        this.setState({ dateStatuses: resp });
        if (this.state.dateStatuses[0].ownerSpaces && this.state.dateStatuses[0].ownerSpaces.length > 0) {
          const periods = this.state.dateStatuses[0].periods.filter(x => x.parkingSpaceId === this.state.dateStatuses[0].ownerSpaces[0].ownerSpaceId);
          this.setState({ periods: periods })
        } else {
          this.setState({ periods: this.state.dateStatuses[0].periods })
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  displayUserSpecificList = () => {
    return (
      this.state.autoMatchFeatureEnabled &&
      this.state.loadingDone &&
      this.state.spaces.length <= 0
    );
  };

  getMonth = async (date) => {
    this.setState({ defaultDate: date });
  };

  changeIndex = async (rise) => {
    let newIndex = 0;
    let stateIndex = this.state.index;
    if (rise === true) {

      newIndex = stateIndex + 1;
      if (this.state.dateStatuses[0].ownerSpaces && this.state.dateStatuses[0].ownerSpaces.length > newIndex) {
        this.setState({ index: newIndex });
        stateIndex = newIndex;
      }
    } else {
      newIndex = stateIndex - 1;
      if (newIndex > -1) {
        this.setState({ index: newIndex });
        stateIndex = newIndex;
      }
    }
    const periods = this.state.dateStatuses[0].periods.filter(x => x.parkingSpaceId === this.state.dateStatuses[0].ownerSpaces[stateIndex].ownerSpaceId);
    this.setState({ periods: periods })
  }

  render() {
    const {
      user,
      vehicles,
      spaces,
      bookedSpaces,
      bookedSpacesHistory,
      sharedSpacesAll,
      sharedSpacesOccupied,
      parkingLots,
      selectedLot,
      parkingSpaces,
      dialogOpened,
      sharedSpacesOccupiedHistory,
      sharedSpacesRequestsAll,
      openedSpaceDialog,
      canBack,
      silencePeriodsAll,
      dateStatuses,
      defaultDate,
      isCurrentUser,
      primaryParkingLots,
      spaceNames,
      index,
      periods,
      openConfirmationDialog,
      onAfterConfirm,
      onCancelAction,
      confirmationText,
      dialogConfirmed,
      autoMatchFeatureEnabled,
      activeInfo,
      show_calendarEnabled,
      show_ibanEnabled,
      show_phoneEnabled,
      inlcudeWeekendsInSharing
    } = this.state;
     const { isAdmin, settings, t } = this.props;
    const currentLng = localStorage.getItem('currentLng');

    return (
      <React.Fragment>
        <ConfirmDialog
          open={openConfirmationDialog}
          handleClose={onCancelAction}
          handleYes={onAfterConfirm}
          t={t}
          text={confirmationText}
          clicked={dialogConfirmed}
        ></ConfirmDialog>
        <Dialog
          fullScreen
          open={openedSpaceDialog}
          onClose={this.handleCloseSpaceDialog}
        >
          <div className="spacesWrap">
            <Topbar
              cbBack={this.handleCloseSpaceDialog}
              title={t('spaces.Assign')}
              location={this.props.location}
            >

            </Topbar>
            <Grid
              container
              marginTop="50px"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <InputSelect
                options={primaryParkingLots}
                onChange={this.setLot}
                value={selectedLot}
                getOptionLabel={(option) => option['name']}
                getOptionValue={(option) => option['id']}
                placeholder={t('spaces.All')}
              />
              <InputSearch
                onChange={this.search}
                placeholder={t('general.Search')}
              />
            </Grid>
            {dialogOpened && (
              <List className="spacesAssingContainer" id="testas">
                <InfiniteScroll
                  scrollableTarget="testas"
                  dataLength={parkingSpaces.length}
                  hasMore={this.state.hasMore}
                  next={() =>
                    this.fetchParkingSpaces(
                      this.state.query,
                      this.state.nextOffset,
                      true
                    )
                  }
                  loader={<p>{t('general.loading')}...</p>}
                >
                  {' '}
                  {parkingSpaces.length > 0 &&
                    parkingSpaces.map((e, i) => (
                      <SpacesOwnerListItem
                        key={i}
                        space={e}
                        onSelect={this.clickOnSpace}
                      />
                    ))}
                </InfiniteScroll>
              </List>
            )}
          </div>
        </Dialog>
        <Topbar
          cbBack={canBack && this.goBack}
          location={this.props.location}
          cbSettings={this.settings}
          title={isCurrentUser ? t('users.MyProfile') : (isCurrentUser === false ?
            "Naudotojo paskyra" : "")}
        >
        </Topbar>
        <div className="ProfileContainer">
          <Grid container spacing={3} padding='16px'>
            <Grid item>
              <Grid container alignItems="center">
                <Avatar sx={{ height: 80, width: 80 }} />
              </Grid>
            </Grid>
            <Grid item>
              <Grid xs  >
                <Typography className="h1" color='#3A4D8F'> {user.name}</Typography>
                <Typography className="body"> {user.email}</Typography>
                {(show_phoneEnabled) && <Typography className="body"> {user.phone}</Typography>}
                {(show_ibanEnabled) && <Typography className="body"> {user.iban}</Typography>}
                <Typography className="h5" onClick={this.editUser} color='#6A95CC' >  {t('users.edit')} </Typography>
                {/*<Button sm={{alignItems='left'}} onClick={this.editUser} variant="text">Redaguoti</Button>*/}
              </Grid>
            </Grid>

          </Grid>

          {(activeInfo) && (< ActiveInfo
            spaces={spaces}
            activeInfo={activeInfo}
          ></ActiveInfo>
          )}
          <VehiclesOwnerList
            onCreate={
              vehicles.length < parseInt(settings.User.VehiclesLimit.value) &&
              this.newVehicle
            }
            onDelete={this.deleteVehicle}
            vehicles={vehicles}
            activeBookingsAmount={bookedSpaces && bookedSpaces.length}
            activeSubscriptionsAmount={autoMatchFeatureEnabled && sharedSpacesRequestsAll && sharedSpacesRequestsAll.length}
          />

          {(spaces.length > 0 || isAdmin) && (
            <SpacesOwnerList
              onCreate={
                isAdmin &&
                spaces.length <
                parseInt(settings.User.ParkingSpotsLimit.value) &&
                this.newSpace
              }
              onDelete={isAdmin && this.deleteSpace}
              onShare={this.exchangeSpace}
              spaces={spaces}
              spaceNames={spaceNames}
              hideOwnerIcon={true}
              listLimit={settings.User.UiUserDetailsListCountLimit.value}
              onReassign={isAdmin && spaces.length > 0 && autoMatchFeatureEnabled && this.reassignSpace}
            />
          )}

          {/* 1. Mano pasidalintos vietos, kurių dar niekas neužėme */}
          {sharedSpacesAll && sharedSpacesAll.length > 0 && spaces && parkingLots && (
            <ExchangesList
              exchanges={sharedSpacesAll}
              isAdmin={isAdmin}
              onDelete={this.deleteSharedSpace}
              needLoad={false}
              load={this.fetchSharedSpaces}
              title={t('exchanges.Sharing')}
              onBookSpace={this.sharedSpace}
              canEdit={true}
              parkingSpaces={spaces}
              parkingLots={parkingLots}
              spaceNames={spaceNames}
            />
          )}

          {/* 2. Mano pasidalintos vietos, kurios jau užimtos */}
          {sharedSpacesOccupied && sharedSpacesOccupied.length > 0 && (
            <BookedList
              bookedSpaces={sharedSpacesOccupied}
              title={t('exchanges.sharedSpacesOccupied')}
              parkingSpaces={spaces}
              parkingLots={parkingLots}
              spaceNames={spaceNames}
            />
          )}

          {/* 3. Mano pasidalintos vietos, kurios jau užimtos ir buvo panaudotos */}
          {sharedSpacesOccupiedHistory &&
            sharedSpacesOccupiedHistory.length > 0 && (
              <BookedList
                bookedSpaces={sharedSpacesOccupiedHistory}
                title={t('exchanges.sharedSpacesOccupiedHistory')}
                parkingSpaces={spaces}
                parkingLots={parkingLots}
                spaceNames={spaceNames}
              />
            )}

          {/* 4. Aktyvios mano rezervacijos (kurias vietas aš rezervavau) */}
          {bookedSpaces && bookedSpaces.length > 0 && (
            <ExchangesBookingList
              my={true}
              onCreate={this.goToShares}
              onDelete={this.deleteBookedSpace}
              //onDelete={this.bookSpace}
              bookedSpaces={bookedSpaces}
              needLoad={false}
              onBookSpace={this.bookSpace}
              canEdit={true}
              parkingSpaces={spaces}
              parkingLots={parkingLots}
            />
          )}

          {/*/!* 5. Istorinės mano rezervacijos (kurias vietas aš rezervavau) *!/*/}
          {/*{bookedSpacesHistory && bookedSpacesHistory.length > 0 && (*/}
          {/*  <ExchangesBookingList*/}
          {/*    my={true}*/}
          {/*    onDelete={false}*/}
          {/*    bookedSpaces={bookedSpacesHistory}*/}
          {/*    needLoad={false}*/}
          {/*    title={t('exchanges.BookingsHistory')}*/}
          {/*    canEdit={false}*/}
          {/*    parkingSpaces={spaces}*/}
          {/*    parkingLots={parkingLots}*/}
          {/*  />*/}
          {/*)}*/}

          {/* 6. my shared space requests */}
          {this.displayUserSpecificList() && (
            <SubscriptionList
              onDelete={this.deleteSharedSpaceRequestFn}
              sharedSpaceRequests={sharedSpacesRequestsAll}
              title={t('subscriptions.Requests')}
              user={this.state.user}
              subscribe={true}
              isAdmin={isAdmin}
            />
          )}

          {/* 7. my silence periods */}
          {this.displayUserSpecificList() && (
            <SubscriptionList
              onDelete={this.deleteSilencePeriodFn}
              sharedSpaceRequests={silencePeriodsAll}
              title={t('subscriptions.SilencePeriods')}
              user={this.state.user}
              subscribe={false}
              isAdmin={isAdmin}
            />
          )}

          {/*calendar*/}
          {show_calendarEnabled && dateStatuses && isAdmin && dateStatuses[0] && (
            <MonthInformation
              item={dateStatuses[0]}
              currentLng={currentLng}
              getMonth={this.getMonth}
              defaultDate={defaultDate}
              index={index}
              changeIndex={this.changeIndex}
              periods={periods}
              inlcudeWeekendsInSharing={inlcudeWeekendsInSharing}
            ></MonthInformation>
          )}

          {/*Monthly history */}
          {user && isCurrentUser !== null &&
            <Grid container direction='row' py={2} p={2} xs={12} onClick={this.activeHistory}>
              <Grid item xs>
                <Typography className="reportPanelTitle">
                  {t("UserDetails.CurrentMonthActionHistory")}
                </Typography>
              </Grid>
              <Grid item>
                <FontAwesomeIcon icon={faChevronRight} size="xs" />
              </Grid>
            </Grid>
          }
          <Divider />

          {/*Ataskaitos */}
          {user && isCurrentUser !== null &&
            <Grid container direction='row' py={2} p={2} xs={12} onClick={this.monthlyReports}>
              <Grid item xs>
                <Typography className="reportPanelTitle">
                  {t("UserDetails.Reports")}
                </Typography>
              </Grid>
              <Grid item>
                <FontAwesomeIcon icon={faChevronRight} size="xs" />
              </Grid>
            </Grid>
          }

        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(UserDetails))
