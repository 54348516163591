import React, { useState } from 'react';
import './DateTimeSettingForm.scss';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Typography , Grid} from '@mui/material';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { DateTimeDisabled } from '../SettingProperties';

// REF: currentUser is props passed from router
export default function DateTimeSettingForm({
                                              isAdmin,
                                              clearEnabledPrp,
                                              setting,
                                              onUpdateSettingItem,
                                              onSaveDate,
                                              autoMatch
                                            }) {
  const { t } = useTranslation();


  const currentLng = localStorage.getItem('currentLng');
  const moment = new MomentUtils({ locale: currentLng });

  const initTime = () => {
    //console.log(setting)
    if (setting && setting.value) {
      if (setting.value === DateTimeDisabled) {
        return null;
      }
      return moment.date('1970-01-01 ' + setting.value);
    }
    return null;
  };

  const [time, setTime] = useState(initTime());
  const initClearEnabled = () => {
    if (clearEnabledPrp) {
      return clearEnabledPrp;
    }
    return false;
  };

  const [clearEnabled, setClearEnabled] = useState(initClearEnabled());
  const formatEndOfDay = (time) => {
    if (time.hours() === 23 && time.minutes() === 59) {
      time.seconds(59);
    } else {
      time.seconds(0);
    }
    return time.format('HH:mm:ss');
  };

  const onChange = (momentDate) => {
    setTime(momentDate);
    setting.value = !momentDate ? DateTimeDisabled : formatEndOfDay(momentDate);
    onUpdateSettingItem(setting);
    onSaveDate(setting);
  };

  return (
      <React.Fragment>

        {(setting.property === 'DeadlineTime' && !isAdmin && !autoMatch) ?

            ( <Grid className = 'timePicker' > <Typography className='h3'>
              {setting.value}
            </Typography> </Grid>):
            // TODO: only 24 hour time is possible currently in app
            (<MuiPickersUtilsProvider utils={MomentUtils} locale="lt-LT">
              <Grid  className = 'timePickerActive'>
                <TimePicker
                    ampm={false}
                    views={['hours', 'minutes']}
                    onChange={onChange}
                    minutesStep={5}
                    value={time}
                    cancelLabel={t('general.Cancel')}
                    okLabel={t('general.Save')}
                    emptyLabel={t('general.Disabled')}
                    clearLabel={t('general.Clear')}
                    clearable={clearEnabled}
                />
              </Grid>
            </MuiPickersUtilsProvider>)
        }
      </React.Fragment>
  );
}
