import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import './PublicMap.scss';
import { Box, Container, Typography, Grid, Card, IconButton, Button, List, ListItem, Divider, Dialog, TextField, InputAdornment } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import config from '../../../config';
import { getPublicTerrtories, getPublicLot } from 'utils/TerritoriesService';
import { PublicFooter } from 'components/public/PublicFooter';
import icon_gps_fixed from '../../../assets/icons/publicApp/icon_gps_fixed.png';
import button_navigate from '../../../assets/icons/publicApp/button_navigate.svg';
import Frame_3007 from '../../../assets/icons/publicApp/Frame_3007.svg';
import Frame_3005 from '../../../assets/icons/publicApp/Frame_3005.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import icon_search_active from '../../../assets/icons/publicApp/icon_search_active.svg';
import icon_search from '../../../assets/icons/publicApp/icon_search.svg';

class PublicMap extends Component {
    constructor() {
        super();
        this.state = {
            defaultProps: {
                center: config.googleMap.center,
                zoom: 15
            },
            lots: [],
            containerStyle: {
                width: '100%',
                height: '100%'
            },
            map: /**@type google.maps.Map */ (null),
            autocomplete: /**@type google.maps.places.Autocomplete */ (null),
            anchor: /**@type google.maps.Point */ { x: 18, y: 18 },
            response: null,
            directions: null,
            destination: null,
            showLot: false,
            selectedLot: {},
            googleMapsApiKey: config.googleMap.googleMapsApiKey,
            mapId: config.googleMap.mapId,
            mapLoaded: false,
            positionLoaded: false,
            showLots: true,
            mounted: true,
            open: false,
            searchLots: '',
            publicLotInfo: null
        }
    }

    componentWillUnmount = () => {
        this.setState({ mounted: false })
    }

    clickLot = (id) => {
        const selectedLot = this.state.lots.find(x => x.id == id);
        this.setState({ selectedLot: selectedLot });
        this.setState({ showLot: true });
        getPublicLot(selectedLot.id).then((response) => {
            this.setState({publicLotInfo: response})
        })
    };

    onMapClick = async () => {
        const selectedLot = {}
        this.setState({ selectedLot: selectedLot });
        this.setState({ showLot: false });
        this.setState({publicLotInfo : null})
    }

    setPosition = async (position) => {
        this.setState(prevState => ({
            defaultProps: {
                ...prevState.defaultProps,
                center: { lat: position.coords.latitude, lng: position.coords.longitude }
            }
        }))

        this.setState({ positionLoaded: true })

        if (this.state.mapLoaded) {
            this.state.map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
        }
        setTimeout(() => {
            if (this.state.mapLoaded) {
                this.state.map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
            }
        }, 1000)
    }

    goPublic = (pathname) => {
        if (pathname !== '/publicMap') {
            this.props.history.push({
                pathname: pathname,
            });
        }
    }

    componentDidMount = async () => {

        getPublicTerrtories().then((response) => {
            if (this.state.mounted) {
                this.setState({ lots: response });
            }
        })
        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition(this.setPosition, null, {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0
            });
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    setMap = async (map) => {
        this.setState({ map: map, mapLoaded: true });
        if (this.state.positionLoaded) {
            map.panTo(this.state.defaultProps.center)
        }
    };

    goCenter = async () => {
        this.state.map.panTo(this.state.defaultProps.center)
        this.setState({ destination: null });
        this.setState({ response: null });
        this.onMapClick();
    };

    onPlaceChanged = (selectedLot) => {

        this.setState({ selectedLot: selectedLot });
        this.setState({ showLot: true })
        this.setState({ open: false })
        const center = {
            lat: Number(selectedLot.center.lat),
            lng: Number(selectedLot.center.lng)
        }
        console.log(selectedLot)
        getPublicLot(selectedLot.id).then((response) => {
            this.setState({publicLotInfo: response})
        })
        setTimeout(() => {
            this.state.map.panTo(center)
        }, 500)
 
    }

    onCenterChanged = (map) => {
        if (map) {
            map.panTo(this.state.defaultProps.center)
        }
    }

    onMapZoom = () => {
        if (this.state.map) {
            const zoom = this.state.map.getZoom();
            const showLots = zoom > 9;
            this.setState({ showLots: showLots })
        }
    }

    fnShowLots = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    };

    handleCard = (event) => {
        this.setState({ searchLots: event.target.value })
    }

    render() {

        const { t } = this.props;
        const { defaultProps, lots, containerStyle, showLot, selectedLot, googleMapsApiKey, mapId, anchor, showLots, positionLoaded, open, searchLots, publicLotInfo } = this.state;

        const options = { closeBoxURL: '', enableEventPropagation: true, width: 'auto', alignBottom: false };

        return (
            <div className='public-form'>
                <Box className='box-fixed'>
                </Box>
                <div style={{ height: '100vh', width: '100%' }}>
                    <LoadScript
                        googleMapsApiKey={googleMapsApiKey}
                        libraries={["places"]}
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={defaultProps.center}
                            zoom={defaultProps.zoom}
                            options={{
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                                zoomControl: false,
                                mapId: mapId
                            }}
                            onLoad={(map) => this.setMap(map)}
                            onClick={() => this.onMapClick()}
                            onZoomChanged={() => this.onMapZoom()}
                        >
                            {positionLoaded && (<Marker position={{ lat: defaultProps.center.lat, lng: defaultProps.center.lng }}
                                icon={{
                                    url: button_navigate,
                                    anchor: anchor
                                }}
                            />)}

                            {lots.map((el, i) => (
                                showLots ?
                                    <Marker position={{ lat: Number(el.center.lat), lng: Number(el.center.lng) }}
                                        icon={{
                                            url: selectedLot.id === el.id ? Frame_3005 : Frame_3007,
                                        }}
                                        key={i}
                                        onClick={() => this.clickLot(el.id)}
                                        zIndex={selectedLot.id === el.id ? 100 : 0}
                                    />
                                    : null
                            ))}
                        </GoogleMap>
                    </LoadScript>
                </div>
                <IconButton
                    style={{
                        position: "absolute",
                        top: selectedLot.id ? "70%" : "75%",
                        left: "90%",
                        transform: "translate(-50%, -50%)",
                    }}
                    onClick={this.goCenter}
                    sx={{ touchAction: 'none' }}
                >
                    <img src={icon_gps_fixed} />
                </IconButton>
                <Container className='map-footer-white' sx={{ touchAction: 'none' }}  >
                    {(!showLot) && <Box sx={{ pt: 3 }}>
                        <Button
                            type="button"
                            sx={{ p: 0, textTransform: 'none' }}
                            onClick={this.fnShowLots}
                            variant="contained"
                            className='map-seach-lots'
                            startIcon={<img src={icon_search} />}
                        >
                             {t('publicApp.ParkingLots')}
                        </Button>
                    </Box>}
                    {(showLot) && <Box sx={{ pt: 2 }}>

                        <Typography variant="h3" gutterBottom>
                            {selectedLot.name}
                        </Typography>
                        {publicLotInfo && (<Grid container spacing={2} sx={{ pb: 1 }}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5}>
                                <Card sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontSize: 20, pt: 1 }} color='primary' gutterBottom>
                                        {t('publicApp.FreeSpaces')}
                                    </Typography>
                                    <Typography sx={{ p: .1 }} color='primary' variant="h3" gutterBottom>
                                        {publicLotInfo.freeSpaces}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={5}>
                                <Card sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontSize: 20, pt: 1 }} color='primary' gutterBottom>
                                         {t('publicApp.PriceFrom')}
                                    </Typography>
                                    <Typography sx={{ p: .1 }} color='primary' variant="h3" gutterBottom>
                                        {publicLotInfo.parkingRateHourly + ' ' +  selectedLot.currencyShort}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>)}
                        <Box sx={{ textAlign: 'center', p: 1 }}>
                            {/* <Typography sx={{ pb: 1 }} color="grey" gutterBottom>
                                First 30 min Free
                            </Typography> */}
                            <Typography sx={{ pb: 1 }} gutterBottom>
                                {t('publicApp.YourParkingWillStartAutomatically')}
                            </Typography>
                        </Box>
                    </Box>}
                </Container>
                <PublicFooter path='map' goPublic={this.goPublic} />
                <Container className='map-footer-blue_' sx={{ touchAction: 'none' }}>
                </Container>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    className='public-dialog'
                >
                    <Container className='map-header-blue' sx={{ position: 'fixed' }}>
                        <Grid container sx={{ mb: 2, pt: 1 }}>
                            <Grid item xs={1}>
                                <IconButton sx={{ mt: 1, color: 'white' }} onClick={this.handleClose}>
                                    <FontAwesomeIcon icon={faChevronLeft} size="1x" sx={{ fontWeight: 400 }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="h3" gutterBottom sx={{ mt: 2, ml: 1, fontWeight: 400, color: 'white' }}>
                                {t('publicApp.Explore')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <TextField
                            id="name"
                            onChange={this.handleCard}
                            className="inputLots"
                            placeholder={t('publicApp.FindParkingLot')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <img src={icon_search_active} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Container>
                    <div style={{ top: 125, position: 'relative', overflow: 'auto' }}>
                        <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3, mb: 1 }} className='border-card'>
                            <List>
                                {lots.map((el, i) => (
                                    el.name.toLowerCase().includes(searchLots.toLowerCase()) || searchLots === '' ?
                                        <span key={i}>
                                            {/* {i > 0 && ()} */}
                                            <ListItem button onClick={() => this.onPlaceChanged(el)} >
                                                <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                                    {el.name}
                                                </Typography>
                                            </ListItem>
                                            <Divider />
                                        </span> : null
                                ))}
                            </List>
                        </Card>
                    </div>
                </Dialog>
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(PublicMap));