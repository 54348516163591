import { axiosA } from './AuthService';

const postPayment = async (lot, userId, payment, id, response, plateNum) => {
	try {
		const body = {
			id: id,
			territoryId: lot.id,
			userId: userId,
			amount: payment.amount,
			parkingLogId: payment.parkingLogId,
			currencyCode: lot.currencyCode,
			response: response,
			plateNum: plateNum
		}
		const { data } = await axiosA.post('/Payments', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const checkPayment = async (userId, hasConfirm) => {
	try {
		const body = {
			userId: userId,
			hasConfirm: hasConfirm
		}
		const { data } = await axiosA.post('/Payments/checkPayment', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}

const confirmPayment = async (payment) => {
	try {
		const body = {
			id: payment.id,
			bankStatus: payment.bankStatus,
			cardStatus: payment.cardStatus,
			statusGroup: payment.statusGroup
		}
		const { data } = await axiosA.post('/Payments/confirmPayment', body)
		return data
	} catch (e) {
		throw new Error(e)
	}
}


export {
	postPayment,
	checkPayment,
	confirmPayment
}