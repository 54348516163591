import axios from 'axios';
import Api from '../constants/Api';
import {NotificationManager} from 'react-notifications';
import i18next from 'i18next';
import * as jwtDecode from 'jwt-decode';
import { getTenantPrefix } from './ProxyPathService';
import {Redirect} from "react-router-dom";
import React from "react";

let vapidPubKey = '';


const getLoginUiConfig = async () => {
  const loginConfig = {
    enableSignup: false,
    enableSocialLogin: false,
  };
  try {
    const { data } = await axios.get('./config.json');
    loginConfig['enableSignup'] = data.EnableSignup;
    loginConfig['enableSocialLogin'] = data.EnableSocialLogin;
  } catch (error) {
    // failed for unknown reasons
  }

  return loginConfig;
};

const getApiBaseUrl = async () => {
  const userSite = localStorage.getItem("userSite");

  if(!window.baseURL || userSite) {
    const {data} = await axios.get('./config.json')
    const host = userSite ? userSite : data.HOST;
    const tenantPrefix = getTenantPrefix();
    const prfix = tenantPrefix && tenantPrefix !== '' ? tenantPrefix + '/' : '';
    const apiBasePath = data.BASE.replace('/api/',`/api/${prfix}`)
    window.baseURL = `${process.env.NODE_ENV === 'production' ? host : ''}${apiBasePath}`
    vapidPubKey = data.VAPID_PUB;
    localStorage.removeItem("userSite" );
  }

  return window.baseURL
}
const refresh = async () => {
  const {data} = await axiosA.get(`/auth/token`)
  setAccessToken(data)
  if(data.token) return data.token;
  return false
}
const isValidToken = async () => {
  // requset token validation 
  // undefined token neturi buti
  let tok = getAccessToken();
  if(!tok) {
    return false;
  } else {
    const info = await jwtDecode(tok)
    const secondsLeft = info.exp - (parseInt(Date.now() / 1000))
    if (secondsLeft < Api.RE_T_IF && !info?.firebase) {
      // TODO: ensure redirect to login page
      logout()
      return false;
    }  
    return true;

  }
} 

const invalidateUserCache = () => {
  let tok = getAccessToken();
  if (!tok) {
    return false;
  } else {
    const info = jwtDecode(tok);
    if (!sessionStorage.user) return false;
    const userInCache = JSON.parse(sessionStorage.user);
    return info.email !== userInCache.email;
  }
}

const getAccessToken = (prop) => {

  return localStorage.getItem(Api.ACCESS_KEY)
}

const setAccessToken = async ({token}) => {
  localStorage.setItem(Api.ACCESS_KEY, token)
}

const removeAccessToken = () => {
  return localStorage.removeItem(Api.ACCESS_KEY)
}
const logout = () => {
  return removeAccessToken();
}

const authenticate = async (email) => {
  try {
    const { status, data } = await axiosA.get(
      `/auth/authenticate?email=${email}`
    );
    if (status === 200) {
      NotificationManager.info(i18next.t('auth.AuthLinkSend'));
      return data?.token
    }
  }catch(e) {
    throw new Error();
  }
// succes msg
}

const getIsActive = async() => {
  const { data } = await axiosA.get('/auth');
  return data
}
const login = async (type) => {
  const {data} = await axiosA.get(`/auth/login?token=${type}`)
  setAccessToken(data)
  if(data.token) return true;
 }

const axiosA = axios.create({
  baseURL: 'Api.BASE_URL',
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': ''
  },
})
const requestInterceptor = async (config) => {
  const token = getAccessToken()
  const baseURL = await getApiBaseUrl()
  config.baseURL = baseURL;
  if (token) config.headers[Api.TOKEN_HEADER_KEY] = `${Api.BEARER} ${token}`

  return config
}

axiosA.interceptors.request.use(requestInterceptor)
axiosA.interceptors.response.use((response) => {
  return response
}, function (error) {
  const eStatus = error.response.status
  if (eStatus === 400 || eStatus === 401 || eStatus === 404 || eStatus === 403
     || eStatus === 451) {
    if (eStatus === 401) {
      console.log("error 401", eStatus);
      localStorage.clear();
      window.location.reload(false);
      // <Redirect
      //     to={{
      //       pathname: "/login"
      //       // state: { from: props.location }
      //     }}
      // />
      //localStorage.clear();
    }
    if (eStatus === 451) {
      if (window.location.pathname !== '/consent') {
        NotificationManager.error(
          i18next.t('consent.redirectTo'),
          i18next.t(`error.consentUnsigned`),
          0,
          () => {
            localStorage.setItem(
              'redirectToAfterConsent',
              window.location.pathname
            );
            window.location.pathname = `${error.response.headers['location']}`;
          }
        );
      }
      return Promise.reject('consent.redirectTo');
    }

    let e = error.response.data;
    let msg = '';
    if (e.details) {
      e.details.forEach((element) => {
        msg += `${i18next.t('error.' + element.errorKey)} \r\n`;
      });
    }
    if (eStatus === 404 && window.location.pathname.indexOf('login') !== -1) {
      NotificationManager.error(msg, i18next.t(`error.UserLoginError`));
    } else {
      NotificationManager.error(msg, i18next.t(`error.${e.errorKey}`));
    }
    return Promise.reject(msg);

  }

  if (error.response.status === 500) {

    NotificationManager.error(`${i18next.t(`error.id`)}: ${error.response.data.errorId}`, i18next.t(`error.500`), 0)
    return Promise.reject(error.response.data.errorId);
  }

  if (
    error.response.status === 502 ||
    error.response.status === 503 ||
    error.response.status === 504
  ) {
    console.log( "error",  error.response.status)
    NotificationManager.error(
      `${i18next.t(`error.SystemAwayPleaseTryLater`)}`,
      i18next.t(`error.${error.response.status}`),
      0
    );
    return Promise.reject(error.response.data.errorId);
  }

  return Promise.reject(error.response);
}
)

export {
  axiosA,
  login,
  logout,
  isValidToken,
  authenticate,
  vapidPubKey,
  invalidateUserCache,
  getLoginUiConfig,
  getIsActive,
  getAccessToken
};
