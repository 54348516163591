import React, { Component } from 'react';
import '../../app/App';
import './Sidenav.scss';
import { withTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocationSelection from './LocationSelection';
import { faParking, faChartBar, faExchangeAlt, faSignOutAlt, faClipboardList, faTachometerAlt, faCog, faBars } from '@fortawesome/free-solid-svg-icons';
import { Grid, List, Divider, SwipeableDrawer, ListItemIcon, ListItemText, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import i18n from 'utils/i18n';
import { getUserSettings, requestNotificationPermissionAndUpdate } from 'utils/SettingsService';
import { getOwnerParkingSpaces, getParkingSpaces } from 'utils/ParkingService';
import SubSidenav from './sub/SubSidenav';
import { getUser } from 'utils/UsersService';
import { getConsent } from 'utils/ConsentService';
import { isMultiTouch } from 'utils/bridge';
import IconButton from '@mui/material/IconButton';
import Colors from '../../../constants/Colors';
import { PARKING_GROUPS } from 'constants/Features';
import { featureEnabled } from 'utils/FeatureTogglerService';

class Sidenav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      left: false,
      lng: localStorage.currentLng ? localStorage.currentLng : 'lt-LT',
      formLang: '',
      locales: [],
      openDialog: false,
      name: '',
      showItem: 
      {
        "spaces.Title" : false,
        "groups.groups": false
      },
      tenantId: null
    }
    this.navList = [
      {
        title: 'dashboard.Title',
        path: '/dashboard',
        icon: faTachometerAlt,
        userVisible: true,
        adminVisible: true
      },
      {
        title: 'exchanges.Title',
       path: '/exchanges',
        icon: faExchangeAlt,
        adminVisible: true,
        userVisible: true,
      },
      {
        title: 'reports.Title',
        icon: faChartBar,
        adminVisible: true,
        subItems: [
          {
            title: 'reports.Statistic',
            path: '/reports'
          },
          {
            title: 'reports.Action',
            path: '/reports/action'
          }
        ]
      },
      {
        title: 'administration.Title',
        icon: faClipboardList,
        adminVisible: true,
        subItems: [
          {
            title: 'users.Title',
            path: '/users',
          },
          {
            title: 'settings.SystemSettings',
            path: '/settings',
            icon: faCog,
            adminVisible: true,
            userVisible: true,
          },
          {
            title: 'parkinger.Rates',
            path: '/parkingRates',
            adminVisible: true,
            userVisible: true,
          },
          {
            title: 'administration.Territories',
            path: '/administration',
          },
          {
            title: 'spaces.Title',
            path: '/spaces',
            icon: faParking,
            adminVisible: true,
          },
          {
            title: 'administration.Consent',
            path: '/consent/edit',
          },
          {
            title: 'administration.Translations',
            path: '/translations',
          },
          {
            title: 'groups.groups',
            path: '/parkingGroups',
          },
        ]
      },
      {
        title: 'administration.Consent',
        path: '/consent',
        icon: faClipboardList,
        adminVisible: true,
        userVisible: true,
      }
    ]
  }

  checkConsent = (opened) => {
    if (!opened) return;

    getConsent().then(val => {
      if (!val) {
        this.props.setConsent(val)
        this.props.history.push('/consent')
      }
    }).catch(reason => {
      // on next navigation will be checked
    })
  }

  postDeviceToken = (opened) => {
    if (!opened) return;
    if (!isMultiTouch()) return;

    // requestNotificationPermissionAndUpdate(null, null, { NotifyPush: true })
    //   .then((ok) => { })
    //   .catch((err) => {
    //     //do next time
    //   });

  }

  toggleNav = (opened) => {
    this.checkConsent(opened)
    this.postDeviceToken(opened)
    this.setState({ ...this.state, left: opened })
  }

  closeSidebar = () => {
    const { location } = this.props;
    this.props.history.push({
      state: {
        openSideNav: false
      }
    });
  }

  setActiveLang = async () => {
    try {
      const thisLang = localStorage.currentLng;
      const items = await getUserSettings(this.props.currentUser.id);
      const LocaleSettings = await items.find(i => i.property === 'Locale');
      if (!LocaleSettings && !thisLang) {
        localStorage.setItem('currentLng', 'lt-LT');
      } else {
        localStorage.setItem('currentLng', LocaleSettings.value);
      }
      await i18n.changeLanguage(LocaleSettings.value);
    } catch (e) {
      console.log(e)
    }
  }

  componentWillReceiveProps(props) {
    const { location } = props
    // this.openLocales();
    if (location && location.state && location.state.openSideNav) {
      this.toggleNav(true);
    } else {
      this.toggleNav(false);
    }
  }

  async componentDidMount() {

    const tenantId = localStorage.getItem('tenantId');
    if (tenantId) {
      this.setState({tenantId: tenantId});
      await this.setActiveLang();
      // Tikriname ar turi priskirtu vietu
      // https://crm.softra.lt/issues/22901
      if (this.props.currentUser.role == 'User') {
        let spaces = await getOwnerParkingSpaces(this.props.currentUser.id);
        if (!spaces.length) {
          this.navList = this.navList.filter(x => x.title != 'exchanges.History');
        }
      }

      if (this.props.isAdmin) {
        const spaces_ = await getParkingSpaces();
        if (spaces_ && spaces_.items && spaces_.items.length > 0 && spaces_.items[0].bookingLevel < 2) {
          const sp = this.state.showItem;
          sp["spaces.Title"] = true;
          this.setState({ showItem: sp })
        }

        const parking_groups = await featureEnabled(PARKING_GROUPS);
        if (parking_groups) {
          const sp_ = this.state.showItem;
          sp_["groups.groups"] = parking_groups;
          this.setState({ showItem: sp_ })
        }
      }

      const user = await getUser(this.props.currentUser.id);
      this.setState({ name: user.name });
    }
  }

  render() {
    const { t, currentUser, logout, isAdmin } = this.props;
    const { openDialog, lang, locales, name, showItem, tenantId } = this.state;
    const lng = i18n.languages[0];

    return (
      <SwipeableDrawer 
        open={this.state.left}
        onClose={() => this.toggleNav(false)}
        onOpen={() => this.toggleNav(true)}
        disableSwipeToOpen={!tenantId}
      >
        <Grid>
          <Grid
            container
            direction="row"
            px={3}
            py={0.6}
            alignItems="center"
            justifyContent='space-between'
          >
            <Grid item>
              <IconButton edge="start" onClick={this.closeSidebar} aria-label="Menu">
                <FontAwesomeIcon icon={faBars} color={Colors.iconHead} />
              </IconButton>
            </Grid>
            <Grid paddingLeft='50px' >
              <LocationSelection
                currentUser={currentUser}
                lng={this.state.formLang} />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Link className="ListItem" replace to={
          {
            pathname: '/user/' + currentUser.id,
            state: { user: currentUser }
          }}
        >
          <ListItem spacing={3} >
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText >{t('users.MyProfile')}</ListItemText>
          </ListItem >
        </Link>
        <Divider />
        <List >
          {this.navList.map((e, i) =>
            ((isAdmin && e.adminVisible) ||
              (!isAdmin && e.userVisible)
              //  || ((isAdmin && e.adminVisible) && showItem[e.title] === true)
            )
            &&
            (e.subItems ?
              (<SubSidenav item={e} key={i} showItem={showItem}></SubSidenav>)
              :
              (
                <Link key={i} to={e.path} className="ListItem" replace >
                  <ListItem>
                    <ListItemIcon><FontAwesomeIcon icon={e.icon} /></ListItemIcon>
                    <ListItemText>{t(e.title)}</ListItemText>
                  </ListItem>
                  <Divider />
                </Link>
              )))
          }
          <Divider />
        </List>
        <Grid >
          <span className="SignOut" onClick={logout} >
            <ListItem >
              <ListItemIcon><FontAwesomeIcon icon={faSignOutAlt} color='#27348A' /></ListItemIcon>
              <ListItemText>{t('auth.Logout')}</ListItemText>
            </ListItem>
          </span>
        </Grid>
      </SwipeableDrawer> 
    );
  }
}
export default withTranslation()(Sidenav)