import React, { Component } from 'react';
import './UserForm.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import {
  Container,
  AppBar,
  Fab,
  Grid,
  Toolbar,
  Box,
} from '@mui/material';
import { InputText } from '../../ui/inputs/text/Text';
import { postUser, getUser, putUser } from '../../../utils/UsersService';
import { withSnackbar, SnackbarProvider } from 'notistack';
import FormValidation, {
  updateValidationMessages,
} from '../../../utils/FormValidation';
import { setParkingOwner } from 'utils/ParkingService';
import { featureEnabled } from '../../../utils/FeatureTogglerService';
import { SHOW_IBAN, SHOW_PHONE } from '../../../constants/Features';

class UserForm extends Component {
  constructor() {
    super();
    this.state = {
      title: 'New user',
      ibanDisabled: true,
      showIban: false,
      showPhone: false,
      user: {
        id: null,
        name: '',
        email: '',
        phone: '',
        iban: '',
        role: '',
        isEmailValidated: null,
      },
    };
    updateValidationMessages();
    this.validator = new FormValidation({
      locale:
        localStorage.currentLng.toLowerCase() === 'lt-lt' ? 'lt-LT' : 'en-GB',
      className: 'input-errormsg',
    });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleChange = (event) => {
    this.setState({
      user: { ...this.state.user, [event.target.id]: event.target.value },
    });
  };
  goToSpaceDetails = (space, owner) => {
    this.props.history.replace({
      pathname: '/space/',
      state: { space, owner, createUserSucces: true },
    });
  };
  goToProfile = (user) => {
    this.props.history.replace({
      pathname: '/user/' + user.id,
      state: { user, canBack: true },
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      try {
        if (!this.state.user.id) {
          const { affectedIds } = await postUser(this.state.user);
          console.log('user update', { affectedIds });
          if (affectedIds && affectedIds.length) {
            const user = await getUser(affectedIds[0]);
            this.props.enqueueSnackbar(
              this.props.t('notification.success.CreateUser'),
              {
                variant: 'success',
              }
            );
            if (this.state.space) {
              await setParkingOwner(this.state.space, user.id);
              this.goToSpaceDetails(this.state.space, user);
            } else {
              this.goToProfile(user);
            }
          }
        } else {
          const resp = await putUser(this.state.user);
          console.log('user update', { resp });
          if (resp) {
            const user = await getUser(resp);
            console.log('user update resp', resp);
            console.log('Get user', user);
            this.props.enqueueSnackbar(
              this.props.t('notification.success.UpdateUser'),
              {
                variant: 'success',
              }
            );

            this.goToProfile(user);
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentDidMount = async () => {
    let user,
      goTo,
      space = null;
    const state = this.props.location.state;
    const isAdmin = this.props.location.state.isAdmin;
    const show_Iban = await featureEnabled(SHOW_IBAN);
    const show_Phone = await featureEnabled(SHOW_PHONE);
    this.setState({
      showIban: show_Iban,
      showPhone: show_Phone,
    });
    // const show_iban = await featureEnabled(SHOW_IBAN);
    console.log('showIban', this.state.showIban);
    console.log('showPhone', this.state.showPhone);
    if (isAdmin) {
      await this.setState({
        ibanDisabled: false,
      });
    }
    if (state) {
      user = state.user || user;
      goTo = state.goTo || goTo;
      space = state.space || space;
    }
    if (user) {
      await this.setState({
        user: { ...user },
        title: 'Edit user',
        emailDisabled: true,
      });
    } else {
      if (this.props.match.params.id === '-1') return;
      user = await getUser(this.props.match.params.id);
      await this.setState({
        user: { ...user },
        title: 'Edit user',
        emailDisabled: true,
      });
    }
    if (goTo) {
      await this.setState({ goTo });
    }
    if (space) {
      await this.setState({ space });
    }
    if (this.props.isAdmin) {
      await this.setState({
        ibanDisabled: false,
      });
    }
  };

  render() {
    const { user, title, emailDisabled, ibanDisabled, showIban, showPhone} =
      this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <Topbar
          title={t('users.' + title)}
          cbBack={this.goBack}
          location={this.props.location}
        ></Topbar>
        <form onSubmit={this.handleSubmit} noValidate>
          <SnackbarProvider>
            <Container fixed className="form-container">
              <InputText
                type="text"
                id="name"
                validator={this.validator.message(
                  t('users.Firstname Lastname'),
                  this.state.user.name,
                  'required'
                )}
                onBlur={() =>
                  this.validator.showMessageFor(t('users.Firstname Lastname'))
                }
                onChange={this.handleChange}
                value={user.name}
                label={t('users.Firstname Lastname')}
                placeholder={t('users.Firstname Lastname')}
              />
              {showPhone && (
                <InputText
                  type="tel"
                  id="phone"
                  onChange={this.handleChange}
                  validator={this.validator.message(
                    t('general.Phone'),
                    this.state.user.phone,
                    'phone'
                  )}
                  onBlur={() =>
                    this.validator.showMessageFor(t('general.Phone'))
                  }
                  value={user.phone}
                  label={t('general.Phone')}
                  placeholder={t('general.Phone')}
                />
              )}
              <InputText
                type="email"
                disabled={emailDisabled}
                id="email"
                validator={this.validator.message(
                  t('general.Email'),
                  this.state.user.email,
                  'required|email'
                )}
                onBlur={() => this.validator.showMessageFor(t('general.Email'))}
                value={user.email}
                onChange={this.handleChange}
                label={t('general.Email')}
                placeholder={t('general.Email')}
              />
              {showIban && (
                <InputText
                  type="text"
                  disabled={ibanDisabled}
                  id="iban"
                  onChange={this.handleChange}
                  validator={this.validator.message(
                    t('general.Phone'),
                    this.state.user.iban,
                    'iban'
                  )}
                  onBlur={() =>
                    this.validator.showMessageFor(t('general.Phone'))
                  }
                  value={user.iban}
                  label="IBAN"
                  placeholdes="IBAN"
                // label={t('general.iban')}
                // placeholder={t('general.iban')}
                />
              )}
            </Container>
          </SnackbarProvider>

          <AppBar
            elevation={0}
            color="inherit"
            position="fixed"
            className="bottom-bar"
          >
            <Toolbar>
              <Grid
                spacing={3}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <Fab
                    className="full-width secondary-btn"
                    variant="extended"
                    type="submit"
                    value="Submit"
                    aria-label="Save"
                  >
                    <Box fontSize={14} textAlign="center">
                      {t('general.Save')}
                    </Box>
                  </Fab>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </form>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(UserForm));
