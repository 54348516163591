import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Topbar } from 'components/ui/topbar/Topbar';
import { getOwnwerVehicles, deleteVehicle } from 'utils/VehicleService';
import VehiclesOwnerList from 'components/vehicles/owner-list/VehiclesOwnerList';
import { NotificationManager } from 'react-notifications';
import { Box } from '@mui/material';

class AccountVehicles extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            vehicles: []
        }
    }

    componentDidMount = async () => {
        const user = this.props.currentUser;
        this.setState({ user: user })

        let vehicles = await getOwnwerVehicles(user.id);
        vehicles = vehicles || [];
        this.setState({ vehicles: vehicles })
    }

    goBack = () => {
        this.props.history.goBack();
    };

    newVehicle = () => {
        this.props.history.push({
            pathname: '/vehicles/new',
            state: {
                ownerId: this.state.user.id
            },
        });
    };

    deleteVehicle = async (id) => {
        const deleteVehicleFn = async (id) => {
            try {
                const { affectedIds } = await deleteVehicle(id);
                if (affectedIds && affectedIds.length) {
                    this.setState({
                        vehicles: [
                            ...this.state.vehicles.filter((e) => !affectedIds.includes(e.id)),
                        ],
                    });
                    NotificationManager.success(this.props.t('notification.success.deleteVehicle'));

                }
            } catch (e) {
                console.error(e);
            }
        };
        deleteVehicleFn(id);
    };

    render() {
        const { vehicles } = this.state;
        const { t } = this.props;
        return (
            <div className='public-form'>
                <Topbar
                    title={t('publicApp.Vehicles')}
                    cbBack={this.goBack}
                    location={this.props.location}
                >
                </Topbar>
                <Box sx={{ top: 50, position: 'relative' }}>
                    <VehiclesOwnerList
                        onCreate={
                            vehicles.length < 1 &&
                            this.newVehicle
                        }
                        onDelete={this.deleteVehicle}
                        vehicles={vehicles}
                    />
                </Box>
            </div>
        )
    }
}

export default withSnackbar(withTranslation()(AccountVehicles));