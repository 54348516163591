import React, { Component } from 'react';
import { Box, CircularProgress, Grid, Typography, Button } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { HubConnectionBuilder } from '@microsoft/signalr'
import { getHubPath } from 'utils/ProxyPathService';
import { checkPayment, confirmPayment } from 'utils/PaymentsService';
import { getPaymentStatus } from 'utils/KevinService';
import x_circle from '../../../assets/icons/publicApp/x-circle.svg';
import check_circle from '../../../assets/icons/publicApp/check-circle.svg';

class PaymentConfirm extends Component {
    state = {
        success: false,
        loading: true,
        paymentStatus: null,
        hasConfirm: false,
        statusTitle: 'publicApp.StatusTitleLoading',
        resultTitle: 'publicApp.PaymentResultLoading',
    }
    componentDidMount = async () => {
        const url = new URL(window.location.href);
        const userId = url.searchParams.get("id");

        checkPayment(userId, false).then((paymentId) => {
            if (paymentId) {
                getPaymentStatus(paymentId).then((payment) => {
                    if (payment) {
                        confirmPayment(payment).then(() => {
                            this.setState({ loading: false })
                            const success = payment.statusGroup === 'completed';
                            if (success) {
                                this.setState({ statusTitle: 'publicApp.StatusTitleSuccess', resultTitle: 'publicApp.PaymentResultSuccess' })
                            } else {
                                this.setState({ statusTitle: 'publicApp.StatusTitleFailed', resultTitle: 'publicApp.PaymentResultFailed' })
                            }
                            this.setState({ success: success });
                        });
                    } else {
                        this.setState({ loading: false, statusTitle: 'publicApp.StatusTitleFailed', resultTitle: 'publicApp.PaymentResultFailed' })
                    }
                });
            } else {
                this.setState({ loading: false, statusTitle: 'publicApp.StatusTitleFailed', resultTitle: 'publicApp.PaymentResultFailed' })
            }
        })
    }

    goMap = () => {
        if (this.state.loading === false) {
            this.props.history.push({
                pathname: '/publicMap'
            });
        }
    }

    render() {
        const { t } = this.props;
        const { success, loading, statusTitle, resultTitle } = this.state;
        return (
            <div  className='public-form'>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '75vh' }}
                >
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading && (
                                <CircularProgress
                                    size={80}
                                    thickness={2}
                                    color='primary'
                                />
                            )}
                            {!loading && !success &&
                                (<Box >
                                    <img src={x_circle} />
                                </Box>)}
                            {!loading && success &&
                                (<Box >
                                    <img src={check_circle} />
                                </Box>)}
                        </Box>
                    </Grid>
                    <Box sx={{ textAlign: 'center', pl: 1, pr: 1, pt: 15 }}>
                        <Typography sx={{ pb: 1 }} variant='h4' gutterBottom>
                            {t(statusTitle)}
                        </Typography>
                        <Typography sx={{ pb: 1 }} variant='h4' gutterBottom>
                            {t(resultTitle, { exit_time: '15' })}
                        </Typography>
                    </Box>
                </Grid>
                {!loading && (
                    <Box className='bottom-button' sx={{ pr: 3, pl: 3, pb: 3 }}>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="button"
                            variant="contained"
                            color="secondary"
                            className='authButton'
                            onClick={() => this.goMap()}
                        >
                            {t('publicApp.Return')}
                        </Button>
                    </Box>
                )}
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(PaymentConfirm));