import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './TerritoryUserForm.scss';
import { withSnackbar, SnackbarProvider } from 'notistack';
import { Container, AppBar, Fab, Grid, Toolbar, Box, TextField, Typography } from '@mui/material';

class TerritoryUserForm extends Component {
    constructor() {
        super()
        this.state = {
            user: {
                id: '',
                name: '',
                territoryId: ''
            },
            isNew: true,
            territory: {}
        }
    }

    componentDidMount = async () => {
        if (this.props.location.state) {

            const { user, territory } = this.props.location.state;
            await this.setState({
                user: { id: '', name: '', territoryId: territory.id }, territory: territory
            });
            if (user) {
                this.setState({ isNew: false });
                await this.setState({
                    user: {
                        id: user.id, name: user.name, territoryId: user.territoryId
                    },
                    territory: territory
                });
            } else {
                this.setState({ isNew: true });
            }
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
    }

    handleChange = (event) => {
        this.setState({ user: { ...this.state.user, [event.target.id]: event.target.value } });
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/territory',
            state: { territory: this.state.territory, expanded: 'users' }
        })
    }

    delete = () => {
    }

    render(){
        const {t} = this.props
        const { user } = this.state;
        const title = this.state.isNew ?  t("territory.NewUser") :  this.state.user.name
        return (
            <div>
                <Topbar title={title} cbBack={this.goBack} />
                <div className="territoryNewContainer">
                    <form
                        onSubmit={this.handleSubmit}
                        noValidate
                    >
                        <SnackbarProvider>
                            <Container fixed className="form-container">
                                <Typography className="containerLabel">{this.state.territory.name}</Typography>
                                <TextField type="text" id="name" label={t('user.Name')} variant="outlined" value={user.name} size="small" onChange={this.handleChange} />
                            </Container>
                        </SnackbarProvider>
                        <AppBar color='inherit' position="fixed" className="bottom-bar">
                            <Toolbar>
                                <Grid
                                    spacing={3}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={4}>
                                        <Fab className="full-width primary-btn" variant="extended" onClick={this.goBack} aria-label="Cancel">
                                            <Box fontSize={14} textAlign="center">
                                                {t('general.Cancel')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Fab
                                            className="full-width secondary-btn" variant="extended"
                                            type="submit"
                                            value="Submit"
                                            aria-label="Save">
                                            <Box fontSize={14} textAlign="center">
                                                {t('general.Save')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Fab className="full-width delete-btn" variant="extended" onClick={this.delete} aria-label="Delete">
                                            <Box fontSize={14} textAlign="center">
                                                {t('general.Delete')}
                                            </Box>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </form>
                </div>
            </div>
        )
    }
}
export default withTranslation()(TerritoryUserForm)