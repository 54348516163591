import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import {
    Grid,
    Fab,
    Box,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Divider,
    Switch,
    DialogTitle,
    Typography,
    CircularProgress
} from '@mui/material';
import { sendMonthlyReport } from 'utils/ReportsService';
import '../monthly/MonthlyReport.scss';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { InputText } from '../../ui/inputs/text/Text';
import moment from "moment";
import "moment/locale/en-gb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../constants/Colors';
import { getUserSettings, updateUserSetting } from 'utils/SettingsService';
import { withSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@mui/material/styles'
import i18n from '../../../../src/utils/i18n';

const momentUtils = new MomentUtils();

class MonthlyReport extends Component {
    constructor() {
        super();
        this.state = {
            date: null,
            email: JSON.parse(sessionStorage.user).email,
            type: 'Finance',
            sendTo: JSON.parse(sessionStorage.user).id,
            showDialog: false,
            checkedB: true,
            dialogTitle: '',
            settingsLoaded: false,
            settingProperty: '',
            userSettings: null,
            currentSetting: null,
            clicked: false
        }
    }

    handleClick = async (userId, isCurrentUser) => {
        this.setState({ clicked: true });
        const month = this.state.date != null ? momentUtils.date(this.state.date).format("YYYY-MM-01").toString() : null;
        const type = isCurrentUser ? "User" : this.state.type;
        const resp = await sendMonthlyReport(this.state.sendTo, month, userId, this.state.email, type);
        this.props.goBack();
        this.setState({ clicked: false });
    }

    handleChange = (date) => {
        this.setState({ date });
    }

    handleChange_ = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    onTypeChange = (event) => {
        this.setState({ type: event.target.value });
    }

    onClickEdit = async (property) => {
        if (!this.state.settingsLoaded) {
            const items = await getUserSettings(this.state.sendTo);
            this.setState({ settingsLoaded: true, userSettings: items });
        }
        this.setState({ settingProperty: property, checkedB: true, currentSetting: null });
        if (this.state.userSettings) {
            const currentSetting = this.state.userSettings.find(x => x.property === property);
            if (currentSetting) {
                this.setState({ currentSetting: currentSetting, checkedB: JSON.parse(currentSetting.value) });
            }
        }
        switch (property) {
            case 'SendMonthlyFinanceReport':
                {
                    this.setState({ dialogTitle: 'reports.Finance' });
                    break;
                }
            case 'SendMonthlyViolationReport': {
                this.setState({ dialogTitle: 'reports.Violation' });
                break;
            }
            case 'SendMonthlyUserReport': {
                this.setState({ dialogTitle: 'reports.User' });
                break;
            }
        }
        this.setState({ showDialog: true });
    }

    handleClose = () => {
        this.setState({ showDialog: false });
    }

    handleYes = async () => {
        if (this.state.currentSetting) {
            if (this.state.currentSetting.value !== this.state.checkedB) {
                const objToUpdate = {
                    value: this.state.checkedB,
                    description: this.state.currentSetting.description,
                    category: this.state.currentSetting.category,
                    property: this.state.currentSetting.property,
                }
                try {
                    await updateUserSetting(objToUpdate, this.state.currentSetting.id, this.state.sendTo);
                    this.props.enqueueSnackbar(
                        this.props.t('notification.success.updateSetting'), {
                        variant: 'success',
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        }
        else {
            if (this.state.checkedB === false) {
                const objToSave = {
                    value: false,
                    category: 'Custom',
                    property: this.state.settingProperty,
                    description: this.state.settingProperty,
                }
                try {
                    await updateUserSetting(objToSave, null, this.state.sendTo);
                    this.props.enqueueSnackbar(
                        this.props.t('notification.success.updateSetting'), {
                        variant: 'success',
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        }
        const items = await getUserSettings(this.state.sendTo);
        this.setState({ userSettings: items });
        this.setState({ showDialog: false });
    }

    handleChangeSwitch = (event) => {
        this.setState({ checkedB: event.target.checked })
    }

    render() {
        const { t, userId, hideTitle, isCurrentUser, goBack } = this.props;
        const { email, type, date, showDialog, checkedB, dialogTitle } = this.state;
        const containerTop = hideTitle ? '0px' : '54px';
        const currentLng = localStorage.getItem('currentLng');
        const minDate = momentUtils.date('2021-05-01');
        const dateClone = momentUtils.date();
        // const maxDate = momentUtils.date().add(-1, 'M');

        const theme = createTheme({
            overrides: {
                MuiSwitch: {

                    switchBase: {
                        color: "white !important",
                    },
                    colorPrimary: {
                        "&$checked": {
                            color: "white !important"
                        }
                    },
                    track: {
                        opacity: 0.2,
                        backgroundColor: "#0CB262",
                        "$checked$checked + &": {
                            opacity: 0.7,
                            backgroundColor: "#0CB262"
                        }
                    }
                }
            }
        });

        moment.locale(currentLng);
        return (
            <div>
                {!hideTitle && (<Topbar title={t("reports.Monthly")} location={this.props.location} />)}
                <div className='monthlyReportContainer' style={{ top: containerTop }}>
                    <Grid container>
                        {!isCurrentUser && (
                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <RadioGroup
                                    value={type}
                                    onChange={this.onTypeChange}
                                >
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <FormControlLabel value="Finance" control={<Radio color="primary" />} label={t("reports.Finance")} />
                                        </Grid>
                                        <Grid item xs={1} className='report_setting_icon'>
                                            {!userId && (
                                                <IconButton
                                                    onClick={() => this.onClickEdit('SendMonthlyFinanceReport')}
                                                    edge="end"
                                                    aria-label="Delete"
                                                    className="delete-btn button-edit-margin"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCog}
                                                        color={Colors.icon}
                                                        size="xs"
                                                    />
                                                </IconButton>)}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <FormControlLabel value="Violation" control={<Radio color="primary" />} label={t("reports.Violation")} />
                                        </Grid>
                                        <Grid item xs={1} className='report_setting_icon'>
                                            {!userId && (<IconButton
                                                onClick={() => this.onClickEdit('SendMonthlyViolationReport')}
                                                edge="end"
                                                aria-label="Delete"
                                                className="delete-btn button-edit-margin"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCog}
                                                    color={Colors.icon}
                                                    size="xs"
                                                />
                                            </IconButton>)}
                                        </Grid>
                                    </Grid>
                                    {userId && (
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <FormControlLabel value="User" control={<Radio color="primary" />} label={t("reports.User")} />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                        </Grid>
                                    )}
                                </RadioGroup>
                            </Grid>
                        )}
                        {isCurrentUser && (
                            <Grid item xs={12} style={{ marginTop: '1em', marginBottom: '1em' }}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Typography className='titleInfo'>{i18n.t('reports.sendToEmail')}</Typography>
                                    </Grid>
                                    {/*<Grid item xs={1} className='report_setting_icon'>*/}
                                    {/*    <IconButton*/}
                                    {/*        onClick={() => this.onClickEdit('SendMonthlyUserReport')}*/}
                                    {/*        edge="end"*/}
                                    {/*        aria-label="Delete"*/}
                                    {/*        className="delete-btn button-edit-margin"*/}
                                    {/*    >*/}
                                    {/*        <FontAwesomeIcon*/}
                                    {/*            icon={faCog}*/}
                                    {/*            color={Colors.icon}*/}
                                    {/*            size="xs"*/}
                                    {/*        />*/}
                                    {/*    </IconButton>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} style={{ marginTop: '1em', paddingRight: '1em' }}>
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('reports.Month')}</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng} libInstance={moment}>
                                <DatePicker
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    variant="outlined"
                                    views={['year', 'month']}
                                    // disableToolbar
                                    onChange={(date) => this.handleChange(date)}
                                    minDate={minDate}
                                    //  maxDate={maxDate}
                                    value={date}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>

                            <InputText
                                type="email"
                                id="email"
                                //   validator={
                                //     this.validator.message(t('general.Email'), email, 'required|email')}
                                //   onBlur={() => this.validator.showMessageFor(t('general.Email')) }
                                value={email}
                                onChange={this.handleChange_}
                                label={t('general.Email')}
                                placeholder={t('general.Email')}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '2em' }}>
                            <Fab
                                className="full-width success-btn" variant="extended"
                                onClick={() => this.handleClick(userId, isCurrentUser)}
                                aria-label="Save"
                                disabled={this.state.clicked}
                            >
                                {this.state.clicked ?
                                    <Box position="relative" display="inline-flex">
                                        <CircularProgress size={30} color={'secondary'} />
                                    </Box> :
                                    <Box fontSize={14} textAlign="center">
                                        {t('reports.sendMail')}
                                    </Box>
                                }
                            </Fab>
                        </Grid>
                    </Grid>
                </div>
                <Dialog open={showDialog} aria-labelledby="draggable-dialog-title">
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t(dialogTitle)}
                    </DialogTitle>
                    <DialogContent>
                        <ThemeProvider theme={theme}>
                            <FormControlLabel
                                labelPlacement="start"
                                label={t('report.autoSendMothly')}
                                control={
                                    <Switch
                                        checked={checkedB}
                                        onChange={this.handleChangeSwitch}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                            />
                        </ThemeProvider>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary">
                            {t('general.Cancel')}
                        </Button>
                        <Button onClick={this.handleYes} color="primary">
                            {t('general.Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(MonthlyReport));