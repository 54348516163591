import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, CircularProgress, Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import info from '../../../assets/icons/info.svg';

class ConfirmDialog extends Component {

  render() {
    const { open = false, handleClose, handleYes, t, text, clicked = false } = this.props;


    return (
      <Dialog
        open={open}
        onClose={handleClose}
      >



        <DialogTitle id="confirmation-dialog-title">{t(text)}</DialogTitle>
        <DialogActions>
          {clicked ?
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress size={30} />
            </Grid>
            :
            <div>
              <Button onClick={handleClose} color="primary" disabled={clicked}>
                {t('general.Cancel')}
              </Button>
              <Button onClick={handleYes} color="primary" autoFocus>
                {t('general.Confirm')}
              </Button>
            </div>
          }
        </DialogActions>
      </Dialog>
    );
  }
}
export default withTranslation()(ConfirmDialog)