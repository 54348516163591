import React, { } from "react";
import clsx from "clsx";
import i18next, { emit } from 'i18next';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { IconButton, List, ListItem, ListItemAvatar, ListItemText, Grid, CircularProgress, Box, Typography, Divider } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCar } from '@fortawesome/free-solid-svg-icons';
import './MonthInformation.scss';
import "moment/locale/lt";
import "moment/locale/en-gb";

const momentUtils = new MomentUtils();

const useStyles = makeStyles({
    typography: {
        h3: {
            fontSize: '1.25rem',
            color: '#000000',
            fontWeight: 600
        }
    },

    day: {
        width: 36,
        height: 36,
        margin: "0 2px",
        color: "inherit",
        // fontWeight: "bolder",
    },
    dayBox: {
        marginTop: '1px',
        marginBottom: '1px',
        paddingBottom: '1px',
        paddingTop: '1px',
        marginRight: '1px',
        marginLeft: '1px',

        borderRadius: '10%',
        // borderTopLeftRadius: "50%",
        // borderBottomLeftRadius: "50%",
        // borderTopRightRadius: "50%",
        // borderBottomRightRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: 'rgba(0,0,0,0.38)',
    },
    used: {
        background: '#DAF7A6',
        color: '#000000',
        pointerEvents: 'none',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    notUsed: {
        background: '#EC7063',
        color: '#000000',
        pointerEvents: 'none',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    booked: {
        background: '#DAF7A6',
        color: '#1D8348',
        textDecoration: 'underline !important',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    occupied: {
        background: '#F8C471',
        color: '#000000',
        pointerEvents: 'none',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    notOccupied: {
        background: '#F8C471',
        color: '#FF0000',
        pointerEvents: 'none',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    shared: {
        background: '#F8C471',
        color: '#935116',
        textDecoration: 'underline !important',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    possible: {
        background: '#E8E28E',
        color: '#935116',
        textDecoration: 'underline !important',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    subscribed: {
        background: '#85C1E9',
        color: '#2874A6',
        textDecoration: 'underline !important',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    disabled: {
        color: 'rgba(0,0,0,0.38)',
        pointerEvents: 'none'
    },
    vacant: {
        textDecoration: 'underline !important'
    },
    parked: {
        pointerEvents: 'none',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);',
    },
    firstHighlight: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
    squere: {
        borderRadius: '10%',
        pointerEvents: 'none',
        boxShadow: '0 1px 3px hsl(0deg 0% 0% / 30%);'
    },
    pointerNone: {
        pointerEvents: 'none'
    }

});

function handleChange(date, getMonth) {

    getMonth(date);
}

function handleClick(status, item, date, navigate, id, el) {
    if (navigate) {
        const dateClone = date.format('YYYY-MM-DDT12:00:00.000').toString();
        if (item.userRole === 'user') {
            if (status === 'vacant') {
                const sharedRequests = item.periods.filter(x => x.status === "subscribed")
                    .map(x => {
                        return {
                            from: momentUtils.date(x.dateFrom).format('YYYY-MM-DDT00:00:00.000').toString(),
                            to: momentUtils.date(x.dateTo).format('YYYY-MM-DDT12:00:00.000').toString(),
                        }
                    });
                navigate("/subscriptions/new", { sharedDates: sharedRequests }, dateClone);
            }
            else if (status === 'possible') {
                navigate("/exchanges", { VacantFrom: dateClone, territoryId: item.territoryId }, dateClone);
            }
            else if (status === 'booked') {
                navigate("/bookings/edit", { bookedSpaceId: id }, dateClone);
            } else if (status === 'subscribed') {
                const sharedRequests = item.periods.filter(x => x.status === "subscribed").map(x => { return { from: x.dateFrom, to: x.dateTo, id: x.id } });
                const sharedRequest = item.periods.find(x => x.id === id);
                const item_ = {
                    id: sharedRequest.id,
                    from: momentUtils.date(sharedRequest.dateFrom).format('YYYY-MM-DDT00:00:00.000').toString(),
                    to: momentUtils.date(sharedRequest.dateTo).format('YYYY-MM-DDT12:00:00.000').toString(),
                }
                navigate("/subscriptions/edit", { sharedDates: sharedRequests, item: item_ }, dateClone)
            }
        }
        else {
            if (status === 'booked') {
                const parkingSpaceId = el ? el.ownerSpaceId : item.ownerSpaces[0].ownerSpaceId;
                const spaceNames = item.ownerSpaces.map(x => { return { id: x.ownerSpaceId, name: x.name } });
                navigate('/exchanges/new', { parkingSpaceId: parkingSpaceId, shareFrom: dateClone, spaceNames: spaceNames }, dateClone);
            } else if (status === 'shared') {
                navigate('/exchanges/edit', { sharedSpaceId: id }, dateClone);
            }
        }
    }
}

function renderDayGroups(date, dayInCurrentMonth, classes, item, navigate, clicked, periods, autoMatchFeatureEnabled, inlcudeWeekendsInSharing, space) {
    const dateClone = date;
    let status = '';
    let id = '';
    // let isFirstDay = false;
    // let isLastDay = false;
    // if (item.periods) {
    //     item.periods.forEach(x => {
    //         if (status == '' && checkDates(dateClone, x)) {
    //             status = x.status;
    //             // isFirstDay = moment.date(x.dateFrom).isSame(dateClone, 'day');
    //             // isLastDay = moment.date(x.dateTo).isSame(dateClone, 'day');
    //         }
    //     });
    // }
    // if(status == ''){
    //     if(moment.date().isAfter(dateClone, 'day') ){
    //         status = 'disabled';
    //     }
    // }
    const clickedDate = clicked && clicked.clicked !== null && momentUtils.date(clicked.clicked).isSame(dateClone, 'day') ? true : false;
    if (clickedDate === false) {
        if (status === '') {
            if (!inlcudeWeekendsInSharing) {
                if (dateClone.day() === 0 || dateClone.day() === 6) {
                    status = 'disabled';
                }
            }
        }
        if (status === '') {
            if (periods) {
                periods.forEach(x => {
                    if (status === '' && checkDates(dateClone, x) && x.status === 'freeEntrance') {
                        status = 'disabled';
                    }
                });
            }
        }
        if (status === '') {
            if (momentUtils.date().isAfter(dateClone, 'day')) {

                if (item.userRole === 'user') {
                    if (periods) {
                        periods.forEach(x => {
                            if (status === '' && checkDates(dateClone, x) && x.status === 'used') {
                                status = x.status;
                            }
                        });
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'booked') {
                                    status = 'notUsed';
                                }
                            });
                        }
                    }
                    if (status === '') {
                        status = 'disabled';
                    }
                } else {
                    const ownerFrom = space ? space.ownerFrom : item.ownerSpaces[0].ownerFrom;
                    if (ownerFrom) {
                        if (momentUtils.date(ownerFrom).isAfter(dateClone, 'day')) {
                            status = 'disabled';
                        }
                    }
                    if (item.periods) {
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'booked') {
                                    status = 'occupied';
                                }
                            });
                        }
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'shared') {
                                    status = 'notOccupied';
                                }
                            });
                        }
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'used') {
                                    status = x.status;
                                }
                            });
                        }

                    }
                    if (status === '') {
                        status = 'notUsed';
                    }
                }

            } else {
                if (item.userRole === 'user') {
                    if (periods) {
                        periods.forEach(x => {
                            if (status === '' && checkDates(dateClone, x) && x.status === 'parked') {
                                status = x.status;
                            }
                        });
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'used') {
                                    status = x.status;
                                }
                            });
                            if (status === '') {
                                periods.forEach(x => {
                                    if (status === '' && checkDates(dateClone, x) && x.status === 'notUsed') {
                                        status = x.status;
                                    }
                                });
                                if (status === '') {
                                    periods.forEach(x => {
                                        if (status === '' && checkDates(dateClone, x) && x.status === 'booked') {
                                            status = x.status;
                                            id = x.id;
                                        }
                                    });
                                    if (status === '') {
                                        periods.forEach(x => {
                                            if (status === '' && checkDates(dateClone, x) && x.status === 'shared') {
                                                status = 'possible';
                                                id = x.id;
                                            }
                                        });
                                        if (status === '') {
                                            periods.forEach(x => {
                                                if (status === '' && checkDates(dateClone, x) && x.status === 'subscribed') {
                                                    status = 'subscribed';
                                                    id = x.id;
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (periods) {
                        periods.forEach(x => {
                            if (status === '' && checkDates(dateClone, x) && x.status === 'parked') {
                                status = x.status;
                            }
                        });
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'used') {
                                    status = x.status;
                                }
                            });
                        }
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'booked') {
                                    status = 'occupied';
                                }
                            });
                        }
                        if (status === '') {
                            periods.forEach(x => {
                                if (status === '' && checkDates(dateClone, x) && x.status === 'shared') {
                                    status = 'shared';
                                    id = x.id;
                                }
                            });
                        }
                    }
                    if (status === '') {
                        status = 'booked';
                    }
                }
                if (status === '') {
                    if (autoMatchFeatureEnabled) {
                        status = 'vacant';
                    } else {
                        status = 'pointerNone';
                    }
                }
            }

        }
    }

    const spanUnderline = clsx({ [classes.vacant]: status === 'booked' || status === 'shared' || status === 'possible' || status === 'subscribed' || status === 'vacant' });

    const wrapperClassName = clsx(classes.dayBox, {
        [classes.used]: status === 'used',
        [classes.notUsed]: status === 'notUsed',
        [classes.booked]: status === 'booked',
        [classes.occupied]: status === 'occupied',
        [classes.notOccupied]: status === 'notOccupied',
        [classes.shared]: status === 'shared',
        [classes.possible]: status === 'possible',
        [classes.subscribed]: status === 'subscribed',
        [classes.disabled]: status === 'disabled',
        [classes.vacant]: status === 'vacant',
        [classes.parked]: status === 'parked',
        [classes.pointerNone]: navigate == null || status === 'pointerNone'

        // [classes.firstHighlight]: isFirstDay,
        // [classes.endHighlight]: isLastDay,
    });

    const parked = status === 'parked';

    const dayClassName = clsx(classes.day
        , {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth
        }
    );

    const clickedClassName = clsx(classes.dayBox, {
        [classes.squere]: true
    });

    return (
        clickedDate ?
            (<div className={clickedClassName}>
                <IconButton className={dayClassName}>
                    <Box position="relative" display="inline-flex">
                        <CircularProgress size={25} />
                    </Box>
                </IconButton>
            </div>)
            :
            (parked ?
                <div className={wrapperClassName}>
                    <IconButton className={dayClassName} >
                        <FontAwesomeIcon icon={faCar}
                            style={{ width: '30px', height: '30px', color: '#2874A6' }} />
                    </IconButton>
                </div>
                : <div className={wrapperClassName}>
                    <IconButton className={dayClassName} onClick={() => handleClick(status, item, date, navigate, id, space)}>
                        <span className={spanUnderline}>
                            {dateClone.date()}
                        </span>
                    </IconButton>
                </div>)

    );
}

function checkDates(date, dates) {
    const startDate = momentUtils.date(dates.dateFrom);
    const endDate = momentUtils.date(dates.dateTo);
    if (date.isSameOrBefore(endDate) && date.isSameOrAfter(startDate)) {
        return true;
    } else {
        return false;
    }
}

function changeI(rise, changeIndex) {
    changeIndex(rise);
}

export default function MonthInformation(props) {
    const classes = useStyles();
    const minDate = momentUtils.date('2021-05-01');
    const { item, navigate, clicked, currentLng, getMonth, defaultDate, index, changeIndex, periods, autoMatchFeatureEnabled, inlcudeWeekendsInSharing } = props;
    // console.log(item);
    const usedClass = clsx({ [classes.day]: true, [classes.used]: true, [classes.squere]: true });
    const notUsedClass = clsx({ [classes.day]: true, [classes.notUsed]: true, [classes.squere]: true });
    const bookedClass = clsx({ [classes.day]: true, [classes.booked]: true, [classes.squere]: true });
    const occupiedClass = clsx({ [classes.day]: true, [classes.occupied]: true, [classes.squere]: true });
    const notOccupiedClass = clsx({ [classes.day]: true, [classes.notOccupied]: true, [classes.squere]: true });
    const sharedClass = clsx({ [classes.day]: true, [classes.shared]: true, [classes.squere]: true });
    const possibleClass = clsx({ [classes.day]: true, [classes.possible]: true, [classes.squere]: true });
    const subscribedClass = clsx({ [classes.day]: true, [classes.subscribed]: true, [classes.squere]: true });
    const vacantClass = clsx({ [classes.day]: true, [classes.vacant]: true, [classes.squere]: true });
    const value = defaultDate ? defaultDate : null;
    moment.locale(currentLng);
    return (
        <div className="monthInformation_container">
            <Grid container justifyContent="center" className="territoryTitle_container">

                <Grid item style={{ minWidth: '360px' }}>
                    {item.ownerSpaces && item.ownerSpaces.length > 1 ?
                        <div>
                            <Grid container>
                                <Grid item xs={3} style={{ textAlign: 'center' }}>
                                    <IconButton onClick={() => changeI(false, changeIndex)} style={{ minWidth: '40px' }} disabled={index == 0}>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className="territoryTitle_label">
                                        {item.ownerSpaces[index].name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'center' }}>
                                    <IconButton onClick={() => changeI(true, changeIndex)} style={{ minWidth: '40px' }} disabled={index == item.ownerSpaces.length - 1}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </IconButton>
                                </Grid>

                            </Grid>
                            <Divider variant={'middle'} />
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng} libInstance={moment}>
                                <DatePicker
                                    disableUnderline={true}
                                    variant="static"
                                    renderDay={(date, dayInCurrentMonth) => renderDayGroups(date, dayInCurrentMonth, classes, item, navigate, clicked, periods, autoMatchFeatureEnabled, inlcudeWeekendsInSharing, item.ownerSpaces[index])}
                                    disableToolbar
                                    onChange={(date) => handleChange(date, getMonth)}
                                    minDate={minDate}
                                    value={value}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        :
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng} libInstance={moment}>
                            <DatePicker
                                disableUnderline={true}
                                variant="static"
                                renderDay={(date, dayInCurrentMonth) => renderDayGroups(date, dayInCurrentMonth, classes, item, navigate, clicked, periods, autoMatchFeatureEnabled, inlcudeWeekendsInSharing)}
                                disableToolbar
                                onChange={(date) => handleChange(date, getMonth)}
                                minDate={minDate}
                                value={value}
                            />
                        </MuiPickersUtilsProvider>
                    }
                </Grid>

                <Grid item >

                    <List className='legendList'>
                        <ListItem className="legendListItem">
                            <ListItemAvatar>
                                <IconButton className={usedClass}>0</IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={i18next.t('dashboard.Used')}></ListItemText>
                        </ListItem>
                        <ListItem className="legendListItem">
                            <ListItemAvatar>

                                <IconButton className={notUsedClass}>0</IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={i18next.t('dashboard.NotUsed')}></ListItemText>
                        </ListItem>

                        <ListItem className="legendListItem">
                            <ListItemAvatar>

                                <IconButton className={bookedClass}>0</IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={i18next.t('dashboard.Booked')}></ListItemText>
                        </ListItem>

                        {item.userRole === 'owner' ?
                            <ListItem className="legendListItem">
                                <ListItemAvatar>

                                    <IconButton className={occupiedClass}>0</IconButton>
                                </ListItemAvatar>
                                <ListItemText primary={i18next.t('dashboard.Occupied')}></ListItemText>
                            </ListItem> : null}

                        {item.userRole === 'owner' ?
                            <ListItem className="legendListItem">
                                <ListItemAvatar>

                                    <IconButton className={notOccupiedClass}>0</IconButton>
                                </ListItemAvatar>
                                <ListItemText primary={i18next.t('dashboard.NotOccupied')}></ListItemText>
                            </ListItem> : null}

                        {item.userRole === 'owner' ?
                            <ListItem className="legendListItem">
                                <ListItemAvatar>
                                    <IconButton className={sharedClass}>0</IconButton>
                                </ListItemAvatar>
                                <ListItemText primary={i18next.t('dashboard.Shared')}></ListItemText>
                            </ListItem>
                            : null}
                        {item.userRole === 'user' ?
                            <ListItem className="legendListItem">
                                <ListItemAvatar>

                                    <IconButton className={possibleClass}>0</IconButton>
                                </ListItemAvatar>
                                <ListItemText primary={i18next.t('dashboard.Shared')}></ListItemText>
                            </ListItem>
                            : null}
                        {item.userRole === 'user' && autoMatchFeatureEnabled ?
                            <ListItem className="legendListItem">
                                <ListItemAvatar>

                                    <IconButton className={subscribedClass}>0</IconButton>
                                </ListItemAvatar>
                                <ListItemText primary={i18next.t('dashboard.Subscribed')}></ListItemText>
                            </ListItem>
                            : null}
                        <ListItem className="legendListItem">
                            <ListItemAvatar>

                                <IconButton className={vacantClass}>0</IconButton>
                            </ListItemAvatar>
                            <ListItemText primary={i18next.t('dashboard.Vacant')}></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </div>
    )
}