import React from 'react'
import { getCurrentUser } from '../utils/UsersService';
import { login, logout, isValidToken, authenticate, invalidateUserCache, getIsActive, getAccessToken } from '../utils/AuthService';
import { getSettings, requestNotificationPermissionAndUpdate, unsubscribePush, getCurrentLng } from '../utils/SettingsService';
import { resolveTimeSpan } from 'utils/DateTimeResolver';
import { getConsent, setConsent } from '../utils/ConsentService';
import { clearNativeTermsAndConditions } from '../utils/bridge';
import firebase from 'firebase';
import i18n from 'utils/i18n';

const AuthContext = React.createContext()

class AuthProvider extends React.Component {
  state = {
    isAuth: false,
    isAdmin: false,
    currentUser: null,
    haveConsent: false,
    isActive: false
  }

  toggleAdmin = async (history) => {

    await this.setState({ isAdmin: !this.state.isAdmin })
    this.login(history, true)
  }
  login = async (history, email, token) => {
    try {
      const t = token;
      let logined = false;
      let isNew = false

      logined = await isValidToken();
      if (email && !logined) {
        try {
          const demotoken = await authenticate(email)
          if (email === 'rwr00370@gmail.com' && demotoken) {
            await login(encodeURIComponent(demotoken));
            await this.fetchInfo(true)
            setTimeout(() => { history.push('/dashboard') }, 2000)
          }

          return true;
        } catch {
          return false;
        }

      }

      // when user logs in with external idp he does not follow 
      // existing login procedure, thus user cache requires update
      if (invalidateUserCache()) {
        isNew = true
      }

      if (t) {
        logined = await login(token)
        isNew = true;
      }
      if (logined) {
        await this.fetchInfo(isNew)
        if (!this.state.isActive) {
          return false;
        }
        let currentLang = localStorage.getItem('currentLng');
        console.log('currentLng', currentLang)

        let tenantId = localStorage.getItem('tenantId');
        console.log('tenantId', tenantId)

        if (currentLang && tenantId) {
          tenantId += '/'
          i18n.init({
            backend: {
              loadPath: '/api/' + tenantId + 'v1/Translations?Locale={{lng}}',
              addPath: '/api/' + tenantId + 'v1/Translations?Locale={{lng}}',
            }
          });
          await i18n.reloadResources(currentLang);
        }

        if (this.state.haveConsent) {
          localStorage.currentPath && localStorage.currentPath != '/consent' ? history.push(localStorage.currentPath) : tenantId != null ? history.push('/dashboard') : history.push('/publicMap');
        } else {
          history.push('/consent');
        }
        delete localStorage.currentPath
        return true;
      } else {
        return false;
      }
    }
    catch (error) {
      console.log(error);
    }
    // pasikeist pagal normalu api 
  }

  //Perkelti logout
  logout = async () => {
    // cleans up subscriptions from from BE
    try {
      await unsubscribePush();
      await firebase.auth().signOut();
      // await firebase.auth().tenantId = null;
      await clearNativeTermsAndConditions();
      await localStorage.clear();
      await sessionStorage.clear();

      //   signOutFirebase();
    } catch (error) {
      console.error(error);
    }

    // dasideti req
    logout()
    this.setState({ isAuth: false })

    await firebase.auth().signOut();
    // await firebase.auth().tenantId = null;
    await clearNativeTermsAndConditions();
    await localStorage.clear();
    await sessionStorage.clear();
    console.log('try logout');
  }
  fetchSettings = async () => {
    let settings = {};
    const data = await getSettings()
    data.items.forEach(e => settings[e.category] = { ...settings[e.category], [e.property]: e })
    await this.setState({ settings })
    this.resolveBookingSettings()
  }
  resolveBookingSettings = async () => {
    const bookingPeriodAlignment = await resolveTimeSpan(this.state.settings.Global.BookingPeriodAlignment.value)
    const bookingPeriodUnit = await resolveTimeSpan(this.state.settings.Global.BookingPeriodUnit.value)
    await this.setState(prevState => ({
      settings: {
        ...prevState.settings, booking: {
          bookingPeriodUnit,
          bookingPeriodAlignment
        }
      }
    }))
  }
  fetchInfo = async (isNew) => {
    await this.fetchIsActive();
    await this.fetchSettings();
    await this.fetchHaveConsent();
    await this.setCurrentLng();
    return await this.fetchCurrentUser(isNew)

  }
  setCurrentLng = async () => {
    let tenantId = localStorage.getItem('tenantId');
    if (!tenantId) {
      try {
        const localeSetting = await getCurrentLng();
        if (localeSetting) {
          localStorage.setItem('currentLng', localeSetting);
          await i18n.changeLanguage(localeSetting);
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  fetchIsActive = async () => {
    const isActive = await getIsActive();
    if (isActive) {
      this.setState({ isActive: true })
    } else {
      this.setState({ isActive: false })
      await this.logout();
    }
  }
  fetchCurrentUser = async (isNew) => {
    const currentUser = await getCurrentUser(isNew)
    if (currentUser) {
      await this.setState({ currentUser, isAuth: true, isAdmin: currentUser.role === "Admin" })
      this.checkPushSubscription(currentUser)
    } else {
      this.setState({ isAuth: false })
    }
  }
  fetchHaveConsent = async () => {
    const haveConsent = await getConsent();
    if (haveConsent && haveConsent == true) {
      this.setState({ haveConsent: true });
    } else {
      this.setState({ haveConsent: false });
    }
  }
  setConsent = async (consented) => {
    if (consented === false) {
      this.setState({ haveConsent: consented });
      return
    }

    const data = await setConsent();
    if (data) {
      await this.setState({ haveConsent: true });
    }
  }
  checkPushSubscription = async (user) => {
    try {
      // if global push notification enabled
      // or user push notification enabled subscribe
      let notificationSetting = undefined;

      // check if user enabled push notifications in custom settings
      let notificationSettingKeys = undefined;
      let customSettings = this.state.settings?.Custom;
      if (customSettings) {
        notificationSettingKeys = Object.keys(customSettings).filter((item) =>
          item.startsWith('UserNotificationConfig')
        );
        notificationSettingKeys.some((key) => {
          notificationSetting = JSON.parse(
            this.state.settings.Custom[key].value
          );
          return notificationSetting.NotifyPush;
        });
      }
      if (!notificationSetting && !notificationSetting?.NotifyPush) {
        // check if default settings enables push notifications
        let userSettings = this.state.settings.User;
        notificationSettingKeys = Object.keys(userSettings).filter((item) =>
          item.startsWith('UserNotificationConfig')
        );
        notificationSettingKeys.some((key) => {
          notificationSetting = JSON.parse(this.state.settings.User[key].value);
          return notificationSetting.NotifyPush;
        });
      }

      if (!notificationSetting) return;
      await requestNotificationPermissionAndUpdate(
        user,
        null,
        notificationSetting
      );
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          currentUser: this.state.currentUser,
          isAuth: this.state.isAuth,
          login: this.login,
          logout: this.logout,
          settings: this.state.settings,
          isAdmin: this.state.isAdmin,
          toggleAdmin: this.toggleAdmin,
          haveConsent: this.state.haveConsent,
          setConsent: this.setConsent
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }