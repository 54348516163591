import React from 'react';
import './UserListItem.scss'

import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Typography, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../avatar/UserAvatar';
import DeleteActionButton from "../../../uiactions/actionbuttons/DeleteActionButton";


export default function UserListItem(props) {
  const { t} = useTranslation();

  return (
  <React.Fragment>
    <ListItem
      className={props.shadow ? "item-shadow":  "item"}
      button
      onClick={props.onMainClick && (() => props.onMainClick({...props.user}))}
    >
      {props.user.name &&
      <ListItemAvatar>
        {props.user && <UserAvatar name={props.user.name} />}
      </ListItemAvatar>
}
      <ListItemText primary={props.user.name} />
      <ListItemSecondaryAction>
        {props.onSelect &&
              <IconButton className="delete-btn" aria-label="Select" onClick={() => props.onSelect(props.user)}>
                <FontAwesomeIcon icon={faCheck} size="xs" />
              </IconButton>
          }
          {props.onChange &&
            <Button className="changeBtn" aria-label="Change" onClick={() => props.onChange(props.user)}>
              <Typography className="changeBtn-text">{t("general.Change")}</Typography>
            </Button>
          }
          {props.onDelete &&
          <DeleteActionButton
              openit = {false}
              onConfirm = {() => props.onDelete(props.user.id)}
          />
          }
      </ListItemSecondaryAction>
    </ListItem>
  </React.Fragment>
)}


// export default UserListItem;
