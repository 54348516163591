import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, IconButton, Container, Grid, Typography, Button } from '@mui/material';
import './PublicMap.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { getUser } from 'utils/UsersService';
import { PublicFooter } from 'components/public/PublicFooter';
import icon_person from '../../../assets/icons/publicApp/icon_person.png';
import icon_profile from '../../../assets/icons/publicApp/icon_profile.svg';
import icon_lang from '../../../assets/icons/publicApp/icon_lang.svg';
import icon_logout from '../../../assets/icons/publicApp/icon_logout.svg';
import icon_car from '../../../assets/icons/publicApp/icon_car.svg';

class PublicSettings extends Component {
    state = {
        user: {},
        showLogout: false
    }

    componentDidMount = async () => {
        const userId = this.props.currentUser.id;
        getUser(userId).then((user) => {
            if(user){
                 this.setState({ user: user })
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    };

    goAccountSettings = (pathname) => {
        this.props.history.push({
            pathname: pathname
        });
    }

    showLogoutConfirm = () => {
        this.setState({ showLogout: true })
    }

    cancelLogout = () => {
        this.setState({ showLogout: false })
    }

    goPublic= (pathname) => {
        this.props.history.push({
            pathname: pathname,
        });
    }

    render() {
        const { t, logout } = this.props;
        const { user, showLogout } = this.state;
        return (
            <div  className='public-form'>
                <div className={showLogout ? 'blur-cont' : ''} >
                    <Container className='map-header-blue'>
                        <Grid container sx={{ mb: 2, pt: 1 }}>
                            <Grid item xs={1}>
                                <IconButton sx={{ mt: 1, color: 'white' }} onClick={this.goBack} disabled={showLogout}>
                                    <FontAwesomeIcon icon={faChevronLeft} size="1x" sx={{ fontWeight: 400 }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="h3" gutterBottom sx={{ mt: 2, ml: 1, fontWeight: 400, color: 'white' }}>
                                    {t('publicApp.YourProfile')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pb: 2 }}>
                           
                            <Grid item xs={5}>
                                <Box sx={{ textAlign: 'center', pt: .5 }}>
                                    <img src={icon_person} style={{width: 65}} />
                                </Box>
                            </Grid>
                           
                            <Grid item xs={7} >
                                <Typography gutterBottom variant="h3" sx={{ pl: 1, pt: 1, mb: 0, fontWeight: 400, color: 'white' }}>
                                    {user.name}
                                </Typography>
                                <Typography gutterBottom sx={{ pl: 1, fontWeight: 400, color: 'white' }}>
                                    {user.email}
                                </Typography>
                                <Typography gutterBottom sx={{ pl: 1, fontWeight: 400, color: 'white' }}>
                                    {user.phone}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box sx={{ mt: 3}}>
                        <Grid container sx={{ m: 2 }}>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    {/* <FontAwesomeIcon icon={faAddressCard} size="2x" color="rgba(35, 49, 68, 0.25)" /> */}
                                    <img src={icon_profile} style={{width: 30 }}  onClick={() => this.goAccountSettings('/accountDetails')}/>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500 }} onClick={() => this.goAccountSettings('/accountDetails')}>
                                    {t('publicApp.EditDetails')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <IconButton disabled={showLogout} onClick={() => this.goAccountSettings('/accountDetails')}>
                                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Grid container sx={{ m: 2 }}>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <img src={icon_car} style={{width: 40 }}  onClick={() => this.goAccountSettings('/accountVehicles')}/>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500 }}  onClick={() => this.goAccountSettings('/accountVehicles')}>
                                    {t('publicApp.Vehicles')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <IconButton disabled={showLogout} onClick={() => this.goAccountSettings('/accountVehicles')}>
                                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid> */}
                        <Grid container sx={{ m: 2 }}>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    {/* <FontAwesomeIcon icon={faLanguage} size="2x" color="rgba(35, 49, 68, 0.25)" /> */}
                                    <img src={icon_lang} style={{width: 30 }}  onClick={() => this.goAccountSettings('/accountLanguages')}/>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500 }} onClick={() => this.goAccountSettings('/accountLanguages')}>
                                    {t('publicApp.Language')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <IconButton disabled={showLogout} onClick={() => this.goAccountSettings('/accountLanguages')}>
                                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {showLogout && (<Box className='grey-box'></Box>)}
                    </Box>
                    <Box className='bottom-button'>
                        <Grid container sx={{ m: 2 }} >
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <IconButton onClick={this.showLogoutConfirm}>
                                        {/* <LogoutIcon sx={{ color: "rgba(35, 49, 68, 0.25)", fontSize: 40 }} /> */}
                                        <img src={icon_logout} style={{width: 40 }}/>
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 2.5, fontWeight: 500 }} onClick={this.showLogoutConfirm}>
                                    {t('publicApp.Logout')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                {showLogout && (<Container className='logout-footer-white' >
                    <Box sx={{ textAlign: 'center', mb: 5 }}>
                        <Typography gutterBottom variant="h1" sx={{ pl: 1, pt: 2, fontWeight: 500 }} color='red'>
                            {t('publicApp.Logout')}
                        </Typography>
                        <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 2, fontWeight: 500 }}>
                            {t('publicApp.AreSureLogout')}
                        </Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ mb: 2 }} >
                        <Grid item xs={6}>
                            <Box sx={{}}>
                                <Button
                                    disableElevation
                                    type="button"
                                    variant="contained"
                                    className='cancel-logout'
                                    onClick={() => this.cancelLogout()}
                                >
                                    {t('publicApp.CancelLogout')}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{}}>
                                <Button
                                    disableElevation
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className='confirm-logout'
                                    onClick={logout}
                                >
                                    {t('publicApp.ConfirmLogout')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>)}
                
            </div>
        )
    }
}
export default withSnackbar(withTranslation()(PublicSettings));