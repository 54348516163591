import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './ActionReport.scss';
import { Grid, InputLabel, TextField, Box, CircularProgress, Fab } from '@mui/material';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from '@mui/material';
import { getActionReportSpaces, getActionReportUsers, sendActionReport } from '../../../utils/ReportsService';
import { InputText } from '../../ui/inputs/text/Text';
import { featureEnabled } from '../../../utils/FeatureTogglerService';
import { AUTO_MATCH } from '../../../constants/Features';


const momentUtils = new MomentUtils();

class ActionReport extends Component {
    constructor() {
        super();

        // todo add to settings then turn off ( actionReport.request)
        this.state = {
            startDate: momentUtils.date(new Date).add(-1, 'M'),
            endDate: momentUtils.date(new Date),
            actions: [],
            types: [
                { title: 'actionReport.created', id: 1 },
                { title: 'actionReport.updated', id: 2 },
                { title: 'actionReport.deleted', id: 3 }
            ],
            users: null,
            spaces: null,
            email: JSON.parse(sessionStorage.user).email,
            action: null,
            type: null,
            user: null,
            space: null,
            clicked: false
        }
    }

    componentDidMount = () => {
        this.fetchUsers();
        this.fetchSpaces();
        this.setActions();
    }

    setActions = async () => {
        const autoMatch = await featureEnabled(AUTO_MATCH);
        console.log('automath', autoMatch);
        if (autoMatch) {
            await this.setState({
                actions: [
                    { title: 'actionReport.shared', id: 1 },
                    { title: 'actionReport.booked', id: 2 },
                    { title: 'actionReport.request', id: 3 }
                ]
            });

        }
        else {
            await this.setState({
                actions: [
                    { title: 'actionReport.shared', id: 1 },
                    { title: 'actionReport.booked', id: 2 }
                ]
            });
        }
    }

    fetchUsers = async () => {
        const data = await getActionReportUsers();
        if (data) {
            this.setState({ users: data });
        }
    }

    fetchSpaces = async () => {
        const data = await getActionReportSpaces();
        if (data) {
            this.setState({ spaces: data });
        }
    }

    handleChangeStart = (startDate) => {
        this.setState({ startDate });
        if (startDate.isAfter(this.state.endDate)) {
            this.setState({ endDate: startDate });
        }
    }

    handleChangeEnd = (endDate) => {
        this.setState({ endDate });
        if (endDate.isBefore(this.state.startDate)) {
            this.setState({ startDate: endDate });
        }
    }

    handleClick = async () => {
        this.setState({ clicked: true });
        const body = {
            startDate: this.state.startDate.format('YYYY-MM-DD'),
            endDate: this.state.endDate.format('YYYY-MM-DD'),
            action: this.state.action ? this.state.action.id : null,
            type: this.state.type ? this.state.type.id : null,
            user: this.state.user ? this.state.user.id : null,
            space: this.state.space ? this.state.space.id : null,
            email: this.state.email
        }
        await sendActionReport(body);
        this.setState({ clicked: false });
    }

    handleChange_ = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    setAction = (action) => {
        this.setState({ action });
    }

    setType = (type) => {
        this.setState({ type });
    }

    setUser = (user) => {
        this.setState({ user });
    }

    setSpace = (space) => {
        this.setState({ space });
    }

    render() {
        const { t } = this.props;
        const currentLng = localStorage.getItem('currentLng');
        const { startDate, endDate, actions, types, users, spaces, email, action, type, user, space } = this.state;
        return (
            <div>
                <Topbar title={t("reports.Action")} location={this.props.location} />
                <div className="actionReportContainer">
                    <Grid container style={{ marginTop: '1em' }}>
                        <Grid item xs={6} style={{ paddingRight: '10px' }}>
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('exchanges.Begining')}</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng} libInstance={moment}>
                                <DatePicker
                                    format='L'
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    variant="outlined"
                                    onChange={(date) => this.handleChangeStart(date)}
                                    value={startDate}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('exchanges.End')}</InputLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng} libInstance={moment}>
                                <DatePicker
                                    format='L'
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    variant="outlined"
                                    onChange={(date) => this.handleChangeEnd(date)}
                                    value={endDate}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} style={{ paddingRight: '10px' }} >
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('actionReport.action')}</InputLabel>
                            <Autocomplete
                                options={actions}
                                getOptionLabel={(option) => t(option.title)}
                                renderInput={(params) => <TextField {...params} />}
                                value={action}
                                onChange={(event, newValue) => {
                                    this.setAction(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('actionReport.type')}</InputLabel>
                            <Autocomplete
                                options={types}
                                getOptionLabel={(option) => t(option.title)}
                                renderInput={(params) => <TextField {...params} />}
                                value={type}
                                onChange={(event, newValue) => {
                                    this.setType(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('actionReport.users')}</InputLabel>
                            {users ? <Autocomplete
                                options={users}
                                getOptionLabel={(option) => (option.deleted ? '*' : '') + option.title}
                                renderInput={(params) => <TextField {...params} />}
                                value={user}
                                onChange={(event, newValue) => {
                                    this.setUser(newValue);
                                }}
                            /> : null}
                        </Grid>
                        <Grid item xs={12} >
                            <InputLabel
                                shrink={true}
                                className='input-label'>{t('actionReport.spaces')}</InputLabel>
                            {users && spaces ? <Autocomplete
                                options={spaces}
                                getOptionLabel={(option) => (option.deleted ? '*' : '') + users.find(x => x.id == option.ownerId)?.title + option.title}
                                renderInput={(params) => <TextField {...params} />}
                                value={space}
                                onChange={(event, newValue) => {
                                    this.setSpace(newValue);
                                }}
                            /> : null}
                        </Grid>
                        <Grid item xs={12}>
                            <InputText style={{ marginTop: 'Opx !important' }}
                                type="email"
                                id="email"
                                //   validator={
                                //     this.validator.message(t('general.Email'), email, 'required|email')}
                                //   onBlur={() => this.validator.showMessageFor(t('general.Email')) }
                                value={email}
                                onChange={this.handleChange_}
                                label={t('general.Email')}
                                placeholder={t('general.Email')}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '2em' }}>
                            <Fab
                                className="full-width success-btn" variant="extended"
                                onClick={() => this.handleClick()}
                                aria-label="Save"
                                disabled={this.state.clicked}
                            >
                                {this.state.clicked ?
                                    <Box position="relative" display="inline-flex">
                                        <CircularProgress size={30} color={'secondary'} />
                                    </Box> :
                                    <Box fontSize={14} textAlign="center">
                                        {t('reports.sendMail')}
                                    </Box>
                                }
                            </Fab>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ActionReport)