import React, { Component } from 'react';
import './Login.scss';
import { withTranslation } from 'react-i18next';
//import background from '../../../assets/images/graphic_cars.png';
import FormValidation from '../../../../utils/FormValidation';
import { isValidToken, getLoginUiConfig } from '../../../../utils/AuthService';
import {
  Redirect,
} from "react-router-dom";
import Login3 from './Login3';
import PublicLogin from  '../public-auth/PublicLogin'
//import { Grid, Container, Button, CircularProgress, Box, Typography } from '@mui/material';
//import { InputText } from 'components/ui/inputs/text/Text';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCar } from '@fortawesome/free-solid-svg-icons';
//import FirebaseSignIn from '../firebase/FirebaseSignIn';

class LoginMain extends Component {
  constructor() {
    super()
    this.state = {
      redirectToReferrer: false,
      email: '',
      magicLinksSent: false,
      loading: false,
      isValidToken: true,
      loginConfig: { enableSocialLogin: false, enableSignup: false },
      doneLoadingConfig: false
    };
    this.validator = new FormValidation({
      locale: 'lt-LT',
      className: 'input-errormsg'
    });

  }

  login = async (token, e) => {
    if (e) e.preventDefault();

    await this.setState({ loading: true })
    try {
      if (token) {
        await this.props.login(this.props.history, null, token);
        this.setState({ loading: false });
      } else {
        if (this.validator.allValid() && this.state.email) {
          const resp = await this.props.login(this.props.history, this.state.email)
          if (resp) {
            await this.setState({ magicLinksSent: true, loading: false })
          } else {
            await this.setState({ magicLinksSent: false, loading: false })
          }
        } else {
          await this.props.login(this.props.history, this.state.email)
          await this.setState({ loading: false })
          if (e) {
            this.validator.showMessages();
            this.forceUpdate()
          }
        }
      }
    } catch (e) {
      // TODO: requires single place logger for non production environment
      // to display all errors
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }

      this.setState({ loading: false });
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }
  checkToken() {
    const sparams = window.location.search;
    if (sparams.startsWith("?token=")) {
      this.login(sparams.slice(7))
    } else {
      this.login()
    }
  }

  componentWillMount() {
    isValidToken().then((isValidToken) => {
      this.setState({ isValidToken });
    })

    getLoginUiConfig()
      .then((config) => this.setState({ loginConfig: config }))
      .then((ok) => this.setState({ doneLoadingConfig: true }));
  }

  componentDidMount() {
    this.checkToken();
  }

  render() {
    const { t } = this.props;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    let { redirectToReferrer, email, magicLinksSent, loading, isValidToken, loginConfig, doneLoadingConfig } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    // Dont show login screen if this just users reload action
    if (isValidToken) return <div></div>;

    return (
    // <Login3/>
    <PublicLogin/>
    );
  }
}
export default withTranslation()(LoginMain);
