import { getParkingRateById } from './ParkingRatesService';
import MomentUtils from '@date-io/moment';

const moment = new MomentUtils();

const getParkingPrice = async (parkingRateId) => {
    let price = 0;
    if (parkingRateId) {
        const parkingRates = await getParkingRateById(parkingRateId);
        price = parkingRates.rate;
    }
    return (price)
};

const currencyFormat = (num) => {
    return (num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' EUR')
};

const countWorkDays = async (dateFrom, dateTo, freedays, inlcudeWeekendsInSharing) => {

    const start = moment.date(dateFrom, 'YYYY-MM-DD');
    const end = moment.date(dateTo, 'YYYY-MM-DD');
    const freedays_ = freedays ? freedays : [];
    const holidays = freedays_.map(i => moment.date(i.start).format('YYYY-MM-DD'));
    let days = 0;
    for (let i = start; start.isBefore(end); i.add(1, 'days')) {
        if (((i.day() !== 0 && i.day() !== 6) || inlcudeWeekendsInSharing) && !holidays.includes(i)) {
            days++
        }
    }
    return days;
}

export { getParkingPrice, currencyFormat, countWorkDays };