import { axiosA } from './AuthService';
import { UserNotificationConfig } from 'components/settings/SettingProperties';
import * as pushServiceWorker from '../pushServiceWorker';
import { NotificationManager } from 'react-notifications';
import { isBrowserSafari, isMultiTouch, sendToNative } from 'utils/bridge';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import i18next from 'i18next';

const getSettings = async (userId, checkDisabled) => {
  try {
    let query = '?true';
    if (userId) {
      query += `&OwnerId=${userId}`;
    }
    if (checkDisabled) {
      query += `&CheckDisabled=${checkDisabled}`;
    }
    const { data } = await axiosA.get(`/Settings${query}`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const getUserSettings = async (userId) => {
  try {
    const { data } = await axiosA.get(`/Settings/user/${userId}`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const updateSettingsLocale = async (Locale, userId) => {
  try {
    const items = await getUserSettings(userId);
    const LocaleSettings = await items.find((i) => i.property === 'Locale');

    let localeSettings = {
      value: Locale,
      description: 'User locale setting',
      category: 'Custom',
      property: 'Locale',
    };

    if (LocaleSettings) {
      localeSettings['Id'] = LocaleSettings.id;
      await axiosA.put(`/Settings/${LocaleSettings.id}`, localeSettings);
    } else {
      await axiosA.post(`/Settings`, localeSettings);
    }
  } catch (e) {
    throw new Error(e);
  }
};

const updateNotificationConfig = async (
  isSystemSettings = false,
  settingObj,
  notificationConfig,
  settingId,
  userId
) => {
  let customerNotificationConfig = {
    value: JSON.stringify(notificationConfig),
    description: settingObj.description,
    category: isSystemSettings ? 'User' : 'Custom',
    property: settingObj.property,
  };
  try {
    if (isSystemSettings) {
      if (settingObj.isReadOnly) {
        customerNotificationConfig['isReadOnly'] = settingObj.isReadOnly;
      }
    } else {
      if (settingObj.ownerId) {
        customerNotificationConfig['ownerId'] = settingObj.ownerId;
      }

      if (userId) {
        customerNotificationConfig['ownerId'] = userId;
      }
    }

    if (
      (settingId && isSystemSettings) ||
      settingObj.category === customerNotificationConfig['category']
    ) {
      customerNotificationConfig['Id'] = settingId;
      await axiosA.put(`/Settings/${settingId}`, customerNotificationConfig);
    } else {
      await axiosA.post(`/Settings`, customerNotificationConfig);
    }
  } catch (e) {
    new Error(e);
  }
};

const updateUserSetting = async (
  isSystemSettings = false,
  settingObj,
  settingId,
  userId
) => {
  try {
    let userValue = {
      value: settingObj.value,
      description: settingObj.description,
      category: isSystemSettings ? settingObj.category : 'Custom',
      property: settingObj.property,
    };

    if (isSystemSettings) {
      if (settingObj.isReadOnly) {
        userValue['isReadOnly'] = settingObj.isReadOnly;
      }
    } else {
      if (settingObj.ownerId) {
        userValue['ownerId'] = settingObj.ownerId;
      }

      if (userId) {
        userValue['ownerId'] = userId;
      }
    }

    if (
      (settingId && isSystemSettings) ||
      settingObj.category === userValue['category']
    ) {
      userValue['Id'] = settingId;
      await axiosA.put(`/Settings/${settingId}`, userValue);
    } else {
      await axiosA.post(`/Settings`, userValue);
    }
  } catch (e) {
    new Error(e);
  }
};

// TODO split this method into more manageable :)
const requestNotificationPermissionAndUpdate = async (
  isSystemSettings = false,
  currentUser,
  setting,
  notificationConfig,
  t
) => {
  let userId = currentUser?.id;
  let settingId = setting?.id;

  try {
    // TODO: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    //  const isSafari = isMultiTouch();
    const isSafari = isBrowserSafari();
    //console.log('is safari',isSafari )
    let deviceId = undefined;
    if (isSafari) {
      sendToNative('requestApnDeviceToken', 'message-from-webview');
      // INFO: device id is set on refresh on ios, so we can access it through localStorage.getItem('deviceId')
      deviceId = pushServiceWorker.getDeviceId();
      //console.log( 'if isSafari' )
    } else {
      //console.log( 'isSafari' )
      // https://developers.google.com/web/updates/2015/04/permissions-api-for-the-web#permissionsquery
      const pushPermission = await navigator.permissions.query({
        name: 'push',
        userVisibleOnly: true,
      });
      const notificationPermission = await navigator.permissions.query({
        name: 'notifications',
      });

      const permission = await Notification.requestPermission();
      if (permission !== 'granted' && notificationConfig.NotifyPush) {
        NotificationManager.error(i18next.t('error.BrowserNotificationsDisabled'));
      }
    }

    // TODO: when administrator modifies settings he has different url, so even when disables for customer
    // he unsubscribes, this requires checking of scope of registration maybe
    // if users settings then check scope for user, else unsubscribe yours
    // as option is changed no actual unsubscribing should be required as its disabled in BE
    // if (notificationConfig && notificationConfig.NotifyPush) {
    //   try {
    //     await pushServiceWorker.register(undefined, deviceId);
    //   } catch (error) {
    //     console.error('error.NotificationSettingsUpdateFailed', error);
    //   }
    // }

    // accountprovider checks if push enabled for user, and tries to subscribe after login
    // no need to update setting
    if (setting && notificationConfig) {
      await updateNotificationConfig(
        isSystemSettings,
        setting,
        notificationConfig,
        settingId,
        userId
      );
    }
  } catch (error) {
    // catch and notify user
    // NotificationManager.error(i18next.t('error.NotificationSettingsUpdateFailed'));
    console.error('error.NotificationSettingsUpdateFailed', error);
    // throw Error(error);
  }
};

const updateDateTimeSetting = async (isSystemSettings, settingItem, user) => {
  const setting = settingItem;
  await updateUserSetting(isSystemSettings, setting, setting.id, user?.id);
};

const unsubscribePush = async () => {
  await pushServiceWorker.unsubscribe();
};

const getCurrentLng = async () => {
  const { data } = await axiosA.get('/Settings/CurrentLng');
  return data
}

export {
  getSettings,
  updateSettingsLocale,
  updateNotificationConfig,
  updateUserSetting,
  requestNotificationPermissionAndUpdate,
  updateDateTimeSetting,
  getUserSettings,
  unsubscribePush,
  getCurrentLng
};
