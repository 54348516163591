import { axiosA, vapidPubKey } from './utils/AuthService';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const scope = '/push';
const pushUri = '/PushNotifications';

export const getDeviceId = () => {
  return localStorage.getItem('deviceId');
};

export async function register(registration, mobileDeviceId) {
  
  if ('serviceWorker' in navigator && !mobileDeviceId) {
    registration = await navigator.serviceWorker.register('/sw.js', {
      scope: scope,
    });

    console.log('waiting for acceptance');
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPubKey),
    });
    console.log('acceptance complete');

    await axiosA.post(pushUri, {
      subscription: subscription,
    });
  } else {
    //alert('should call '+ mobileDeviceId)
    console.error('Service workers are not supported in this browser');
    const rqs = {
      deviceId: mobileDeviceId,
      os: null
    }
    try {

      let storedDeviceToken = JSON.parse(mobileDeviceId)
      rqs.deviceId = storedDeviceToken.token
      rqs.os = storedDeviceToken.os

    } catch (error) {

    }
    await axiosA.post(pushUri, rqs);
  }
}

export async function unsubscribe() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration(scope);
    const subscription = await registration.pushManager.getSubscription();
    if (subscription) {
      await axiosA.delete(`${pushUri}?endpoint=${subscription.endpoint}`);
    }
  } else {
    await axiosA.delete(`${pushUri}?deviceId=${getDeviceId()}`);
  }
}

// TODO: resubscribe logic should be added
// https://serviceworke.rs/push-subscription-management_service-worker_doc.html
