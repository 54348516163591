import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import {
  Grid,
  InputBase,
  Typography,
   Select, MenuItem,
} from '@mui/material';
// material-ui
import lt from '../../../assets/icons/languages/lt.svg';
import ru from '../../../assets/icons/languages/russia.svg';
import en from '../../../assets/icons/languages/united-kingdom.svg';
import { axiosA } from '../../../utils/AuthService';
import { getUserSettings, updateSettingsLocale } from '../../../utils/SettingsService';
import i18n from '../../../utils/i18n';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    '&:focus': {
    },
  },
}));



const LocalizationSelection = (props) => {
  const {currentUser, lng} = props;
  const [locales, setLocales] = useState([]);
  const [val, setVal] = useState(localStorage.currentLng);

  const handleChange = async (event) => {
    setVal(event.target.value);
    const lng = event.target.value;
    await localStorage.setItem('currentLng', lng)
    const l = lng.toLowerCase()
    await updateSettingsLocale(l, currentUser.id);
    await i18n.changeLanguage(l);

  };
 const fetchLocales = async () => {
   try {
     const { data } = await axiosA.get('Translations/locales')
     const locales = await data.items.map(e => { return { key: e.key, lng: e.key.slice(0, 2) } })
     await setLocales( locales )
   } catch (e) {
     console.error(e)
   }
 };

 const setFormLang = async() => {
    const thisLang= localStorage.currentLng;
    let formLanguage = '';
    if (thisLang === 'lt-lt' || thisLang === 'lt-LT') {
      formLanguage = 'lt-LT';
      setVal( formLanguage );
    }
    if (thisLang === 'en-GB' || thisLang === 'en-gb') {
      formLanguage = 'en-GB';
      setVal( formLanguage );
    }
    if (thisLang === 'ru-ru' || thisLang === 'ru-RU') {
      formLanguage = 'ru-RU';
      setVal( formLanguage );
    }
  }



  useEffect(() => {
    setFormLang();
    fetchLocales();

  }, []);

  const getflag = (lang) => {
    let flag = (<div></div>);
    if (lang ==='lt' || lang ==='lt-Lt') {
      flag = (<div><img src={lt} alt="background" width="20px" height="20px" /></div>);
    }
    if (lang ==='ru' || lang ==='ru-Ru') {
      flag = (<div><img src={ru} alt="background" width="20px" height="20px" /></div>);
    }
    if (lang ==='en' || lang ==='en-Gb') {
      flag = (<div><img src={en} alt="background" width="20px" height="20px" /></div>);
    }
    return (flag);
  }



return(
  <Select
    style = {{width:' 100 px', border: '0px'}}
    value = {val}
    onChange = {handleChange}
    input={<BootstrapInput />}

  >
    {locales && locales.map((e, i) =>(
        <MenuItem key = {i} value = {e.key}>

          <Grid container alignContent='center'>
            <Typography  px={1} py ={0.2}>
              {(e.lng).toUpperCase()}
            </Typography>

            {getflag(e.lng)}
          </Grid>
        </MenuItem>

      )
    )}
  </Select>

);
};

export default LocalizationSelection;