import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/app';
import 'firebase/auth';

// action - state management
import { LOGIN, LOGOUT } from '../stores/actions';
import loginReducer from '../stores/loginReducer';
import * as jwtDecode from 'jwt-decode';
// project imports
import Loader from 'ui-component/Loader';
import config from '../config';
import Api from '../constants/Api';

import { getCurrentUser } from '../utils/UsersService';

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  firebaseUser: null,
};
const host = window.location.href;
const actionCodeSettings = {
  url: `${host}`,
  handleCodeInApp: true,
};

const isNew = true;

// ==============================|| User CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  let firebaseUser = undefined;

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          console.log(user);
          console.log(actionCodeSettings);
          user
            .getIdToken()
            .then((token) => {
              console.log('got token', token);

              if (user.tenantId) {
                localStorage.setItem('tenantId', user.tenantId);
              }
              localStorage.setItem(Api.ACCESS_KEY, token);
              const currentPath = user.tenantId ? '/dashboard' : '/publicMap';
              localStorage.setItem('currentPath', currentPath);
              //  const info = jwtDecode(token);
              //  const secondsLeft = info.exp - parseInt(Date.now() / 1000);
              //
              //  console.log('token will expire in ' + secondsLeft + ' refreshing');
              //  console.log('api.re_t_if' + Api.RE_T_IF + ' refreshing');
              //  setInterval(
              //      checkTokenValidity,
              //      Api.RE_T_IF * 1000
              // );
            })
            .catch((e) => {
              console.error(e);
            });
          firebaseUser = user;

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              firebaseUser: {
                id: user.uid,
                email: user.email,
                name: user.displayName,
              },
            },
          });
        } else {
          if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
              email = window.prompt(
                'Please provide your email for confirmation'
              );
              console.log('input email', email);
            }
            let params = new URLSearchParams(window.location.href);
            let tenantId = params.get('tenantId');
            if (tenantId) {
              firebase.auth().tenantId = tenantId;
            }
            console.log('should auth with email', email);
            firebase
              .auth()
              .signInWithEmailLink(email, window.location.href)
              .then((result) => {
                window.localStorage.removeItem('emailForSignIn');
                console.log('signed in with email', result);
              })
              .catch((error) => {
                alert(error);
              });
          } else {
            localStorage.clear();
            sessionStorage.clear();
            firebase.auth().tenantId = null;
            dispatch({
              type: LOGOUT,
            });
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const checkTokenValidity = () => {
    console.log('checkTokenValidity');
    const token = localStorage.getItem(Api.ACCESS_KEY);
    if (!token) {
      console.error('no token nothing to refresh');
      return;
    }

    const info = jwtDecode(token);
    if (!info?.firebase) {
      console.log('token is not issued by firebase ignore');
      return;
    }

    const secondsLeft = info.exp - parseInt(Date.now() / 1000);
    if (secondsLeft < Api.RE_T_IF) {
      console.log('token will expire in ' + secondsLeft + ' refreshing');
      refreshToken();
    }
  };

  const tokenRefreshInterval = setInterval(
    checkTokenValidity,
    Api.RE_T_IF * 1000
  );

  const refreshToken = () => {
    //TODO: figure out how to refresh token
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error('unable to refresh user null');
      user = firebaseUser;
      if (!user) {
        console.error('unable to refresh user not cached');
        return;
      }
    }
    console.log('refreshToken', user);
    user
      .getIdToken(true)
      .then((token) => {
        console.log('token', 'new token acquired', token);
        // if(user.tenantId) {
        //     localStorage.setItem("tenantId", user.tenantId);
        // }
        localStorage.setItem(Api.ACCESS_KEY, token);

        console.log('token will expire in ' + secondsLeft + ' refreshing');
        setInterval(checkTokenValidity, Api.RE_T_IF * 1000);
        const info = jwtDecode(token);
        const secondsLeft = info.exp - parseInt(Date.now() / 1000);
        console.log(
          'token will expire in ' + secondsLeft + ' refresh new timer'
        );
      })
      .catch((e) => { });
  };

  const firebaseEmailPasswordSignIn = (email, password) => {
    try {
      const res = firebase.auth().signInWithEmailAndPassword(email, password);
      firebase.auth().user = res.user;
      localStorage.setItem('emailForSignIn', email);

    } catch (err) {
      console.log(err.message);
    }
  }

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

  const firebaseEmailLink = async (email) => {
    // TODO: fix issues caused by "masterpeice" (yes thats correct masterpEIce) /api/v1/uiconfig.json
    const rsp = await fetch(
      `${window.location.origin}/api/v1/tenants?email=${email}`
    );
    const { tenantId } = await rsp.json();

    if (tenantId) {
      firebase.auth().tenantId = tenantId;
    }

    const signInBody = {
      Email: email,
      ActionCodeSettings: actionCodeSettings,
    };

    const tenant = tenantId ? tenantId + '/' : '';

    const sendStatus = await fetch(
      `${window.location.origin}/api/${tenant}v1/Auth/signIn`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(signInBody),
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });

    return sendStatus ? Promise.resolve() : Promise.reject();
  };

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };



  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    //    console.log('updated profile if');
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailLink,
        firebaseEmailPasswordSignIn,
        login: () => { },
        firebaseGoogleSignIn,
        logout,
        resetPassword,
        updateProfile,
        refreshToken,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
