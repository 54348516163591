import React, { Component } from 'react';
import './ExchangeSpaceForm.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import SpaceFormHead from './head/SpaceFormHead';
import InputDate from '../../ui/inputs/date/InputDate';
import { Grid, Fab, Box, Dialog, Checkbox, Typography, DialogActions, Button, DialogContent, ListItem, List, ListItemIcon, Divider, CircularProgress } from '@mui/material';
import UserList from '../../users/list/UsersList';
import { InputSearch } from '../../ui/inputs/search/Search';
import { getUsers } from '../../../utils/UsersService';
import { shareSpace, getSharedSpaces, shareSpaceList, getFreeEntrance } from '../../../utils/ParkingService';
import { withSnackbar } from 'notistack';
import { getParkingPrice } from "../../../utils/PricingHelpers";
import MomentUtils from '@date-io/moment';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../../constants/Api';
import PriceInfoCard from '../../ui/informationCard/PriceInfoCard';
import { PARKING_GROUPS, PAID_SHARE } from 'constants/Features';
import { featureEnabled } from 'utils/FeatureTogglerService';
import { getParkingGroupsForSharing } from 'utils/ParkingGroupsService';

const moment = new MomentUtils({ locale: 'lt', })
const searchAPIDebounced = AwesomeDebouncePromise(getUsers, Api.SEARCH_DEBOUNCE);

class ExchangeSpaceForm extends Component {
  state = {
    from: moment.date(new Date()).utc(0).startOf('day'),
    to: moment.date(new Date()).utc(0).endOf('day'),
    space: {
      id: null,
      name: null,
      parkingLotId: null,
      parkingLotName: null,
      ownerId: null

    },
    user: {
      name: '',
      id: null
    },
    users: [],
    openedUsersDialog: false,
    id: '',
    isNew: true,
    minDate: null,
    maxDate: null,
    checkedF: false,
    showSlots: false,
    spaceNames: null,
    checked: [],
    checkedList: [],
    clicked: false,
    parkingGroupsEnabled: false,
    parkingGroups: [],
    checkedParkingGroups: [],
    paidShare: false,
    price: null,
    freedays: null,
    inlcudeWeekendsInSharing: false
  }

  goBack = () => {
    this.props.history.goBack()
  }

  fetchUsers = async (query) => {
    try {
      const { items } = await searchAPIDebounced(query)
      this.setState({ users: items })
    } catch (e) {
      console.error(e)
    }
  }

  search = (e) => {
    // pakeisti i debounce o state po atsakymo
    this.fetchUsers(e.target.value)
  }

  changeShareWith = async () => {
    await this.fetchUsers()
    await this.setState({ openedUsersDialog: true })
  }

  handleClose = () => {
    this.setState({ openedUsersDialog: false })
  }

  changeOwner = (user) => {
    this.setState({ user, openedUsersDialog: false })
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    try {

      let fromDate = this.state.from;
      if (fromDate) {
        fromDate = fromDate.format('YYYY-MM-DDT00:00:00.000').toString();
      }

      let toDate = this.state.to;
      if (toDate) {
        toDate = toDate.format('YYYY-MM-DDT23:59:59.999').toString();
      }

      if (!this.state.isNew) {
        var showMesg = false;
        if (!showMesg) {
          showMesg = this.state.sharedDates.some(element => {
            return this.checkDates(element.from)
          });
        }
        if (!showMesg) {
          showMesg = this.state.sharedDates.some(element => {
            return this.checkDates(element.to)
          });
        }
        if (showMesg) {
          // const confirmMessage = i18next.t('Jau užrezervuoti laikotarpiai liks kaip atskiri pasidalijimai. Ar norite keisti pasidalijimo laikotarpį?');
          const confirmMessage = 'Jau užrezervuoti laikotarpiai liks kaip atskiri pasidalijimai. Ar norite keisti pasidalijimo laikotarpį?';

          if (!window.confirm(confirmMessage)) {
            return;
          }
        }
      }
      if (this.state.checkedF) {
        this.setState({ clicked: true });
        const params = {
          spaceList: this.state.checked,
          sharedFrom: fromDate,
          sharedTo: toDate,
          ownerId: this.state.user.id,
          parkingGroups: this.state.checkedParkingGroups.map(x => { return x.id })
        }
        const data = await shareSpaceList(params);
        if (data) {
          this.props.enqueueSnackbar(this.props.t('notification.success.ShareSpace'), {
            variant: 'success',
          });
          this.props.history.goBack()
        }
      } else {
        this.setState({ clicked: true });

        const params = {
          parkingSpaceId: this.state.space.id,
          ownerId: this.state.space.ownerId,
          sharedFrom: fromDate,
          sharedTo: toDate,
          id: this.state.id,
          parkingGroups: this.state.checkedParkingGroups.map(x => { return x.id })
        }
        const { affectedIds } = await shareSpace(params, this.state.id);
        if (affectedIds && affectedIds.length) {
          this.props.enqueueSnackbar(this.props.t('notification.success.ShareSpace'), {
            variant: 'success',
          });
          this.props.history.goBack()
        }
      }

    } catch (e) {
      this.setState({ clicked: false });
      console.error(e)
    }
  }


  checkDates(dt) {
    const date = moment.date(dt);
    const startDate = moment.date(this.state.from).startOf('day');
    const endDate = moment.date(this.state.to).endOf('day');
    if (date.isSameOrBefore(endDate) && date.isSameOrAfter(startDate)) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount = async () => {

    let action = this.props.match.params.action;

    const paid = await featureEnabled(PAID_SHARE);
    await this.setState({
      ...this.state,
      paidShare: paid,
    });

    if (action === 'new') {
      const { space, user, sharedDates, shareFrom, spaceNames } = this.props.location.state;
      if (space && user) {
        this.setState({ space, user, sharedDates, spaceNames });
      }
      if (shareFrom) {
        const from = moment.date(shareFrom).utc(0).startOf('day');
        const to = moment.date(shareFrom).utc(0).endOf('day');
        this.setState({ from, to });
      }
    }
    else if (action === 'edit') {
      const { space, minDate_, maxDate_, from_, to_, id, sharedDates, disabledForStart, disabledForEnd } = this.props.location.state;
      if (space) {
        const from = moment.date(from_).utc(0);
        const to = moment.date(to_).utc(0);
        const minDate = moment.date(minDate_).utc(0);
        if (maxDate_) {
          const maxDate = moment.date(maxDate_).utc(0);
          if (maxDate) {
            this.setState({ maxDate });
          }
        }
        this.setState({ space, minDate, from, to, id, sharedDates, disabledForStart, disabledForEnd, isNew: false });
      }
    }

    if (this.props.isAdmin) {
      const parking_groups = await featureEnabled(PARKING_GROUPS);
      if (parking_groups == true) {
        this.setState({ parkingGroupsEnabled: parking_groups });
        const data = await getParkingGroupsForSharing();
        this.setState({ parkingGroups: data });
      }
    }

    if (this.props.location.state.space.parkingRateId) {
      const rate = await getParkingPrice(this.props.location.state.space.parkingRateId);
      this.setState({ price: rate })
    }

    const freedays = await getFreeEntrance();
    this.setState({ freedays: freedays });

    if (this.props.settings) {
      const inlcudeWeekendsInSharing = this.props.settings.Global.InlcudeWeekendsInSharing.value === 'true'
      this.setState({ inlcudeWeekendsInSharing: inlcudeWeekendsInSharing });
    }
  }

  setFromDate(momentDate) {
    this.setState({ from: momentDate });
    if (this.state.to) {
      if (momentDate.isAfter(this.state.to)) {
        this.setToDate(momentDate);
      }
    }
  }

  setToDate(momentDate) {
    momentDate = moment.date(momentDate).utc(0).endOf('day');
    this.setState({ to: momentDate });
    if (this.state.from) {
      if (momentDate.isBefore(this.state.from)) {
        this.setFromDate(momentDate);
      }
    }
  }

  fetchSharedSpacesDates = async (id) => {
    try {
      const { items } = await getSharedSpaces(id, true);
      return items.map((e) => {
        return { from: e.sharedFrom, to: e.sharedTo };
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (event) => {
    this.setState({ checkedF: event.target.checked });
    if (event.target.checked === true) {
      this.setState({ checked: this.state.spaceNames });
    } else {
      this.setState({ checked: [] });
    }
  };

  clickShowSlots = () => {
    this.setState({ showSlots: true, checkedList: this.state.checked });
  }

  slotsDialogCancel = () => {
    this.setState({ showSlots: false });
  }

  handleToggle = (el) => {
    const currentIndex = this.state.checkedList.indexOf(el);
    const newChecked = [...this.state.checkedList];

    if (currentIndex === -1) {
      newChecked.push(el);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checkedList: newChecked });
  }

  handleTogglePg = (el) => {
    const currentIndex = this.state.checkedParkingGroups.indexOf(el);
    const newChecked = [...this.state.checkedParkingGroups];

    if (currentIndex === -1) {
      newChecked.push(el);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checkedParkingGroups: newChecked });
  }

  slotsDialogConfirm = () => {
    const isChecked = this.state.checkedList.length > 0;
    this.setState({ checked: this.state.checkedList, checkedF: isChecked, showSlots: false });
  }

  render() {
    const currentLng = localStorage.getItem('currentLng');
    const {
      from,
      to,
      space,
      user,
      users,
      openedUsersDialog,
      sharedDates,
      minDate,
      maxDate,
      isNew,
      disabledForStart,
      disabledForEnd,
      showSlots,
      spaceNames,
      checkedList,
      checkedF,
      parkingGroupsEnabled,
      parkingGroups,
      checkedParkingGroups,
      paidShare, price, freedays, inlcudeWeekendsInSharing
    } = this.state;
    const { t, location, isAdmin } = this.props;
    const formatFrom = moment.date(this.state.from).utc(0).format(' YYYY-MM-DD');
    const formatTo = moment.date(this.state.to).utc(0).format(' YYYY-MM-DD');
    return (
      <React.Fragment>
        {isAdmin && <Dialog fullScreen open={openedUsersDialog} onClose={this.handleClose} >
          <Topbar
            title={t("users.Title")}
            closeDialog={this.handleClose}
            cbCreate={this.createUser}
          >
            <InputSearch onChange={this.search} placeholder={t("general.Search")} />
          </Topbar>
          {users &&
            <div className="UsersDialogContainer">
              <UserList
                users={users}
                onSelect={this.changeOwner}
              />
            </div>
          }
        </Dialog>}

        <Topbar
          title={t(isNew ? 'exchanges.Space' : 'exchanges.SharedPeriodChange')}
          location={location}
          cbBack={this.goBack}
          disableShadow={true}
        >
        </Topbar>
        <div className="ExchageContainer">
          <Grid item >
            <SpaceFormHead space={space} />
          </Grid>
          <form
            className="ExchageFormContainer"
            onSubmit={this.handleSubmit}
            noValidate
          >
            <Grid container
              //   direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} >
                <Grid container paddingLeft={1} >

                  {isNew && !checkedF &&
                    <Grid item xs={6}>
                      <InputDate
                        value={from}
                        label={t('exchanges.Begining')}
                        id="from"
                        excludesList={sharedDates}
                        onChange={(momentDate) => this.setFromDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {isNew && checkedF &&
                    <Grid item xs={6}>
                      <InputDate
                        value={from}
                        label={t('exchanges.Begining')}
                        id="from"
                        onChange={(momentDate) => this.setFromDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {!isNew && maxDate != null &&
                    <Grid item xs={6}>
                      <InputDate
                        value={from}
                        label={t('exchanges.Begining')}
                        id="from"
                        minDate={minDate}
                        maxDate={maxDate}
                        excludesList={disabledForStart}
                        onChange={(momentDate) => this.setFromDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {!isNew && maxDate == null &&
                    <Grid item xs={6}>
                      <InputDate
                        value={from}
                        label={t('exchanges.Begining')}
                        id="from"
                        minDate={minDate}
                        excludesList={disabledForStart}
                        onChange={(momentDate) => this.setFromDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {isNew && !checkedF &&
                    <Grid item xs={6}>
                      <InputDate
                        value={to}
                        label={t('exchanges.End')}
                        minDate={from}
                        id="to"
                        excludesList={sharedDates}
                        onChange={(momentDate) => this.setToDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {isNew && checkedF &&
                    <Grid item xs={6}>
                      <InputDate
                        value={to}
                        label={t('exchanges.End')}
                        minDate={from}
                        id="to"
                        onChange={(momentDate) => this.setToDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {!isNew && maxDate != null &&
                    <Grid item xs={6}>
                      <InputDate
                        value={to}
                        label={t('exchanges.End')}
                        minDate={minDate}
                        maxDate={maxDate}
                        id="to"
                        excludesList={disabledForEnd}
                        onChange={(momentDate) => this.setToDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                  {!isNew && maxDate == null &&
                    <Grid item xs={6}>
                      <InputDate
                        value={to}
                        label={t('exchanges.End')}
                        minDate={minDate}
                        // maxDate={maxDate}
                        id="to"
                        excludesList={disabledForEnd}
                        onChange={(momentDate) => this.setToDate(momentDate)}
                        currentLng={currentLng}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>

              {isNew && spaceNames && spaceNames.length > 0 && <Grid item xs={12} className="CheckBoxContainer">
                <Checkbox className="CheckBoxComponent"
                  checked={checkedF}
                  onChange={this.handleChange} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography onClick={this.clickShowSlots} style={{ cursor: 'pointer' }}>
                  {this.state.checked.length === spaceNames.length || !checkedF ? t("exchanges.AllSlots") : t("exchanges.SeveralSlots")}
                </Typography>
              </Grid>}
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {space.parkingRateId && paidShare && (<PriceInfoCard
                type="exchange"
                dateFrom={from}
                dateTo={to}
                parkingInfo={space}
                price={price}
                freedays={freedays}
                inlcudeWeekendsInSharing={inlcudeWeekendsInSharing}
              />)}

              {parkingGroupsEnabled && parkingGroups && (parkingGroups.length > 0) &&
                (<Grid item xs={10} py={1} >
                  <Typography className='h6' color='#27348A'>
                    {t('exchangeSpaceForm.forGroupOnly')}
                  </Typography>
                  <List sx={{ 'padding-bottom': '0px !important', 'padding-top': '0px !important' }}>
                    {parkingGroups.map((el, i) => (
                      <div key={i}>
                        <ListItem sx={{ 'padding-bottom': '0px !important', 'padding-top': '0px !important' }}>
                          <ListItemIcon >
                            <Checkbox
                              edge="start"
                              checked={checkedParkingGroups.indexOf(el) !== -1}
                              tabIndex={-1}
                              // disableRipple
                              onChange={() => this.handleTogglePg(el)}
                              color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                              disabled={!isNew}
                            />
                          </ListItemIcon>
                          <Box >
                            <Typography className='h5'>{el.title}</Typography></Box>
                        </ListItem>
                      </div>
                    )

                    )}
                  </List>
                </Grid>
                )}
              <Grid item xs={10} py={2} >

                <Fab disabled={this.state.clicked}
                  className="full-width secondary-btn" variant="extended"
                  type="submit"
                  value="Submit"
                  aria-label="Save">
                  {this.state.clicked ?
                    <Box position="realative" display="inline-flex">
                      <CircularProgress size={30} color={'secondary'} />
                    </Box> :
                    <Box fontSize={14} textAlign="center">
                      {t('general.Save')}
                    </Box>
                  }
                </Fab>
              </Grid>
            </Grid>
          </form>
        </div>
        <Dialog
          //fullScreen
          open={showSlots}
        >
          <div >
            <DialogContent>
              <List>
                {spaceNames && spaceNames.map((el, i) => (
                  <div key={i}>
                    <ListItem >
                      <ListItemIcon >
                        <Checkbox
                          edge="start"
                          checked={checkedList.indexOf(el) !== -1}
                          tabIndex={-1}
                          // disableRipple
                          onChange={() => this.handleToggle(el)}
                          color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </ListItemIcon>
                      <Box className='ExchangeSpaceBlock'>
                        <div className='ExchangeSpaceTitle'>{el.name}</div></Box>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={this.slotsDialogCancel} color="primary">
                {t('general.Cancel')}
              </Button>
              <Button onClick={this.slotsDialogConfirm} color="primary">
                {t('general.Confirm')}
              </Button>
            </DialogActions>

          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(ExchangeSpaceForm))