import React from 'react';
import './SpacesOwnerListItem.scss'
import { useTheme } from '@mui/material/styles';

import { ListItem, IconButton, Divider, Grid, Typography, Button, Box ,Card} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import { faCheck, faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import ListExpanded from '../../../ui/list/expanded/ListExpanded';
import ParkingPlaceCard from '../../../ui/cards/ParkingPlaceCard';
import DeleteActionButton from "../../../uiactions/actionbuttons/DeleteActionButton";

const SpacesOwnerListItem = (props) => {
  const theme = useTheme();
   return(

      <ListItem className="spaceItemContainer"
        // button
        // onClick={props.goTo ? () => props.goTo(props.space) : props.onSelect ? () => props.onSelect(props.space) : null}
      >
              <Grid item xs={12} sx={{ background: theme.palette.paper, py: 1.5, px: 2 , borderRadius: '10px', borderColor: theme.palette.primary.main, border: '2px solid #3A4D8F'}}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  alignItems= 'center'
                >
                  <Grid item xs={1}/>
                    <Grid xs container direction="column" spacing={2} >
                      <Grid item xs >
                        <Typography className ='h2'>
                          {`${props.space.name} `}{props.space.isOwned && (props.space.bookingLevel == 0 || props.showOwnerIcon) && <FontAwesomeIcon className={'NameIcon'} icon={faUser} size="xs" />}
                        </Typography>
                        <Typography className='h2'>
                          {props.spaceName && ` (${props.spaceName.name}) `}
                        </Typography>
                          <ListExpanded name={props.space.parkingLotName} names={props.space.parkingLotNames}></ListExpanded>
                      </Grid>
                    </Grid>
                  <Grid item >
                    <Box>
                      {props.goTo && <div className="pb-10">
                        {props.goTo &&
                        <IconButton aria-label="Enter" onClick={() => props.goTo(props.space)}>
                          <FontAwesomeIcon icon={faChevronRight} size="xs" />
                        </IconButton>
                        }
                      </div>
                      }
                      {props.onSelect && <div className="pb-10">
                        {props.onSelect &&
                        <Button className="actionBtn" aria-label="Delete" onClick={() => props.onSelect(props.space)}>
                          <FontAwesomeIcon icon={faCheck} size="xs" />
                        </Button>
                        }
                      </div>
                      }
                      {props.onShare && <div className="pb-10">
                        {props.onShare &&
                        <Button className="actionBtn" aria-label="Delete" onClick={() => props.onShare(props.space)}>
                          <Typography className="shareBtn-text">{props.t("general.Share")}</Typography>
                        </Button>
                        }
                      </div>
                      }
                      {props.onDelete && <div className="pb-10">
                        {props.onDelete &&
                        <DeleteActionButton
                            openit = {false}
                            onConfirm ={() => props.onDelete(props.space)}

                        />
                        // <Button className="actionBtn" aria-label="Delete" onClick={() => props.onDelete(props.space)}>
                        //   <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                        // </Button>
                        }
                      </div>
                      }
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
      </ListItem>

  );
}

export default withTranslation()(SpacesOwnerListItem);
