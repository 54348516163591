import PropTypes from 'prop-types';
import React, { useState } from 'react';
import i18next from 'i18next';
import okey from '../../../../assets/icons/ok-1976099.svg'
import AlertDialogSlide from '../../../dialogs/AlertDialogSlide';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery,
TextField
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useAuth from '../../../../hooks/useAuth';
import useScriptRef from '../../../../hooks/useScriptRef';
import AnimateButton from '../../../../ui-component/AnimateButton';

// assets


import Google from '../../../../assets/icons/social-google.svg';
import Api from '../../../../constants/Api';
import background from "../../../../assets/images/graphic_cars.png";
import LoaderCircle from "../../../../ui-component/LoaderCircle";
import SquareIcon from '@mui/icons-material/Square';
import EmailIcon from '@mui/icons-material/Email';

//todo alert dialog on submit
// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ loginProp, ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [send, setSend] = useState(true);
    const [open, setOpen] = useState(false);
    const [load, setLoad ] = useState(false);
    const { firebaseEmailLink, firebaseGoogleSignIn } = useAuth();

    const handleClickOpen = () => {
        setOpen(true);
    };


 
    const handleClose = () => {
        setOpen(false);
    };
    const googleHandler = async () => {
        try {
            await firebaseGoogleSignIn();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>

                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        {/*<Typography variant="subtitle1">Sign in with Email address</Typography>*/}
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    email: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(i18next.t('error.EmailInvalid')).max(255).required(i18next.t('Login3.EnterEmailToGetLink')),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting ,  }) => {
                    const email = values.email.toLowerCase();
                    try {
                        setLoad(true);
                        await firebaseEmailLink(email).then(
                            () => {
                              localStorage.setItem('emailForSignIn', email);
                                setLoad(false);
                                setOpen(true);

                                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                                // github issue: https://github.com/formium/formik/issues/2430
                            },
                            (err) => {
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: i18next.t('error.EmailInvalid') });
                                    setSubmitting(false);
                                }
                            }
                        );
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }

                }}

                setEmail={(value, values) => {
                    values.email = value
                }

                }
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setEmail }) => (
                    <Box sx={{ mt: 3 }}>
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                        <TextField
                                    id="outlined-adornment-email-login"
                                    type="email"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                   
                                    className="authInput"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon sx={{ color: "rgba(35, 49, 68, 0.25)" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                           {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>
                        </Box>

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 3 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className='authButton'
                                >
                                    {i18next.t('auth.Login')}
                                </Button>
                            </AnimateButton>
                        </Box>
                        {isSubmitting && <LoaderCircle></LoaderCircle>}
                    </form>
                    </Box>
                )}
            </Formik>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
              <Grid  item xs={12} container paddingLeft={2} justifyContent='center' alignContent='center' alignItems = 'center' sx={{ pr: 2.5 }}>
                  <Grid item  xs={4}>
                <img
                    src={okey}
                    width='70vh'
                    alt="background"
                />
                  </Grid>
                  <Grid item xs ={8} justifyContent='center' alignContent='center' >
                      <Typography variant ='h3' >
                      {i18next.t('AuthLogin.LinkSentSuccessfully')} 
                      </Typography>
                  </Grid>
                </Grid>

                <DialogContent>

                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="body">
                        {i18next.t('AuthLogin.CheckYourEmail')} 
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>

                    <Button variant="contained" size="small" onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

FirebaseLogin.propTypes = {
    loginProp: PropTypes.number
};

export default FirebaseLogin;
