const TERMS_AND_CONDITIONS_CLEAR = 'onPwaLogout';
const REQUEST_TO_TRACK = 'onRequestToTrack';

const sendToNative = (requestType, payload) => {
  // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#injectedjavascript
  const msg = {
    type: requestType,
    payload: payload,
  };
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
};

const clearNativeTermsAndConditions = () => {
  const msg = {
    type: TERMS_AND_CONDITIONS_CLEAR,
    payload: 'TermsAndConditions_Clear',
  };
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
};

const requestToTrack = () => {
  sendToNative(REQUEST_TO_TRACK, 'RequestToTrack');
};

const isMultiTouch = () => {
  console.log('maxMultiTouch', navigator.maxTouchPoints )

 // console.log('userAgent', navigator.userAgent.indexOf("Safari") > -1)
  return navigator.maxTouchPoints > 0;
};

const isBrowserSafari = () => {
  return navigator.userAgent.indexOf("Safari") > -1 ;
}

export {
  sendToNative,
  clearNativeTermsAndConditions,
  requestToTrack,
  isMultiTouch,
    isBrowserSafari,
};
