import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Grid, IconButton, Typography, Box, Button, FormControl, TextField, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faUser, faPhone, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import './Settings.scss';
import { putUser, getUser } from 'utils/UsersService';
import { NotificationManager } from 'react-notifications';

class AccountDetails extends Component {
    constructor() {
        super();
        this.state = {
            user: {
                phone: '',
                name: '',
                iban: ''
            }
        }
    }

    componentDidMount = async () => {
        const userId = this.props.currentUser.id;
        getUser(userId).then((user) => {
            if (user) {
                this.setState({ user: user })
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        putUser(this.state.user).then((resp) => {
            if (resp) {
                getUser(resp).then((user) => {
                    NotificationManager.success(this.props.t('notification.success.UpdateUser'));
                    this.setState({ user: user })
                })
            }
        })
    };

    handleCard = (event) => {
        this.setState(prevState => ({ user: { ...prevState.user, [event.target.id]: event.target.value } }));
    }

    render() {
        const { user } = this.state;
        const { t } = this.props;
        return (
            <div  className='public-form'>

                <Grid container sx={{ mb: 2, mt: 1, ml: 2 }}>
                    <Grid item xs={1}>
                        <IconButton sx={{ mt: 1 }} onClick={this.goBack}>
                            <FontAwesomeIcon icon={faChevronLeft} size="1x" sx={{ fontWeight: 400 }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h3" gutterBottom sx={{ mt: 2, ml: 1, fontWeight: 400 }}>
                            {t('publicApp.EditProfileDetails')}
                        </Typography>
                    </Grid>
                </Grid>

                <form noValidate onSubmit={this.handleSubmit}>
                    <Box sx={{ mt: 4, ml: 3, mr: 3 }}>
                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth >
                                <TextField
                                    id="name"
                                    value={user.name}
                                    onChange={this.handleCard}
                                    className="inputAccount"
                                    placeholder={t('publicApp.NameSurname')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{ pr: 1, pl: 1 }}>
                                                <FontAwesomeIcon icon={faUser} color="rgba(35, 49, 68, 0.25)" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth >
                                <TextField
                                    id="phone"
                                    value={user.phone}
                                    onChange={this.handleCard}
                                    className="inputAccount"
                                    placeholder={t('publicApp.Phone')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{ pr: 1, pl: 1 }}>
                                                <FontAwesomeIcon icon={faPhone} color="rgba(35, 49, 68, 0.25)" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    {/* <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth >
                                <TextField
                                    id="iban"
                                    value={user.iban}
                                    onChange={this.handleCard}
                                    className="inputAccount"
                                    placeholder='IBAN'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" sx={{ pr: 1, pl: 1 }}>
                                                <FontAwesomeIcon icon={faCreditCard} color="rgba(35, 49, 68, 0.25)" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box> */}
                    <Box className='bottom-button' sx={{ pr: 3, pl: 3, pb: 3 }}>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className='authButton'
                        >
                            {t('publicApp.Update')}
                        </Button>
                    </Box>
                </form>
            </div>
        )
    }
}

export default withSnackbar(withTranslation()(AccountDetails));