import React, { Component } from 'react';
import { Topbar } from '../ui/topbar/Topbar';
import { InputSearch } from '../ui/inputs/search/Search';
import { withTranslation } from 'react-i18next';
import { List } from '@mui/material';
import { withSnackbar } from 'notistack';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../constants/Api';
import InfiniteScroll from 'react-infinite-scroll-component';
import GroupUserItem from './GroupUserItem';
import {
  postGroupUser,
  getGroupUsers,
  getUsers,
} from 'utils/GroupUsersService';

const searchAPIDebounced = AwesomeDebouncePromise(
  getUsers,
  Api.SEARCH_DEBOUNCE
);

class GroupUsersAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      usersList: [],
      loadedRowsMap: {},
      searching: false,
      group: {},
      groupUsers: [],
    };
  }

  fetchUsers = async (query, offset, loadingMore) => {
    try {
      let hasMore = false;
      const existings = this.state.groupUsers.map((x) => {
        return x.userId;
      });

      const resp = await searchAPIDebounced(this.state.group.id, query, offset);
      if (resp.nextOffset) hasMore = true;

      if (!loadingMore) {
        this.setState((prevState) => ({
          usersList: [...resp.items],
          nextOffset: resp.nextOffset,
          searching: true,
          count: resp.items,
          hasMore,
        }));
      } else {
        this.setState((prevState) => ({
          usersList: [...new Set([...prevState.usersList, ...resp.items])],
          nextOffset: resp.nextOffset,
          searching: true,
          count: resp.items,
          hasMore,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchGroupUsers = async (id) => {
    const data = await getGroupUsers(id);
    this.setState({ groupUsers: data });
    await this.fetchUsers();
  };

  search = async (e) => {
    this.setState({ query: e.target.value });
    await this.fetchUsers(this.state.query);
  };

  componentDidMount() {
    const { group } = this.props.location.state;

    if (group) {
      this.setState({ group });
    }
    this.fetchGroupUsers(group.id);
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasMore && this.state.usersList.length < 20) {
      this.fetchUsers(this.state.query, this.state.usersList.length, true);
    }
  }

  onChange = async (user) => {
    const value = {
      groupId: this.state.group.id,
      userId: user.id,
    };
    const data = await postGroupUser(value);
    const newList = this.state.usersList.filter((x) => x.id != user.id);
    const nextOffset = this.state.query.nextOffset + 1;
    this.setState({ usersList: newList });
    this.props.enqueueSnackbar(this.props.t('notification.success.addUser'), {
      variant: 'success',
    });
  };

  render() {
    const { t, location, history } = this.props;

    const goBack = () => {
      this.props.history.goBack();
    };

    return (
      <React.Fragment>
        {/* <ConfirmDialog open={true} title={t('confirm.Delete')}/> */}
        <Topbar title={t('users.Title')} cbBack={goBack}>
          <InputSearch
            onChange={this.search}
            placeholder={t('general.Search')}
          />
        </Topbar>

        <List className="UsersList">
          <InfiniteScroll
            dataLength={this.state.usersList.length}
            hasMore={this.state.hasMore}
            next={() =>
              this.fetchUsers(this.state.query, this.state.nextOffset, true)
            }
            loader={<p>{t('general.loading')}...</p>}
          >
            {this.state.usersList.length > 0 &&
              this.state.usersList.map((e, i) => (
                <GroupUserItem
                  user={e}
                  key={e.id + i}
                  //   onDelete={this.deleteUser}
                  onChange={this.onChange}
                />
              ))}
          </InfiniteScroll>
        </List>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withTranslation()(GroupUsersAdd));
