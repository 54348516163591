import React, {Component} from 'react';
import './Spaces.scss';
import { Topbar } from '../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { InputSearch } from '../ui/inputs/search/Search';
import { Grid, List} from '@mui/material';
import { InputSelect } from '../ui/inputs/select/Select';
import { getParkingLots, getParkingSpaces, resolveSpaceLot } from '../../utils/ParkingService';
import SpacesOwnerListItem from './owner-list/item/SpacesOwnerListItem';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../constants/Api';
import InfiniteScroll from "react-infinite-scroll-component";

const searchAPIDebounced = AwesomeDebouncePromise(getParkingSpaces, Api.SEARCH_DEBOUNCE);

// 
class Spaces extends Component {
  state = {
    query: '',
    parkingLots: [],
    selectedLot: this.props.t('spaces.All'),
    parkingSpaces: [],
    loadedRowsMap: {},
    searching: false,
  }
  fetchParkingLots = async () => {
    try {
      const resp = await getParkingLots()
      this.setState({parkingLots: [{id: '', name: this.props.t('spaces.All')}, ...resp.items]})
    }catch (e) {
      console.error(e)
    }
  }
  search = async (e) => {
    await this.setState({query: e.target.value})
    this.fetchParkingSpaces(this.state.query)
  }
  goToSpace = async (space) => {
    this.props.history.push({
      pathname: '/space/'+space.id,
      state: {space}
    })
  }
  resolveSpaceLot = async (spaces) => {
    const parkingSpaces = await resolveSpaceLot(spaces, this.state.parkingLots)
    return parkingSpaces;
  }
  fetchParkingSpaces = async (query, offset, loadingMore) => {
    try {
        let hasMore = false;
        const lotId = this.props.t('spaces.All') === this.state.selectedLot ? undefined : 
        this.state.selectedLot.id;
        const resp = await searchAPIDebounced(lotId, query, offset)
        const items = await this.resolveSpaceLot(resp.items);

        if(resp.nextOffset) hasMore = true
        if(!loadingMore) {
          this.setState(prevState => ({parkingSpaces: [...items], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore}))
        }else {
          this.setState(prevState => ({parkingSpaces: [...new Set([...prevState.parkingSpaces, ...items])], nextOffset: resp.nextOffset, searching: true, count: resp.items, hasMore}))
        }
      }catch (e) {
      console.error(e)
    }
  }
  setLot = (selectedLot) => {
    this.setState({selectedLot, searching: false}, () => this.fetchParkingSpaces(this.state.query, 0))
  }
  
  componentDidMount = async () => {
    await this.fetchParkingLots()
    await this.fetchParkingSpaces()
  }
  render() {
    const {parkingLots, selectedLot, parkingSpaces} = this.state;
    const {t} = this.props;
    return (
        <React.Fragment>
        <Topbar title={t('spaces.TopTitle')} location={this.props.location} >

        </Topbar>

            <List className="spacesContainer">

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={ 12}
                >

                    <InputSelect
                        options={parkingLots}
                        onChange={this.setLot}
                        value={selectedLot}
                        getOptionLabel={(option) => option['name']}
                        getOptionValue={(option) => option['id']}
                        placeholder={t("spaces.All")} />
                    <InputSearch onChange={this.search} placeholder={t("general.Search")} />

                </Grid>

            <InfiniteScroll
          dataLength={parkingSpaces.length}
          hasMore={(this.state.hasMore)}
          next={()=> this.fetchParkingSpaces(this.state.query, this.state.nextOffset, true)}
          loader={
          <p>{t('general.loading')}...</p>
          }
          // endMessage={
          //   parkingSpaces.length > 0 &&
          //   <p style={{ textAlign: "center" }}>
          //     <b>{t('general.Listend')}</b>
          //   </p>
          // }
          > { parkingSpaces.length > 0 && parkingSpaces.map((e, i) =>  (
            <SpacesOwnerListItem 
            key={i}
            space={e}
            goTo={() => this.goToSpace(e)} />
            )
            )
            
          }
          </InfiniteScroll>
            </List>

        </React.Fragment>

    );
  }
}

export default withTranslation()(Spaces)