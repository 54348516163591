import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Topbar } from '../../ui/topbar/Topbar';
import './Lot.scss';
import {
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Grid,
    Divider,
    Box, Button,
} from '@mui/material';
import { getSpacesByLotId } from '../../../utils/TerritoriesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFileImport } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../constants/Colors';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

class Lot extends Component {
    constructor() {
        super()
        this.state = {
            lot: {
                id: '',
                name: '',
            },
            territory: {
                id: '',
                name: '',
                isRemote: null
            },
            spaces: [],
            expanded: null,
        }
    }

    componentDidMount = async () => {

        if (this.props.location.state) {
            const { lot, territory, expanded } = this.props.location.state;
            if (lot && territory) {
                await this.setState({ lot: lot, territory: territory, expanded: expanded });
                await this.fetchSpaces();
            }
        }
    }

    setExpanded(newValue) {
        this.setState({ expanded: newValue })
    }


    fetchSpaces = async () => {
        if (this.state.lot.id) {
            try {
                const resp = await getSpacesByLotId(this.state.lot.id);
                this.setState({ spaces: resp });
                this.setState({ expanded: 'spaces' })
            } catch (e) {
                console.error(e)
            }
        }
    }

    goBack = async () => {
        this.props.history.push({
            pathname: '/territory',
            state: { territory: this.state.territory, expanded: 'lots' }
        })
    }

    editClick = async () => {
        this.props.history.push({
            pathname: '/lot/form',
            state: { lot: this.state.lot, territory: this.state.territory }
        })
    }

    spaceClick = (value) => {
        if (value.bookingLevel < 2) {
            this.props.history.push({
                pathname: '/lotSpace/form',
                state: { space: value, territory: this.state.territory, lot: this.state.lot }
            });
        }
    }

    newSpace = () => {
        this.props.history.push({
            pathname: '/lotSpace/form',
            state: { territory: this.state.territory, lot: this.state.lot }
        });
    }

    import = (name) => {
        this.props.history.push({
            pathname: '/import',
            state: { territory: this.state.territory, patchName: '/lot', name: name, lot: this.state.lot }
        });
    }

    render() {
        const { t } = this.props;

        const handleChange = (panel) => (event, isExpanded) => {
            this.setExpanded(isExpanded ? panel : false);
        };

        const handleSpace = (value) => () => {
            this.spaceClick(value);
        };

        const handleImport = (value) => () => {
            this.import(value);
        }
        let changeTitle = t('exchanges.Change');
        return (
            <div>
                <Topbar title={t("general.ParkingLot")} cbBack={this.goBack} >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="SpaceBlock"
                    >

                    </Grid>
                </Topbar>
                <div className="territoryContainer" >

                    <Grid  xs container direction='row' sx={{p: 2, py:1.5,  background:'#3A4D8F', border: '1px solid'}}>
                        <Grid item  xs >
                            <Typography variant = 'h3' color ='#ffffff'>
                                {this.state.lot.name}
                            </Typography>
                            <Typography variant = 'h5' color ='#ffffff'>
                                {this.state.territory.name}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Button  onClick={this.editClick}>
                                <Typography className="shareBtn-text">{changeTitle}</Typography>
                            </Button>

                            <Box>
                                <div className='pb-10'>
                                    <Button className="actionBtn" aria-label="Delete" onClick={this.editClick}>
                                        <Typography className="shareBtn-text">{changeTitle}</Typography>
                                    </Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>


                    <Accordion expanded={this.state.expanded === 'spaces'} onChange={handleChange('spaces')}>
                        <AccordionSummary className="summary" aria-controls="panel2bh-content" id="panel2bh-header">
                            <Typography variant ='h3' edge="start">{t("spaces.Title")}</Typography>
                            {this.state.expanded === 'spaces' ?
                                <div>
                                    <IconButton onClick={handleImport('lot')} edge="end" aria-label="Action" className="expansionIcon">
                                        <FontAwesomeIcon icon={faFileImport} color={Colors.iconHead} />
                                    </IconButton>
                                    {/* <IconButton onClick={this.newSpace} edge="end" aria-label="Action" className="expansionIcon">
                                        <FontAwesomeIcon icon={faPlus} color={Colors.iconHead} />
                                    </IconButton> */}
                                </div> : null
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="territoryLotsContainer">
                                <List component="nav" >
                                    {
                                        this.state.spaces.map((item) => {
                                            return (
                                                <React.Fragment key={item.id} >
                                                    <Divider />
                                                    <ListItem className="territoryLotsListItem"
                                                        onClick={handleSpace(item)}>
                                                        {item.bookingLevel < 2 && <ListItemText primary=
                                                            {<Typography className="MuiListItemText-primary SpaceName">{`${item.name} `}</Typography>}
                                                        ></ListItemText>}
                                                         {item.bookingLevel === 2 && <ListItemText secondary=
                                                            {<Typography className="heading">{`${item.name} `} {t('territory.owners')}</Typography>}
                                                        ></ListItemText>}
                                                        {item.bookingLevel < 2 && <FontAwesomeIcon icon={faEdit} color={Colors.iconHead} />}
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </List>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                </div>
            </div>
        )


    }
}
export default withTranslation()(Lot);