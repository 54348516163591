import config from "../config";

const initiatePayment = async (lot, payment, redirect) => {
    const kevin = require('@kevin.eu/kevin-platform-client');

    const client = new kevin.Client(config.kevin.clientId, config.kevin.clientSecret);

    const options = {
        headers: {
            'Redirect-URL': redirect
        },
        query: {
            redirectPreferred: true
        },
        body: {
            amount: payment.amount,
            currencyCode: lot.currencyCode,
            description: 'Parking',
            bankPaymentMethod: {
                creditorName: lot.receiverName,
                endToEndId: payment.parkingLogId.toString(),
                creditorAccount: {
                    iban: lot.iban
                }
            },
            cardPaymentMethod: {}
        },
    }

    return await client.payment.initiatePayment(options);
}

const getPaymentStatus = async (id) => {
    const kevin = require('@kevin.eu/kevin-platform-client');

    const client = new kevin.Client(config.kevin.clientId, config.kevin.clientSecret);
    
    const options = {
        paymentId : id
    }
    return await client.payment.getPayment(options)
}

export{
    initiatePayment,
    getPaymentStatus
}