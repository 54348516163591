import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Topbar } from 'components/ui/topbar/Topbar';
import { getUserVehicles } from 'utils/VehicleService';
import { getPublicTerrtories } from 'utils/TerritoriesService';
import { getParkingLog } from 'utils/TerritoriesService';
import { Box, Card, Grid, Typography, Divider, Container, IconButton, List, ListItem, Dialog, InputAdornment, TextField, Slider } from '@mui/material';
import icon_parking from '../../assets/icons/publicApp/icon_parking.svg';
import icon_car from '../../assets/icons/publicApp/icon_car.svg';
import icon_eur from '../../assets/icons/publicApp/icon_eur.svg';
import icon_time from '../../assets/icons/publicApp/icon_time.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import icon_search_active from '../../assets/icons/publicApp/icon_search_active.svg';
import MomentUtils from '@date-io/moment';

const moment = new MomentUtils({ locale: 'en' })

class PublicParking extends Component {
    constructor() {
        super();
        this.state = {
            vehicles: [],
            userId: null,
            lots: [],
            lot: null,
            parkingLog: null,
            mounted: true,
            open: false,
            searchLots: '',
            days: '00',
            hours: '00',
            minutes: '00',
            exitDate: null
        }
        this.timer = {}
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.setState({ mounted: false })
    }

    setPosition = async (position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude }
        if (this.state.lots.length > 0 && location) {
            await this.calcLot(location, this.state.lots);
        }
    }

    componentDidMount = async () => {
        const userId = this.props.currentUser.id;
        this.setState({ userId: userId })

        getUserVehicles(userId).then((vehicles) => {
            if (this.state.mounted) {
                let _vehicles = vehicles || [];
                this.setState({ vehicles: _vehicles })
            }
        });

        getPublicTerrtories().then((response) => {
            if (this.state.mounted) {
                this.setState({ lots: response });
                if ("geolocation" in navigator) {

                    navigator.geolocation.getCurrentPosition(this.setPosition, null, {
                        enableHighAccuracy: true,
                        timeout: 15000,
                        maximumAge: 0
                    });
                }
            }
        })
    }

    goAccountSettings = (pathname) => {
        this.props.history.push({
            pathname: pathname
        });
    }

    startTimer = () => {
        this.timer = setInterval(this.countParkingTime, 60000);
    }

    countParkingTime = () => {
        if (this.state.mounted) {

            let exitDate = moment.date(this.state.exitDate).add(1, 'minutes');
            this.setState({
                exitDate: exitDate.format('YYYY-MM-DD HH:mm')
            });
            this.getDiff()
        }
    }

    getDiff = () => {
        if (this.state.parkingLog) {
            const mins = moment.date(this.state.exitDate).diff(moment.date(this.state.parkingLog.entryDate), 'minutes');
            var d = mins / (60 * 24) | 0;
            var h = (mins - (d * 60 * 24)) / 60 | 0;
            var m = mins % 60 | 0;
            const days = d > 9 ? d : '0' + d;
            const hours = h > 9 ? h : '0' + h;
            const minutes = m > 9 ? m : '0' + m;

            this.setState({ days: days, hours: hours, minutes: minutes })
        }
    }

    calcLot = async (location, lots) => {
        let lot = lots[0];
        let distant = null;
        lots.forEach(x => {
            const a = Math.pow((Number(x.center.lat) - Number(location.lat)), 2);
            const b = Math.pow((Number(x.center.lng) - Number(location.lng)), 2);
            const c = Math.sqrt(a + b);
            if (distant === null || distant > c) {
                distant = c;
                lot = x
            }
        });

        this.setState({ lot: lot });
        await this.fnGetParkingLog(lot);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    onPlaceChanged = async (lot) => {
        clearInterval(this.timer);
        this.setState({ lot: lot });
        this.setState({ open: false });
        this.setState({ parkingLog: null });
        await this.fnGetParkingLog(lot);
    }

    fnGetParkingLog = async (lot) => {
        if (lot) {
            const plateNum = this.state.vehicles && this.state.vehicles.length > 0 ? this.state.vehicles[0].plateNum : null;
            getParkingLog(lot.id, plateNum).then((parkingLog) => {
                console.log(parkingLog)
                this.setState({ parkingLog: parkingLog, exitDate: parkingLog.exitDate });
                this.getDiff();
                this.startTimer();
            })
        }
    }

    fnShowLots = () => {
        this.setState({ open: true })
    }

    goPayment = (event, value) => {
        if (value > 99) {
            setTimeout(() => {
                this.props.history.push({
                    pathname: 'publicPayment',
                    state: { lot: this.state.lot, vehicles: this.state.vehicles, parkingLog: this.state.parkingLog }
                });
            }, 500)
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    };

    handleCard = (event) => {
        this.setState({ searchLots: event.target.value })
    }

    render() {
        const { t } = this.props;
        const { vehicles, lots, lot, open, parkingLog, searchLots, days, hours, minutes } = this.state;
        return (
            <div className='public-form' style={{ backgroundColor: '#F5F6FA' }}>
                <Topbar
                    title={t('publicApp.ParkingInfo')}
                    cbBack={this.goBack}
                    location={this.props.location}
                ></Topbar>
                <Box sx={{ top: 50, position: 'relative', overflow: 'auto', mb: 2 }} >
                    <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3 }}>
                        <Grid container >
                            <Grid item xs={2} >
                                <img src={icon_parking} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }}>
                                {lot && (<Typography variant="h3" gutterBottom sx={{ pl: 1 }}>
                                    {lot.name}
                                </Typography>)}
                            </Grid>
                            {lots.length > 0 && (
                                <Box sx={{ pl: 1, pr: 1, width: '100%', textAlign: 'center' }}>
                                    <Divider />
                                    <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500, color: '#3268B2' }} onClick={() => this.fnShowLots()}>
                                        {lot && lot.id ? t('publicApp.NotThisParkingLot') : t('publicApp.ParkingLots')}
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Card>
                    <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3, mb: 1 }}>
                        <Grid container >
                            <Grid item xs={2} >
                                <img src={icon_car} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }}>
                                <Typography variant="h3" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                    {t('publicApp.Vehicle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box style={{ textAlign: '-webkit-center' }}>
                            <Divider />
                            <Card sx={{ boxShadow: 3, m: 1, width: 'min-content', minWidth: '200px' }}
                            >
                                <Typography className='parking-plate-num' gutterBottom style={{ paddingLeft: '20px', paddingRight: '20px', color: '#3268B2' }} onClick={() => this.goAccountSettings('/accountVehicles')}>
                                    {vehicles.length > 0 ? vehicles[0].plateNum : '---'}
                                </Typography>
                            </Card>
                        </Box>
                    </Card>
                    {parkingLog && (<Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3, mb: 1 }}>
                        <Grid container >
                            <Grid item xs={2}>
                                <img src={icon_eur} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }} >
                                <Typography variant="h3" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                    {t('publicApp.ParkingRate')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container sx={{ pb: 1, pt: 1 }}>
                            <Grid item xs={6}>
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1', mr: 1 }}>
                                        <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1, fontWeight: 500, color: '#3268B2' }}>
                                            {t('publicApp.Hourly')}
                                        </Typography>
                                        <Typography variant="h2" gutterBottom sx={{ pl: 1, fontWeight: 800, color: '#3268B2' }}>
                                            {parkingLog.parkingRateHourly + ' ' + lot.currencyShort}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>
                            <Grid item xs={6} >
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1', ml: 1 }}>
                                        <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1, fontWeight: 500, color: '#3268B2' }}>
                                            {t('publicApp.Daily')}
                                        </Typography>
                                        <Typography variant="h2" gutterBottom sx={{ pl: 1, fontWeight: 800, color: '#3268B2' }}>
                                            {parkingLog.parkingRateDaily + ' ' + lot.currencyShort}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>)}
                    {parkingLog && (<Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3, mb: 1 }}>
                        <Grid container >
                            <Grid item xs={2}>
                                <img src={icon_time} style={{ width: 50 }} />
                            </Grid>
                            <Grid item xs={10} sx={{ pt: 1 }} >
                                <Typography variant="h3" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                    {t('publicApp.ParkingTime')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container justifyContent="center" sx={{ pb: 1, pt: 1 }}>
                            <Grid item xs={3}>
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}>
                                    <Typography gutterBottom sx={{ fontWeight: 500, color: '#3268B2' }}>
                                        {t('publicApp.Days')}
                                    </Typography>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1' }}>
                                        <Typography gutterBottom className='parking-time-info'>
                                            {days}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>
                            <Grid item >
                                <Typography variant="h5" gutterBottom sx={{ pt: 1, fontWeight: 500 }}>
                                </Typography>
                                <Typography gutterBottom className='parking-time-info'>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={3} >
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}>
                                    <Typography gutterBottom sx={{ fontWeight: 500, color: '#3268B2' }}>
                                        {t('publicApp.Hours')}
                                    </Typography>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1' }}>
                                        <Typography gutterBottom className='parking-time-info'>
                                            {hours}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>
                            <Grid item >
                                <Typography variant="h5" gutterBottom sx={{ pt: 1, fontWeight: 500 }}>
                                </Typography>
                                <Typography gutterBottom className='parking-time-info'>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={3} >
                                <Card sx={{ boxShadow: 0, textAlign: 'center' }}>
                                    <Typography gutterBottom sx={{ fontWeight: 500, color: '#3268B2' }}>
                                        {t('publicApp.Minutes')}
                                    </Typography>
                                    <Card sx={{ boxShadow: 0, textAlign: 'center', background: '#f0f2f1' }}>
                                        <Typography gutterBottom className='parking-time-info'>
                                            {minutes}
                                        </Typography>
                                    </Card>
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>)}
                </Box>

                <Container sx={{ minHeight: '150px' }}>
                </Container>
                <Container className='parking-footer-white' sx={{ touchAction: 'none', boxShadow: 12 }}  >
                    <Box sx={{ pb: 5, pt: 5, textAlign: '-webkit-center' }}>
                        <Card className='parking-footer-button'>

                            <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1.9, fontWeight: 500, color: '#D69F97', flexDirection: 'row' }} >
                                {t('publicApp.GoPayment') + '   >>'}
                            </Typography>
                        </Card>
                    </Box>
                    <Slider onChange={this.goPayment}
                        disabled={!parkingLog}
                        sx={{
                            position: "fixed",
                            bottom: '45px',
                            width: '60%',
                            left: '20%',
                            pointerEvents: 'none !important',
                            '& .MuiSlider-rail': {
                                display: 'none'
                            },
                            '& .MuiSlider-track': {
                                display: 'none'
                            },
                            '& .MuiSlider-thumb': {
                                pointerEvents: 'all !important',
                                backgroundColor: '#FF7D6B',
                                borderRadius: '12px',
                                width: 64,
                                height: 64,
                                border: '2px outset #FFE2DC',
                                boxShadow: '0px 2px 1px  rgba(255,125,107,0.2), 0px 2px 1px rgba(255,125,107,0.14), 0px 2px 1px rgba(255,125,107,0.12)',
                                '&:before': {
                                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" viewBox="-17 -13 60 60"><path d="M14.5859 23.4219H6.10156V17.0703H14.5859C15.7422 17.0703 16.6797 16.875 17.3984 16.4844C18.1172 16.0938 18.6406 15.5547 18.9688 14.8672C19.3125 14.1641 19.4844 13.375 19.4844 12.5C19.4844 11.5625 19.3125 10.6953 18.9688 9.89844C18.6406 9.10156 18.1172 8.46094 17.3984 7.97656C16.6797 7.47656 15.7422 7.22656 14.5859 7.22656H9.19531V35H0.96875V0.875H14.5859C17.3203 0.875 19.6719 1.375 21.6406 2.375C23.625 3.375 25.1484 4.75 26.2109 6.5C27.2734 8.23438 27.8047 10.2188 27.8047 12.4531C27.8047 14.6562 27.2734 16.5781 26.2109 18.2188C25.1484 19.8594 23.625 21.1406 21.6406 22.0625C19.6719 22.9688 17.3203 23.4219 14.5859 23.4219Z" fill="white"/></svg>')`,
                                },
                                '&.Mui-active': {
                                    boxShadow: 'none'
                                },
                            },
                        }} />
                </Container>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    className='public-dialog'
                >
                    <Container className='map-header-blue' sx={{ position: 'fixed' }}>
                        <Grid container sx={{ mb: 2, pt: 1 }}>
                            <Grid item xs={1}>
                                <IconButton sx={{ mt: 1, color: 'white' }} onClick={this.handleClose}>
                                    <FontAwesomeIcon icon={faChevronLeft} size="1x" sx={{ fontWeight: 400 }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="h3" gutterBottom sx={{ mt: 2, ml: 1, fontWeight: 400, color: 'white' }}>
                                    {t('publicApp.Explore')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <TextField
                            id="name"
                            onChange={this.handleCard}
                            className="inputLots"
                            placeholder={t('publicApp.FindParkingLot')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <img src={icon_search_active} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Container>
                    <div style={{ top: 125, position: 'relative', overflow: 'auto' }}>
                        <Card sx={{ p: 1, mt: 1, ml: 2, mr: 2, boxShadow: 3, mb: 1 }} className='border-card'>
                            <List>
                                {lots.map((el, i) => (
                                    el.name.toLowerCase().includes(searchLots.toLowerCase()) || searchLots === '' ?
                                        <span key={i}>
                                            <ListItem button onClick={() => this.onPlaceChanged(el)} >
                                                <Typography variant="h4" gutterBottom sx={{ pl: 1, pt: 1 }}>
                                                    {el.name}
                                                </Typography>
                                            </ListItem>
                                            <Divider />
                                        </span> : null
                                ))}
                            </List>
                        </Card>
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(withTranslation()(PublicParking));