import React, { Component, useEffect } from 'react';
import './App.scss';
import config from '../../config';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack'



import { Helmet } from "react-helmet";
import { NotificationContainer } from 'react-notifications';

//import Login from '../auth/login/Login';
import Users from '../users/Users';
import Reports from '../reports/Reports';
import Spaces from '../spaces/Spaces';
import Sidenav from '../ui/sidenav/Sidenav';
import Exchanges from '../exchanges/Exchanges';
import ExchangesHistory from '../exchanges/ExchangesHistory';
import UserDetails from '../users/details/UserDetails';
import UserForm from '../users/form/UserForm';
import VehicleForm from '../vehicles/form/VehicleForm';
import SpaceAssign from '../spaces/assign/SpaceAssign';
import SpaceDetails from '../spaces/details/SpaceDetails';
import UsersAssign from '../users/assign/UsersAssign';
import ActiveHistory from "../history/ActiveHistory";
import MonthlyReports from "../reports/monthlyReports/MonthlyReports";
import ExchangeSpaceForm from '../exchanges/space-form/ExchangeSpaceForm';
import { AuthProvider, AuthConsumer } from '../../providers/AccountProvider';
import BookingSpaceForm from '../exchanges/booking-form/BookingSpaceForm';
import Axios from 'axios';
import Administration from '../administration/Administration';
import Territory from '../administration/territory/Territory';
import Lot from '../administration/lot/Lot';
import TerritoryForm from '../administration/territory-form/TerritoryForm';
import LotForm from '../administration/lot-form/LotForm';
import TerritoryUserForm from '../administration/territory-user-form/TerritoryUserForm';
import SpaceForm from '../administration/space-form/SpaceForm';
import ImportData from '../administration/import-data/ImportData';
import SilencePeriodForm from '../silenceperiods/form/SilencePeriodForm';
import Dashboard from '../dashboard/Dashboard';
import SettingsView from 'components/settings/settings-view/SettingsView';
import Consent from '../consent/Consent';
import ConsentForm from '../consent/form/ConsentForm';
import MonthlyReport from '../reports/monthly/MonthlyReport';
import ActionReport from '../reports/action/ActionReport';
import Translations from 'components/translations/Translations';
import SpaceReassign from '../users/spaces/reassign/SpaceReassign';
import SendReportDetails from '../reports/monthlyReports/SendReportDetails';
import AvailableSites from 'components/availablesites/AvailableSites';
import ParkingRates from '../rates/ParkingRates';
import ParkingRatesForm from '../rates/ParkingRatesForm';
import themes from '../../themes';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';

import { CssBaseline, StyledEngineProvider } from '@mui/material';


import { ThemeProvider } from '@mui/material/styles';
//navigateimport Routes from '../../routes';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import NavigationScroll from '../../layout/NavigationScroll';
import Locales from '../../ui-component/Locales';
import LoginMain from '../auth/authentication/authentication3/LoginMain';
import UserSites from '../availablesites/UserSites';
import AuthGuard from "../../utils/route-guard/AuthGuard";
import GuestGuard from "../../utils/route-guard/GuestGuard";
import ParkingGroups from 'components/groups/ParkingGroups';
import ParkingGroupForm from 'components/groups/ParkingGroupForm';
import GroupUsers from 'components/groups/GroupUsers';
import GroupUsersAdd from 'components/groups/GroupUsersAdd';
import CardForm from 'components/users/payment/CardForm';
import PublicMap from 'components/exchanges/public-booking/PublicMap';
import PublicSettings from 'components/exchanges/public-booking/PublicSettings';
import PublicPayment from 'components/exchanges/public-booking/PublicPayment';
import PaymentConfirm from 'components/exchanges/public-booking/PaymentConfirm';
import AccountDetails from 'components/public/settings/AccountDetails';
import AccountVehicles from 'components/public/settings/AccountVehicles';
import AccountLanguages from 'components/public/settings/AccountLanguages';
import PublicParking from 'components/public/PublicParking';

const routes = [
  {
    path: "/",
    public: true,
    exact: true,
    main: () => <Redirect to={{
      pathname: '/spaces',
      state: { referrer: '/spaces' }
    }
    } />
    // main: () => <Redirect to={{
    //   pathname: '/dashboard',
    //   state: { referrer: '/dashboard' }
    //   }
    // } />
  },
  {
    path: "/login",
    public: true,
    main: LoginMain
  },
  {
    path: "/users",
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Users
  },
  {
    path: "/users/assing",
    sidebar: Sidenav,
    onlyAdmin: true,
    main: UsersAssign
  },
  {
    path: "/user/:id",
    sidebar: Sidenav,
    exact: true,
    main: UserDetails
  },
  {
    path: "/user/:id/edit",
    sidebar: Sidenav,
    main: UserForm
  },
  {
    path: "/vehicles/new",
    sidebar: Sidenav,
    main: VehicleForm
  },
  {
    path: "/activehistory",
    sidebar: Sidenav,
    main: ActiveHistory
  },
  {
    path: "/monthly_reports",
    sidebar: Sidenav,
    main: MonthlyReports
  },
  {
    path: "/reports",
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Reports,
    exact: true
  },
  {
    path: "/exchanges",
    sidebar: Sidenav,
    exact: true,
    main: Exchanges
  },
  {
    path: "/history/exchanges",
    sidebar: Sidenav,
    exact: true,
    main: ExchangesHistory
  },
  {
    path: "/exchanges/:action",
    sidebar: Sidenav,
    main: ExchangeSpaceForm
  },
  {
    path: "/bookings/:action",
    sidebar: Sidenav,
    main: BookingSpaceForm
  },
  {
    path: "/spaces",
    exact: true,
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Spaces
  },
  {
    path: "/spaces/assing",
    sidebar: Sidenav,
    onlyAdmin: true,
    main: SpaceAssign
  },
  {
    path: "/space/:id",
    sidebar: Sidenav,
    onlyAdmin: true,
    exact: true,
    main: SpaceDetails
  },
  {
    path: "/administration",
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Administration
  },
  {
    path: "/territory",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: Territory
  },
  {
    path: "/parkingRates",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingRates
  },
  {
    path: "/parkingRates/form",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingRatesForm
  },
  {
    path: "/territory/form",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: TerritoryForm
  },
  {
    path: "/lot",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: Lot
  },
  {
    path: "/lot/form",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: LotForm
  },
  {
    path: "/territoryUser/form",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: TerritoryUserForm
  },
  {
    path: "/lotSpace/form",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: SpaceForm
  },
  {
    path: "/import",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ImportData
  },
  {
    path: "/silenceperiods/:action",
    sidebar: Sidenav,
    main: SilencePeriodForm
  },
  {
    path: "/subscriptions/:action",
    sidebar: Sidenav,
    main: SilencePeriodForm
  },
  {
    path: "/dashboard",
    sidebar: Sidenav,
    main: Dashboard
  },
  {
    path: "/settings",
    sidebar: Sidenav,
    exact: true,
    main: SettingsView
  },
  {
    path: "/consent",
    sidebar: Sidenav,
    main: Consent,
    exact: true
  },
  {
    path: "/consent/edit",
    sidebar: Sidenav,
    main: ConsentForm,
    exact: true
  },
  {
    path: "/reports/monthly",
    onlyAdmin: true,
    sidebar: Sidenav,
    main: MonthlyReport,
    exact: true,
  },
  {
    path: "/reports/sendReport",
    sidebar: Sidenav,
    main: SendReportDetails,
    exact: true,
  },
  {
    path: "/reports/action",
    onlyAdmin: true,
    sidebar: Sidenav,
    main: ActionReport,
    exact: true,
  },
  {
    path: '/translations',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: Translations,
    exact: true,
  },
  {
    path: '/availablesites',
    onlyAdmin: true,
    sidebar: Sidenav,
    main: AvailableSites,
    exact: true,
  },
  {
    path: "/spaces/reassign",
    sidebar: Sidenav,
    onlyAdmin: true,
    main: SpaceReassign,
    exact: true
  },
  {
    path: '/usersites',
    // element: <UserSites />,
    public: true,
    main: UserSites,
    //  exact: true
  },
  {
    path: "/parkingGroups",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingGroups
  },
  {
    path: "/parkingGroup/form",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: ParkingGroupForm
  },
  {
    path: "/groupUsers",
    onlyAdmin: true,
    sidebar: Sidenav,
    exact: true,
    main: GroupUsers
  },
  {
    path: "/groupUsersAdd",
    onlyAdmin: true,
    sidebar: Sidenav,
    main: GroupUsersAdd
  },
  {
    path: "/card",
    sidebar: Sidenav,
    main: CardForm
  },
  {
    path: "/publicMap",
    sidebar: Sidenav,
    main: PublicMap
  },
  {
    path: "/publicSettings",
    sidebar: Sidenav,
    main: PublicSettings
  },
  {
    path: "/publicPayment",
    sidebar: Sidenav,
    main: PublicPayment
  },
  {
    path: "/paymentConfirm",
    sidebar: Sidenav,
    main: PaymentConfirm,
    public: true
  },
  {
    path: "/accountDetails",
    sidebar: Sidenav,
    main: AccountDetails
  },
  {
    path: "/accountVehicles",
    sidebar: Sidenav,
    main: AccountVehicles
  },
  {
    path: "/accountLanguages",
    sidebar: Sidenav,
    main: AccountLanguages
  },
  {
    path: "/publicParking",
    sidebar: Sidenav,
    main: PublicParking
  },
];
function PrivateRoute({ component: Component, ...rest }) {

  return (

    <AuthGuard>
      <AuthConsumer>
        {({ isAuth, currentUser, logout, isAdmin, toggleAdmin, settings, haveConsent, setConsent }) => (

          <Route
            {...rest}
            render={props =>
              isAuth ? (
                <Component
                  {...props}
                  timestamp={new Date().toString()}
                  settings={settings}
                  currentUser={currentUser}
                  logout={logout}
                  isAdmin={isAdmin}
                  toggleAdmin={toggleAdmin}
                  haveConsent={haveConsent}
                  setConsent={setConsent}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location }
                  }}
                />
              )
            }
          />
        )}</AuthConsumer>
    </AuthGuard>

  );
}
const customization = {
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  outlinedFilled: config.outlinedFilled,
  navType: config.theme,
  presetColor: config.presetColor,
  rtlLayout: config.rtlLayout
};
class App extends Component {
  constructor(props) {
    super()
    if (window.performance) {
      if (performance.navigation.type == 1) {
        const exList = ['/', '/login']
        if (!exList.includes(window.location.pathname))
          localStorage.setItem('currentPath', window.location.pathname)
      } else {
        const exList = ['/', '/login']
        if (!exList.includes(window.location.pathname)) localStorage.setItem('currentPath', window.location.pathname)

      }
    }
  }

  getApiBaseUrl = async () => {
    if (!window.title) {
      const { data } = await Axios.get('./config.json')
      window.title = `${process.env.NODE_ENV === 'production' ? data.TITLE : 'Parkshare'}`
    }
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <Router >
            <NotificationContainer />
            <Helmet>
              <title>{window.title}</title>
            </Helmet>
            <Locales>
              <NavigationScroll>
                <FirebaseProvider>
                  <AuthProvider>
                    <SnackbarProvider maxSnack={3} >
                      {routes.map((route, index) => (
                        route.public ?
                          <GuestGuard>
                            <AuthConsumer
                              key={index}
                            >{({ login }) => (
                              <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => <route.main {...props} login={login} />}
                              />)}</AuthConsumer></GuestGuard>
                          :

                          <AuthConsumer
                            key={index}
                          >{({ isAuth, isAdmin }) => (
                            (route.onlyAdmin && isAdmin) || !route.onlyAdmin ?
                              <PrivateRoute
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                isAuth={isAuth}
                                component={route.main}
                              />
                              : null
                          )}</AuthConsumer>

                      ))}
                      {routes.map((route, index) => (
                        !route.public &&
                        <PrivateRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.sidebar}
                        />
                      ))}

                    </SnackbarProvider>
                    {/*<SnackbarProvider maxSnack={3} >*/}
                    {/*  <Routes />*/}

                    {/*</SnackbarProvider>*/}
                  </AuthProvider>

                </FirebaseProvider>
              </NavigationScroll>
            </Locales>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
