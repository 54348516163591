import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import './SilencePeriodForm.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import BottomBar from '../../ui/bottom-bar/BottomBar';
import InputDate from '../../ui/inputs/date/InputDate';
import { withTranslation } from 'react-i18next';
import { Grid, Checkbox, Typography } from '@mui/material';
import { withSnackbar } from 'notistack';
import { checkForBookedSpaces, postSilencePeriod } from '../../../utils/SilencePeriodsService';
import SilencePeriodFormHead from '../head/SilencePeriodFormHead';

import MomentUtils from '@date-io/moment';
import { putSharedSpaceRequest, deleteSharedSpaceRequest, requestSharedSpace } from '../../../utils/SharedSpaceRequestService';
import { resolveEndOfDay, resolveStartOfDay } from 'utils/DateTimeResolver';
import DeleteDialog from 'components/ui/dialogs/DeleteDialog';
import { featureEnabled } from 'utils/FeatureTogglerService';
import { SILENCE_CLIPS_BOOKINGS } from 'constants/Features';

function SilencePeriodForm({ t, enqueueSnackbar }) {
  const [currentLng, setCurrentLng] = useState(
    localStorage.getItem('currentLng')
  );

  const moment = new MomentUtils({ locale: currentLng });

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const setStartOfDay = (date) => {
    if (date) {
      return moment.date(resolveStartOfDay(date));
    }
    return moment.date(new Date()).utc(0).startOf('day');
  };

  const setEndOfDay = (date) => {
    if (date) {
      const date_ = moment.date(resolveEndOfDay(date));
      return date_;
    }
    return moment.date(new Date()).utc(0).endOf('day');
  };

  const setFrom = (state) => {
    const from_ = state && state.item ? state.item.from : null;
    return setStartOfDay(from_);
  };

  const setTo = (state) => {
    const to_ = state && state.item != null ? state.item.to : null;
    return setEndOfDay(to_);
  };

  const setMin = (state) => {
    const min_ = state ? state.minDate : null;
    return setStartOfDay(min_);
  };

  const setMax = (state) => {
    const max_ = state ? state.maxDate : null;
    return max_ ? setEndOfDay(max_) : null;
  };

  const [from, setFromDate] = useState(setFrom(location.state));
  const [to, setToDate] = useState(setTo(location.state));
  const [formTitle, setFormTitle] = useState('subscriptions.SilencePeriod');

  useEffect(() => {
    if (isNew) {
      if (location.state && location.state.subscribe) {
        setFormTitle('subscriptions.Request');
      } else {
        setFormTitle('subscriptions.SilencePeriod');
      }
    } else {
      if (location.state && location.state.subscribe) {
        setFormTitle('subscriptions.EditRequest');
      }
    }

    setCurrentLng(localStorage.getItem('currentLng'));
  }, [location.state]);

  const createSilencePeriod = async (body) => {
    await postSilencePeriod(body);
    enqueueSnackbar(t('notification.success.SilencePeriodCreated'), {
      variant: 'success',
    });
  };
  
  const checkSilenceOverlayFeature = async () => {
    setSilenceOverlayFeatureEnabled(await featureEnabled(SILENCE_CLIPS_BOOKINGS));
  }

  const [minDate, setMinDate] = useState(setMin(location.state));
  const [maxDate, setMaxDate] = useState(setMax(location.state));
  const [isNew, setIsNew] = useState(params.action === 'new');
  const [sharedDates, setSharedDates] = useState(location.state ? location.state.sharedDates : null);
  //   const [disabledForEnd, setDisabledForEnd] = useState(location.state.disabledForEnd || null);
  //   const [disabledForStart, setDisabledForStart] = useState(location.state.disabledForStart || null);
  const [disabledForEnd, setDisabledForEnd] = useState(null);
  const [disabledForStart, setDisabledForStart] = useState(null);
  const [submitTitle, setSubmitTitle] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [body, setBody] = useState();
  const [silenceOverlayFeatureEnabled, setSilenceOverlayFeatureEnabled] = useState('false');

  const handleAfterConfirmDialog = () => {
    createSilencePeriod(body);
    setOpenConfirmationDialog(false);
    goBack();
  };

  useEffect(() => {
    setBody({
      // id: location.state && location.state.item ? location.state.item.id : null,
      from: from.format('YYYY-MM-DDT00:00:00.000').toString(),
      to: to.format('YYYY-MM-DDT23:59:59.999').toString(),
      userId: location.state && location.state.user ? location.state.user.id : ''
    });
  }, [from, to]);

  useEffect(() => {
    checkSilenceOverlayFeature();
  }, []);

  const onClose = () => {
    setOpenConfirmationDialog(false);
  }

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log({silenceOverlayFeatureEnabled});

    try {
      if (isNew) {
        //console.log(isNew)
        if (location.state.subscribe) {
          await requestSharedSpace(from, to, body.userId);
          enqueueSnackbar(t('notification.success.SubscriptionCreated'), {
            variant: 'success',
          });
          goBack();
        } else {
          if (silenceOverlayFeatureEnabled) {
            await checkForBookedSpaces(body.userId, body.from, body.to).then(
              (response) => {
                if (response) {
                  setOpenConfirmationDialog(true);
                } else {
                  createSilencePeriod(body);
                  goBack();
                }
              }
            );
          } else {
            createSilencePeriod(body);
            goBack();
          }
        }
      } else {
        if (submitTitle) {
          const { affectedIds } = await deleteSharedSpaceRequest(location.state.item.id);

          if (affectedIds && affectedIds.length) {
            enqueueSnackbar(
              t('notification.success.DeleteSharedSpaceRequest'),
              {
                variant: 'success',
              }
            );
          }
        } else {
          await putSharedSpaceRequest(body, location.state.item.id);
          enqueueSnackbar(t('notification.success.SharedSpaceRequestUpdated'), {
            variant: 'success',
          });
          goBack();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSetFromDate = (date) => {
    setFromDate(date);
    if (moment.isAfter(date, to)) {
      setToDate(date);
    }
  };

  const onChangeSetToDate = (date) => {
    setToDate(date);
    if (moment.isBefore(date, from)) {
      setFromDate(date);
    }
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setSubmitTitle(t("general.Delete"));
    } else {
      setSubmitTitle(null);
    }
  };

  return (
    <React.Fragment>
      <div className="ExchageContainer">
        <Topbar title={t(formTitle)} location={location} cbBack={goBack}>
        </Topbar>
        <SilencePeriodFormHead
          isSilencePeriod={location.state?.subscribe ?? true}
        />
        <form
          className="SilencePeriodFormFormContainer"
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid container spacing={1}>
            {isNew && (
              <Grid item xs={6}>
                <InputDate
                  value={from}
                  label={t('exchanges.Begining')}
                  id="from"
                  excludesList={sharedDates}
                  onChange={(momentDate) => onChangeSetFromDate(momentDate)}
                  currentLng={currentLng}
                />
              </Grid>
            )}
            {!isNew && maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={from}
                  label={t('exchanges.Begining')}
                  id="from"
                  minDate={minDate}
                  maxDate={maxDate}
                  // excludesList={disabledForStart}
                  onChange={(momentDate) => onChangeSetFromDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}
            {!isNew && !maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={from}
                  label={t('exchanges.Begining')}
                  id="from"
                  minDate={minDate}
                  // excludesList={disabledForStart}
                  onChange={(momentDate) => onChangeSetFromDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}
            {isNew && (
              <Grid item xs={6}>
                <InputDate
                  value={to}
                  label={t('exchanges.End')}
                  minDate={from}
                  id="to"
                  excludesList={sharedDates}
                  onChange={(momentDate) => onChangeSetToDate(momentDate)}
                  currentLng={currentLng}
                />
              </Grid>
            )}
            {!isNew && maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={to}
                  label={t('exchanges.End')}
                  minDate={minDate}
                  maxDate={maxDate}
                  id="to"
                  // excludesList={disabledForEnd}
                  onChange={(momentDate) => onChangeSetToDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}
            {!isNew && !maxDate && (
              <Grid item xs={6}>
                <InputDate
                  value={to}
                  label={t('exchanges.End')}
                  minDate={minDate}
                  id="to"
                  // excludesList={disabledForEnd}
                  onChange={(momentDate) => onChangeSetToDate(momentDate)}
                  currentLng={currentLng}
                  readOnly={submitTitle != null}
                />
              </Grid>
            )}

            {!isNew && location.state?.subscribe && (
              <Grid item xs={12} className="CheckBoxContainer">
                <Checkbox
                  className="CheckBoxComponent"
                  //  checked={isDeleteAction}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography>
                  {t('subscriptions.DeleteSharedSpaceRequest')}
                </Typography>
              </Grid>
            )}

            {/* <Grid item xs={12} className="SharedWithContainer">
              {isAdmin && user && (
                <UserListItem user={user} className="UserCard" />
              )}
            </Grid> */}
          </Grid>
          {silenceOverlayFeatureEnabled && openConfirmationDialog && (
            <DeleteDialog
              openIt={openConfirmationDialog}
              onConfirm={handleAfterConfirmDialog}
              onClose={onClose}
              text={'confirm.deleteBookedSpaces'}
            />
          )}
          <BottomBar onGoBackFn={goBack} submitTitle={submitTitle}></BottomBar>
        </form>
      </div>
    </React.Fragment>
  );
}

export default withSnackbar(withTranslation()(SilencePeriodForm));
