import React, {Component} from 'react';
import './UsersAssign.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { List, Grid } from '@mui/material';
import { InputSearch } from '../../ui/inputs/search/Search';
import UserListItem from '../list/item/UserListItem';
import { getUsers } from '../../../utils/UsersService';



class UsersAssign extends Component {
  state = {
    users: [],
  }
  fetchUsers = async () => {
    try {
      const {items} = await getUsers()
      this.setState({users: [...items]})
    }catch (e) {
      console.error(e)
    }
  }
  assignUser = async (user) => {
    // setParkingOwner(space, this.state.userId)
  }
  componentDidMount = async () => {
      await this.fetchUsers()
  }

  render() {
    const {users} = this.state;
    const {t} = this.props;
    return (
      <React.Fragment>
        <Topbar title={t('users.Title')} location={this.props.location} >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
        <InputSearch onChange={this.search} placeholder={t("general.Search")} />
        </Grid>
        </Topbar>
        <List className="usersContainer">
          {users && users.map((user, i) => (
            <UserListItem user={user} onSelect={() => this.assignUser(user)} key={i} />
          ))}

        </List>
      </React.Fragment>

    );
  }
}
export default withTranslation()(UsersAssign)