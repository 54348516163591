import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Grid, IconButton, Typography, Radio, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './Settings.scss';
import { updateSettingsLocale} from '../../../utils/SettingsService';
import i18n from '../../../utils/i18n'

class AccountLanguages extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            currentLng: "en-gb",
        }
    }

    componentDidMount = async () => {
        const user = this.props.currentUser;
        this.setState({ user: user })
        const currentLng = localStorage.getItem('currentLng');
        if(currentLng){
            this.setState({currentLng: currentLng});
        }
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = async(event) => {
        this.setState({ currentLng: event.target.value });
        const lng = event.target.value;
        localStorage.setItem('currentLng', lng)
        const l = lng.toLowerCase()
        await updateSettingsLocale(l, this.state.user.id);
        await i18n.changeLanguage(l);
    };

    clickLanguage = async(l) => {
        this.setState({ currentLng: l });
        localStorage.setItem('currentLng', l)
        await updateSettingsLocale(l, this.state.user.id);
        await i18n.changeLanguage(l);
    }

    render() {
        const { currentLng } = this.state;
        const { t } = this.props;
        return (
            <div  className='public-form'>

                <Grid container sx={{ mb: 2, mt: 1, ml: 2 }}>
                    <Grid item xs={1}>
                        <IconButton sx={{ mt: 1 }} onClick={this.goBack}>
                            <FontAwesomeIcon icon={faChevronLeft} size="1x" sx={{ fontWeight: 400 }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h3" gutterBottom sx={{ mt: 2, ml: 1, fontWeight: 400 }}>
                            {t('publicApp.Language')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ m: 2 }}>
                    <Grid container sx={{ m: 2 }}>
                        <Grid item xs={9}>
                            <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500 }} onClick={() => this.clickLanguage('en-gb')}>
                                {t('publicApp.English')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Radio value="en-gb" onChange={this.handleChange} checked={currentLng.toLowerCase() === 'en-gb'} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ m: 2 }}>
                        <Grid item xs={9}>
                            <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500 }}  onClick={() => this.clickLanguage('lt-lt')}>
                                {t('publicApp.Lithuanian')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Radio value="lt-lt" onChange={this.handleChange} checked={currentLng.toLowerCase() === 'lt-lt'} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ m: 2 }}>
                        <Grid item xs={9}>
                            <Typography gutterBottom variant="h4" sx={{ pl: 1, pt: 1, fontWeight: 500 }}  onClick={() => this.clickLanguage('pl-pl')}>
                                {t('publicApp.Polish')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Radio value="pl-pl" onChange={ this.handleChange} checked={currentLng.toLowerCase() === 'pl-pl'} />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }
}

export default withSnackbar(withTranslation()(AccountLanguages));