import React, { Component } from 'react';
import { Container, IconButton, Grid } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export class PublicFooter extends Component {

    render() {
        const { path, goPublic } = this.props;
        return (
            <Container className='map-footer-blue' sx={{touchAction: 'none'}}>
                <Grid container sx={{ textAlign: 'center' }}>
                    <Grid item xs={4}  >
                        <IconButton
                            sx={{ mt: 1, mb: 1 }}
                            className={path === 'map' ? 'icon_white' : 'icon_grey'}
                            onClick={() => goPublic('/publicMap')}
                        >
                            <RoomIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            sx={{ mt: 1 }}
                            className={path === 'parking' ? 'icon_white' : 'icon_grey'}
                            onClick={() => goPublic('/publicParking')}
                        >
                            <ConfirmationNumberOutlinedIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            sx={{ mt: 1 }}
                            className={path === 'settings' ? 'icon_white' : 'icon_grey'}
                            onClick={() => goPublic('/publicSettings')}
                        >
                            <PersonOutlineOutlinedIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}