import React from 'react';
// import {Link} from 'react-router-dom';
import './InputDate.scss';

// import { Clock } from '@material-ui/pickers'
import i18next from 'i18next';

import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import "moment/locale/lt";
import "moment/locale/en-gb";
import { makeStyles } from '@material-ui/styles';
import { InputText } from '../text/Text';
const momentUtils = new MomentUtils();

const useStyles = makeStyles({
  formControl: {
    borderBottom: 'none',
    "&:before": {
      borderBottom: 'none'
    },
    "&:after": {
      borderBottom: 'none'
    }
  },
  input: {
    height: '40px',
   // width: '100%',
    margin: '1em 0',
    display: 'flex',
    fontWeight: 500,
    color: '#656D78',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid ',
    backgroundColor: '',
    boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.1)',
    "&:before": {
      borderBottom: 'none'
    },
    "&:after": {
      borderBottom: 'none'
    }
  },
});

function checkDates(date, dates) {
  const startDate = momentUtils.date(dates.from)
  const endDate = momentUtils.date(dates.to)
  if (date.isSameOrBefore(endDate) && date.isSameOrAfter(startDate)) {
    return true;
  } else {
    return false;
  }
}

function disableDates(date, dates, value, disableWeekend) {
  if (date.isSame(value, 'day')) return
  if (disableWeekend && (date.day() === 0 || date.day() === 6)) return true
  if (!dates) return
  return dates.some(element => {
    return checkDates(date, element)
  });
}

export default function InputDate(props) {
//  const classes = useStyles();
  const {
    placeholder, 
    onChange, 
    onClick, 
    id, 
    label, 
    type, 
    value, 
    onBlur, 
    validator, 
    minDate, 
    maxDate, 
    excludesList, 
    allowPast, 
    disableWeekend, 
    currentLng, 
    readOnly
  } = props;
  const currentLng_ = currentLng != null ? currentLng : "lt-LT";
  moment.locale(currentLng_);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLng_} libInstance={moment}>
      <DatePicker
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        cancelLabel={i18next.t('general.Cancel')}
        okLabel={i18next.t('general.Save')}
        minutesStep={5}
        emptyLabel={''}
        onClick={onClick}
        disablePast={allowPast ? false : true}
        format='L'
        shouldDisableDate={(date) =>
          disableDates(date, excludesList, value, disableWeekend)
        }
        TextFieldComponent={(props) => <InputText
          {...props}
          placeholder={placeholder}
          id={id}
          label={label}
          type={type}
          onBlur={onBlur}
          validator={validator}
        />}
        ampm={false}
    //    className={classes}
        disableUnderline={true}
        variant="outlined"
        disabled={readOnly}
      />
    </MuiPickersUtilsProvider>
  )
}