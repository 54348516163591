import React, { Component } from 'react';
import { Topbar } from '../../ui/topbar/Topbar';
import './MonthlyReports.scss';
import { withTranslation } from "react-i18next";
import MomentUtils from '@date-io/moment';
import {
    Grid,
    Fab,
    Box,
    Dialog,
    Typography,
    DialogContentText, DialogActions, Button, InputLabel, DialogTitle, FormControlLabel, Switch, List
} from '@mui/material';
import SendReportDetails from "./SendReportDetails";

const thisLang = localStorage.currentLng;
const moment = new MomentUtils({ locale: thisLang.slice(0, 2) });


class MonthlyReports extends Component {
    state = {
        from: moment.date().utc(0).startOf('day'),
        to: moment.date().utc(0).endOf('day'),
        date: moment.date().utc(0).format(' YYYY-MMMM'),
        //  month:  moment.substract (1, 'month').date(1).utc(0).format(' YYYY-MM-DD'),
        today: moment.date().utc(0),
        // let_from: .utc(0).format('YYYY-MM-DDT00:00:00.000').toString(),
        // let_to: moment.date().utc(0).subtract(1, 'day').format('YYYY-MM-DDT23:59:59.999').toString()

        open: false,
        openDialog: this.props.location.open
    }

    // <MonthlyReport userId={this.state.user.id} hideTitle={true} isCurrentUser={isCurrentUser}></MonthlyReport>


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };



    goBack = () => {
        this.props.history.goBack()
    }

    sendReport = () => {

        this.handleClickOpen();

        //         const { userId, isCurrentUser ,hideTitle } = this.props.location;
        // console.log(this.props.location );
        //       //  const userId = user.id;
        //         this.props.history.push({
        //             pathname: '/reports/sendReport',
        //             state: { userId: userId,  hideTitle: hideTitle , isCurrentUser: isCurrentUser, }
        //         });
    };
    getMonth = (number) => {
        const moments = new MomentUtils({ locale: localStorage.currentLng.slice(0, 2) });
        // let today = this.state.today;
        const month = moments.date().utc(0).subtract(number, 'month').format(' YYYY MMMM');
        return (month)
    }

    dataFrom = (number) => {
        let today = this.state.today;
        const startOfMonth = today.utc(0).subtract(1, 'month').startOf('month').format('YYYY-MM-DDT00:00:00.000').toString();
        return (startOfMonth)
    }

    dataTo = () => {
        let today = this.state.today;
        const endOfMonth = today.utc(0).subtract(1, 'month').endOf('month').format('YYYY-MM-DDT23:59:59.999').toString();
        return (endOfMonth)
    }



    item = (number) => {
        //  console.log ('this lang', thisLang );
        let month = this.getMonth(number);
        return (
            <Grid item xs={12} sx={{ background: '#fffff', borderRadius: '10px', border: '1px solid #3A4D8F' }}>
                <Grid xs container >
                    <Grid item xs sx={{ px: 2, py: 1.5 }} >
                        <Box sx={{ p: 0.5 }}>
                            <Typography className ='h2' >
                                {`${month} `}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sx={{ py: 1.5, px: 2 }} >
                        <Box sx={{ px: 0.5, py: 0.5 }}>
                            <Typography className ='h2' color='#3A4D8F'>
                                0 EUR
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        )
    }



    render() {
        const { today, open, } = this.state;
        const test = thisLang;
        const { t, user } = this.props;
        const let_from = this.state.today.format('YYYY-MM-DDT00:00:00.000').toString();
        const let_to = today.subtract(1, 'day').format('YYYY-MM-DDT23:59:59.999').toString();
        const from = this.dataFrom();
        // const monthi = this.getMonth(1);
        const { userId, isCurrentUser, hideTitle } = this.props.location;
        console.log('start', this.props.location);
        return (
            <React.Fragment>

                <Dialog
                    // fullScreen
                    open={open}
                    onClose={this.handleClose}
                // onClose={this.handleCloseSpaceDialog}
                >
                    <div >

                        <Grid
                            container
                            //  marginTop="50px"
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography className="h3">{t("reports.Monthly")}</Typography>
                            <SendReportDetails userId={userId} hideTitle={true} isCurrentUser={isCurrentUser} goBack={this.handleClose} ></SendReportDetails>
                        </Grid>
                    </div>
                </Dialog>
                <Topbar cbBack={this.goBack} title={t("UserDetails.Reports")} cbSend={this.sendReport} ></Topbar>
                <div className="ReportsFormContainer">

                    <Grid container>
                        {this.item(1)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(2)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(3)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(4)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(5)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(6)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(7)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(8)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(9)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(10)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(11)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {this.item(12)}
                        <Grid item sx={{ p: 0.5 }}>
                        </Grid>
                        {/*<Grid xs container >*/}
                        {/*    <Grid item xs sx={{p:5, py:1.5  }} >*/}
                        {/*       <Box  sx={{ background:'#3A4D8F', p:0.5  }}>*/}
                        {/*        <Typography variant ='h3' color = '#ffffff'>*/}
                        {/*            {`${monthi} `}*/}
                        {/*        </Typography>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item  sx={{p:5,py: 1.5  }} >*/}
                        {/*        <Box  sx={{  p:0.5, py:0.5 }}>*/}
                        {/*        <Typography variant ='h3' color = '#3A4D8F'>*/}
                        {/*          0 EUR*/}
                        {/*        </Typography>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                    </Grid>
                </div>
                <div>

                </div>
                {/*{this.data}*/}
            </React.Fragment>
        );
    }
}
export default (withTranslation()(MonthlyReports));