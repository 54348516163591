import React, {Component} from 'react';
import './VehicleForm.scss';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { Container, AppBar, Fab, Grid, Toolbar, Box } from '@mui/material';
import { InputText } from '../../ui/inputs/text/Text';
import { InputSelect } from '../../ui/inputs/select/Select';
import { getVehiclesMakes, postVehicle } from '../../../utils/VehicleService';
import { withSnackbar, SnackbarProvider } from 'notistack';



class VehicleForm extends Component {
  state = {
    makesList: [],
    modelsList: [],
    selectedMake: null,
    vehicle: {
      id: "",
      makeId: "",
      modelId: "",
      plateNum: "",
      ownerId: ""
    }
  }
  goBack = () => {
    this.props.history.goBack()
  }



  handleChange = (event) => {
    this.setState({vehicle: {...this.state.vehicle, [event.target.id]: event.target.value}});
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {affectedIds} = await postVehicle(this.state.vehicle)
      if(affectedIds && affectedIds.length) {
        this.props.enqueueSnackbar(this.props.t('notification.success.postVehicle'), {
          variant: 'success',
        });
        this.goBack();
      }
    }catch (e) {
      console.error(e);
    }

  }
  getMakes = async () => {
    try {
     const {items} = await getVehiclesMakes();
     await this.setState({makesList: items})
    }catch (err) {
      console.error(err)
    }
  }

  setMake = async (selectedMake) => {
    await this.setState({
      vehicle: {...this.state.vehicle, makeId: selectedMake.id},
      selectedMake,
      modelsList: selectedMake.models
    })
  }

  setModel = async (selectedModel) => {
    await this.setState({
      vehicle: {...this.state.vehicle, modelId: selectedModel.id},
      selectedModel,
    })
  }

  componentDidMount = () => {
      this.getMakes()
      const {ownerId} = this.props.location.state;
      if(ownerId) {
        this.setState({vehicle: {...this.state.vehicle, ownerId: ownerId}})
      }
  }

  render() {
    const {vehicle, selectedMake, selectedModel, makesList, modelsList} = this.state;
    const {t} = this.props;
    return (
      <React.Fragment>
        <Topbar
          title={t('vehicles.New vehicle')}
          cbBack={this.goBack}
          location={this.props.location}
        >
        </Topbar>
          <form onSubmit={this.handleSubmit} noValidate  >
          <SnackbarProvider>
            <Container fixed className="form-container">
            <InputText
              type="text"
              id="plateNum"
              onChange={this.handleChange}
              value={vehicle.plateNum}
              label={t('vehicles.Plate number')}
              placeholder={t('vehicles.Plate number')}
            />
            {/* <InputSelect
              placeholder={t("vehicles.Make")}
              value={selectedMake}
              options={makesList}
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['id']}
              onChange={this.setMake}
            />
            <InputSelect 
              placeholder={t("vehicles.Model")}
              value={selectedModel}
              options={modelsList}
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['id']}
              onChange={this.setModel}
            /> */}
            </Container>
            </SnackbarProvider>
            <AppBar color='inherit' position="fixed"  className="bottom-bar" elevation={0}>
              <Toolbar>
                <Grid
                  spacing={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >

                <Grid item xs={10}>
                  <Fab 
                  className="full-width secondary-btn" variant="extended" 
                  type="submit"
                  value="Submit" 
                  aria-label="Save">
                    <Box fontSize={14} textAlign="center">
                      {t('general.Save')}
                    </Box>
                  </Fab>
                </Grid>
              </Grid>
              </Toolbar>
            </AppBar>
          </form>
      </React.Fragment>

    );
  }
}
export default withSnackbar(withTranslation()(VehicleForm))