import React, { Component } from 'react';
import './ActiveHistory.scss';
import { Topbar } from '../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import { List } from '@mui/material';
import { getUserBookingSpacesListHistory, getUserSharingSpacesList, getParkingLots } from '../../utils/ParkingService';
import { withSnackbar } from 'notistack';
import MomentUtils from "@date-io/moment";


import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Api from '../../constants/Api';
import InfiniteScroll from "react-infinite-scroll-component";
import ActiveHistoryItem from "./item/ActiveHistoryItem";

const moment = new MomentUtils({ locale: 'lt' })

class ActiveHistory extends Component {
    constructor() {
        super()
        this.state = {
            bookedSpaces: [],
            sharedSpaces: [],
            spaces: [],
            lots: []
        }
    }
    goBack = () => {
        this.props.history.goBack()
    }


    fetchBookingSpaces = async () => {
        const { user } = this.props.location.state;
        const userId = user.id;

        const resp = await getUserBookingSpacesListHistory(userId);
        this.setState({ bookedSpaces: [...resp.items] });

        let newSharedSpaces = this.state.bookedSpaces.map(index => ({ ...index, type: 'Rezervacija' }));
        this.setState({ bookedSpaces: newSharedSpaces });

    }
    fetchSharingSpaces = async () => {
        const { user } = this.props.location.state;
        const userId = user.id;

        let today = moment.date().utc(0)
	    let from = today.startOf('month').format('YYYY-MM-DDT00:00:00').toString();

        const resp = await getUserSharingSpacesList(userId, from);
        this.setState({ sharedSpaces: [...resp.items] });

        let newSharedSpaces = this.state.sharedSpaces.map(index => ({ ...index, type: 'Pasidalijimas' }));
        this.setState({ sharedSpaces: newSharedSpaces });

        this.setState({ spaces: [...this.state.bookedSpaces, ...this.state.sharedSpaces] });
    }

    fetchInfo = async () => {
        await this.fetchBookingSpaces();
        await this.fetchSharingSpaces();
        const lots = await getParkingLots();
        this.setState({ lots: lots.items });
    }

    componentDidMount() {
        this.fetchInfo()
    }


    render() {
        const { t, isAdmin, user, isCurrentUser } = this.props;
        const { bookedSpaces, spaces, lots } = this.state;

        return (
            <React.Fragment>
                <Topbar cbBack={this.goBack} title={'Einamojo mėnesio veiksmų istorija'} location={this.props.location} />
                <div className="ActiveHistoryContainer">
                    <List className="ActiveHistoryList" >
                        <InfiniteScroll
                            dataLength={bookedSpaces.length}
                            hasMore={(this.state.hasMore)}
                            next={() => this.fetchSharedSpaces(this.state.nextOffset, true)}
                            loader={
                                <p>{t('general.loading')}...</p>
                            }>
                            {lots.length > 0 && spaces.length > 0 && spaces.map((e, i) => (

                                <ActiveHistoryItem
                                    key={e.id}
                                    exchange={e}
                                    locked={!isAdmin && e.isReadOnly}
                                    onDelete={false}
                                    onBookSpace={false}
                                    lots={lots}
                                />

                            )
                            )

                            }
                        </InfiniteScroll>
                    </List>
                </div>
            </React.Fragment>
        );
    }
}
export default withSnackbar(withTranslation()(ActiveHistory))