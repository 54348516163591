import React, { Component } from 'react';
import { Topbar } from '../../ui/topbar/Topbar';
import { withTranslation } from 'react-i18next';
import CSVReader from "react-csv-reader";
import './ImportData.scss';
import { Fab, Box, Typography, CircularProgress, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { postData } from '../../../utils/AdministrationService';

class ImportData extends Component {
    constructor() {
        super()
        this.state = {
            importData: [],
            showButton: false,
            progress: 0,
            showSpinner: false,
            imported: {},
            arr: [],
            done: 0,
            territory: {},
            name: null,
            lot: {},
            patchName: '/territory',
            open: false,
            selectFile: null
        }
    }

    handleClick = async () => {
        this.setState({ showButton: false });
        this.setState({ showSpinner: true });
        this.setState({ arr: [] });
        this.setState({ progress: 0 });
        this.setState({ imported: null });
        this.setState({ done: 0 });
        await this.asyncForEach(this.state.importData, postData);

        this.setState({ showSpinner: false });
    }

    handleForce = (data, fileInfo) => {
        this.setState({ importData: data });
        this.setState({ showButton: true, open: false, selectFile: fileInfo.name });
    }

    componentDidMount = async () => {
        if (this.props.location.state) {
            const { territory, name, lot } = this.props.location.state;
            if (territory) {
                await this.setState({ territory: territory, name: name });
            }
            if (lot) {
                await this.setState({ lot: lot });
            }
        }
    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            let imported = array[index];
            if (imported.Email == null) {
                imported = this.setTitle(index);
            }
            if (imported == null) {
                this.setState({
                    arr: this.state.arr.concat({ row: index + 1 })
                })
            } else {
                imported.TerritoryId = this.state.territory.id;

                if (this.state.name === 'lot') {
                    imported.LotId = this.state.lot.id;
                }

                const data = await callback(imported);

                let scale = (100.0 / array.length) * index;
                this.setState({ progress: scale });
                if (data == null) {
                    this.setState({
                        arr: this.state.arr.concat({ row: index + 1, title: imported.Title, message: data.value.value.message })
                    })
                } else {
                    this.setState({ done: this.state.done + 1 });
                }
                this.setState({ imported: imported });
            }
        }
    }

    setTitle(index) {
        let result;
        if (index > 0) {
            result = this.state.importData[index - 1];

            if (result.Email == null) {
                result = this.setTitle(index - 1);
            } else {
                result.LicensePlate = this.state.importData[index].LicensePlate
            }
        }
        return result;
    }

    goBack = async () => {
        this.props.history.push({
            pathname: this.state.patchName,
            state: { territory: this.state.territory, lot: this.state.lot }
        })
    }



    render() {

        const { t } = this.props;

        const title = this.state.selectFile == null ? t("Administration.SelectFile") : this.state.selectFile;

        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
        };

        const handleClose = () => {
            this.setState({ open: false });
        };

        const handleClickOpen = () => {
            this.setState({ open: true });
        };

        return (
            <div>
                <Topbar title={t("administration.Title")} cbBack={this.goBack} />
                <div className="container">
                    {!this.state.showSpinner ?
                        <div style={{ paddingBottom: '15px' }}>
                            <Fab variant="extended" className="full-width success-btn" onClick={handleClickOpen}>
                                <Box fontSize={14} textAlign="center">
                                    {title}
                                </Box>
                            </Fab>

                        </div>
                        : null}
                    {this.state.showButton ?
                        <Fab variant="extended" className="full-width success-btn" onClick={this.handleClick}>
                            <Box fontSize={14} textAlign="center">
                                {t('administration.importData')}
                            </Box>
                        </Fab>
                        : null}

                    {this.state.showSpinner ?
                        <div>
                            <Box position="relative" display="inline-flex">
                                <CircularProgress />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                                        this.state.progress,
                                    )}%`}</Typography>
                                </Box>

                            </Box>
                            {this.state.imported != null ?
                                <div>
                                    <p>{this.state.imported.Title}</p>
                                    <p>{this.state.imported.LicensePlate}</p>
                                    <p>{this.state.imported.Space}</p>
                                </div>
                                :
                                <div>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>}
                        </div>
                        : null
                    }
                    {this.state.done > 0 ? <p>Įkelta : {this.state.done}</p> : null}
                </div>

                <div className="arrList">
                    {this.state.arr.length > 0 ?
                        <p > Errors : {this.state.arr.length}</p> : null
                    }
                    {this.state.arr.map((item) =>
                        <p key={item.row}> {item.row}. '{item.title}' {item.message}</p>
                    )}
                </div>
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <CSVReader
                            cssClass="react-csv-input"
                            label={t("administration.LoadCSVfile")}
                            onFileLoaded={this.handleForce}
                            parserOptions={papaparseOptions}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {t("general.Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }
}
export default withTranslation()(ImportData)