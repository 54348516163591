import React, { useState, useEffect } from 'react';
import './NotificationSettingsForm.scss';
// https://css-tricks.com/getting-javascript-to-talk-to-css-and-sass/
import formStyles from './NotificationSettingsForm.scss';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box } from "@mui/material";
import Switch from './../../ui/inputs/switch/Switch';

export default function NotificationSettingsForm({
  globalSetting,
  setting,
  onUpdateSettingItem,
  onSaveNotification,
  isAdmin
}) {
  const { t } = useTranslation();
  const initNotificationConfig = () => {
    if (setting && setting.value) {
      return JSON.parse(setting.value);
    }
    return { NotifyEmail: true, NotifyPush: false };
  };

  const [notifyEmailEnable, setNotifyEmailEnable] = useState(globalSetting(setting, 'NotifyEmail'));
  const [notifyPushEnable, setNotifyPushEnable] = useState(globalSetting(setting, 'NotifyPush'));
  const [notificationConfig, setNotificationConfig] = useState(initNotificationConfig());

  const disableEmail = !isAdmin ? (notifyEmailEnable ? false : true) : false;
  const disablePush = !isAdmin ? (notifyPushEnable ? false : true) : false;

  useEffect(() => {
    setNotifyEmailEnable(globalSetting(setting, 'NotifyEmail'));
    setNotifyPushEnable(globalSetting(setting, 'NotifyPush'));
  },[]);

  const handleChange = (event) => {
    setNotificationConfig({
      ...notificationConfig,
      [event.target.name]: event.target.checked,
    });

    // FIXME: object is passed from parent component and is JSON requires some smarter state management
    let objToUpdate = JSON.parse(setting.value);
    objToUpdate[event.target.name] = event.target.checked;
    setting.value = JSON.stringify(objToUpdate);
    //console.log(event.target.name + ' : ' + event.target.checked)
    onUpdateSettingItem(setting);
    onSaveNotification(setting);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: 1 }}>
        <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2}>
          <Box gridColumn="span 5"  >

            {Object.keys(notificationConfig).map((configKey, i) => (
              (configKey === "NotifyEmail" &&
                <Switch disabled={disableEmail} isToggle={notificationConfig[configKey]} onToggle={handleChange} name={configKey} />
              )
            ))}

          </Box>
          <Box gridColumn="span 5">

            {Object.keys(notificationConfig).map((configKey, i) => (

              (configKey === "NotifyPush" && <Switch disabled={disablePush} isToggle={notificationConfig[configKey]} onToggle={handleChange} name={configKey} />
              )
            ))}
          </Box>

        </Box>
      </Box>
    </React.Fragment>
  );
}
